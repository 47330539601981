import description from './description';
import MaterialMovementAssetHealth1 from './elements/materialMovementAssetHealth_1';
import MaterialMovementAssetHealth2 from './elements/materialMovementAssetHealth_2';
import MaterialMovementAssetHealth3 from './elements/materialMovementAssetHealth_3';
import MaterialMovementAssetHealth4 from './elements/materialMovementAssetHealth_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [15, 42.6], width: 5 }],
  dancingAnts: [['hs1', [0, 24], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [15, 42.6], offset: [4.5, 1.475] },
    { id: 'tb2', position: [15, 42.6], offset: [2.2, 4.7] },
  ],
  elements: [
    new MaterialMovementAssetHealth1([11.6, 16.5]),
    new MaterialMovementAssetHealth2([27.5, 42.62]), // Asset Health Content
    new MaterialMovementAssetHealth3([11.6, 56.25]), // Asset Health video
    new MaterialMovementAssetHealth4([34.1, 56.25]), // Enterprise Asset Management Content
  ],
};

export default config;
