const config = {
  id: '1 of 5',
  currentNumber: 1,
  totalNumber: 5,
  title: 'workflow.Mine Map',
  slug: 'mine-map',
  hasMainNav: true,
  isInPaths: true,
  hidePath: false,
  description: 'workflow.Mine Map description1',
  video: {
    backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
  pointers: [
    {
      id: 'exploration',
      label: {
        number: '01',
        title: 'exploration.Exploration',
      },
      position: [2312, 468],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 278,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 278,
        },
        rect: {
          x: 0,
          y: 0, // 0.12146,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V278H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 278,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 278,
        },
        rect: {
          x: 0,
          y: 0, // 0.12146,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,

          d: 'M58 88H62V278H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Exploration_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Exploration-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Exploration_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'planning',
      label: {
        number: '02',
        title: 'planning.Planning',
      },
      position: [1904, 578],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 398,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 398,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V398H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 398,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 398,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          x1: 59.9995,
          y1: 59,
          x2: 59.9995,
          y2: 398,
          d: 'M58 88H62V398H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Planning_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Planning-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Planning_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'drill-blast',
      label: {
        number: '03',
        title: 'drill-blast.Drill Blast',
      },
      position: [1802, 712],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 318,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 318,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V318H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 318,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 318,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 59.9999,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V318H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Drill_and_Blast_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Drill_and_Blast-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Drill_and_Blast_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'material-movement',
      label: {
        number: '04',
        title: 'material-movement.Material Movement',
      },
      position: [2024, 816],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 218,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 218,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V218H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 218,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 218,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 59.9999,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V218H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Material_Movement_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Material_Movement-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Material_Movement_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'safety',
      label: {
        number: '05',
        title: 'safety.Safety',
      },
      position: [1858, 888],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 172,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 172,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          x1: 59.9995,
          y1: 90.338,
          x2: 59.9995,
          y2: 172,
          d: 'M58 82H62V172H58V82Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 172,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 172,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          x1: 59.9995,
          y1: 97.5969,
          x2: 59.9995,
          y2: 172,
          d: 'M58 90H62V172H58V90Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Safety_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Safety-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Safety_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'survey-monitoring',
      label: {
        number: '06',
        title: 'survey-monitoring.Survey Monitoring',
      },
      position: [1636, 462],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 458,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 458,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V458H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 458,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 458,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V458H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Monitoring_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Monitoring-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Monitoring_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'underground',
      label: {
        number: '07',
        title: 'underground.Underground',
      },
      position: [1692, 878],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-70',
          },
        },
        // width: 2.275,
        width: 120,
        height: 178,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 178,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 68,
            r: 22,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 68,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V178H58V88Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 120,
        height: 178,
        scale: 0.00833333,
        viewBox: {
          x: 120,
          y: 178,
        },
        rect: {
          x: 0,
          y: 0,
          width: 120,
          height: 120,
        },
        circle: {
          outer: {
            x: 60,
            y: 60,
            r: 30,
            strokeWidth: 4,
          },
          inner: {
            x: 60,
            y: 60,
            r: 8,
          },
        },
        line: {
          y1: 59,
          d: 'M58 88H62V178H58V88Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Underground_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Underground-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Underground_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
  ],
  connectivityLines: {
    offsetX: 81,
    offsetY: 222,
    width: 852,
    height: 404,
  },
};

export default config;
