import description from './description';
import ExplorationPlanning1 from './elements/explorationPlanning_1';
import ExplorationPlanning2 from './elements/explorationPlanning_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.76, 39.32], width: 6.375 }],
  dancingAnts: [['hs1', [0, 76.17], { direction: 'reverse' }]],
  tinyBois: [{ id: 'tb1', position: [13.76, 39.32], offset: [2.9, 6.1] }],
  elements: [new ExplorationPlanning1([12.4, 17.18]), new ExplorationPlanning2([12.4, 58.9])],
};

export default config;
