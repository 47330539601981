import StageCard from 'modules/common/components/StageCard';

const description = {
  id: 7,
  currentNumber: 7,
  title: 'underground.Underground',
  slug: 'introduction',
  apiSlug: 'underground-introduction',
  video: {
    backgroundImage: 'Path_6_location_1_frame.jpg',
  },
  description: 'underground.description',
  drawPoints: {
    hotspots: 1.324,
    dancingAnts: 1.324,
    secondaries: 1.324,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'undergroundMining_1', component: StageCard }],
};

export default description;
