import description from './description';
import ExplorationDynamicData1 from './elements/explorationDynamicData_1';
import ExplorationDynamicData2 from './elements/explorationDynamicData_2';
import ExplorationDynamicData3 from './elements/explorationDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [47.92, 54], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [
    new ExplorationDynamicData1([11.75, 15.25], { size: 'wide' }),
    new ExplorationDynamicData2([59.1, 15.37]),
    new ExplorationDynamicData3([67.08, 41.75]),
  ],
};

export default config;
