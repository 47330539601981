import description from './description';
import ExplorationDynamicData1 from './elements/explorationDynamicData_1';
import ExplorationDynamicData2 from './elements/explorationDynamicData_2';
import ExplorationDynamicData3 from './elements/explorationDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [46.2, 63.9], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [
    new ExplorationDynamicData1([19.79, 17.7]),
    new ExplorationDynamicData2([51, 19.82]),
    new ExplorationDynamicData3([58.8, 42.57]),
  ],
};

export default config;
