const description = {
  id: '4 of 7',
  currentNumber: 4,
  totalNumber: 7,
  title: 'drill-blast.Blast Monitoring.title',
  slug: 'blast-monitoring',
  pdfPath: '04BlastMonitoring',
  apiSlug: 'drill-blast-blast-monitoring',
  productDetailsLink:
    'https://hexagon.com/company/newsroom/press-releases/2022/ids-georadar-launches-ai-da',
  description: 'drill-blast.Blast Monitoring.description',
  video: {
    name: 'path 3_location_4.mp4',
    stop: 11,
    backgroundImage: 'Path_3_location_4_frame.jpg',
  },
  drawPoints: {
    hotspots: 11,
    dancingAnts: 13,
    secondaries: 13.5,
  },
};

export default description;
