const description = {
  id: '2 of 5',
  currentNumber: 2,
  totalNumber: 5,
  title: 'exploration.Exploration Drilling.title',
  slug: 'exploration-drilling',
  pdfPath: '02ExplorationDrilling',
  apiSlug: 'exploration-exploration-drilling',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-exploration-geo',
  description: 'exploration.Exploration Drilling.description',
  video: {
    name: 'path 1_location_2.mp4',
    stop: 1.9,
    backgroundImage: 'Path_1_location_2_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 4,
    secondaries: 4.5,
  },
};

export default description;
