import description from './description';
import MaterialMovementDynamicData1 from './elements/materialMovementDynamicData_1';
import MaterialMovementDynamicData3 from './elements/materialMovementDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [60.6, 42.7], width: 6.375 }],
  dancingAnts: [],
  tinyBois: [{ id: 'tb1', position: [60.6, 42.7], offset: [5.6, 4.8] }],
  elements: [
    new MaterialMovementDynamicData1([12.4, 23.69], { size: 'wide', cornerFrom: ['top', 'right'] }),
    new MaterialMovementDynamicData3([74.9, 43.62], { cornerFrom: ['bottom', 'left'] }),
  ],
};

export default config;
