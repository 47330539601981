/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import FormControl from 'core/form/FormControl';
import configHelper from 'modules/journeys-manager/services/ConfigHelper';
import FormElement from 'core/form/FormElement';
import { withTranslation } from 'react-i18next';
import CloseIcon from './assets/icons/close.svg';
import CheckboxCheckedIcon from './assets/icons/checkbox.svg';
import CheckboxDefaultIcon from './assets/icons/checkbox-false.svg';

import './assets/scss/index.scss';

class SelectLocationsModal extends PureComponent {
  constructor(props) {
    super(props);

    const selectedList = props.selectedList || [];

    const formControlParams = configHelper.getAllApiSlugs().reduce((formConf, apiSlug) => {
      // eslint-disable-next-line no-param-reassign
      formConf[apiSlug] = new FormElement(selectedList.includes(apiSlug), []);

      return formConf;
    }, {});

    this.rawConfig = configHelper.rawConfig;

    this.form = new FormControl(formControlParams);

    this.state = {
      open: props.open,
      formValue: this.form.getValues(),
      // formErrors: this.form.getErrors(),
    };
  }

  componentDidMount() {
    this.form.onChange(() => {
      if (!this.props.open) {
        return;
      }

      this.setState({
        formValue: this.form.getValues(),
        // formErrors: this.form.getErrors(),
      });
    });

    this.form.setSubmitFunction(() => {
      this.submitSelection();
    });
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open === 'boolean' && this.props.open !== prevProps.open) {
      this.setState({
        open: this.props.open,
      });

      if (this.props.open) {
        this.setSelected(this.props.selectedList);
      }
    }
  }

  setSelected(selectedList) {
    const selected = selectedList.reduce((acc, el) => ({ ...acc, [el]: true }), {});
    this.form.setValues(selected);
  }

  submitSelection() {
    const { handleClose, selectedList } = this.props;

    const allValues = this.form.getValues();

    const selection = Object.keys(allValues).filter((el) => allValues[el]);

    const newSelection = configHelper.combineSelectedAndExisting(selectedList, selection);

    handleClose(newSelection);
  }

  close() {
    const { handleClose } = this.props;

    handleClose();
  }

  render() {
    const { handleClose, t } = this.props;
    const { open, formValue } = this.state;

    return (
      <div>
        <Dialog
          className="c-dialog c-dialog--with-main-header c-dialog--100 select-locations-modal"
          fullScreen
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle className="c-dialog-title c-dialog-title--big" id="scroll-dialog-title">
            <span>{t('journeys-manager.Select location for your journey')}</span>
            <button
              type="button"
              className="c-button c-button--size-sm c-button--link"
              onClick={() => this.close()}
            >
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent>
            <form className="c-dialog__content">
              {this.rawConfig.map((journey) => (
                <div key={journey.slug} className="journey-form-group">
                  <div className="journey-form-group__title">
                    <div className="journey-form-group__title-text">{t(journey.title)}</div>
                  </div>
                  <div className="journey-form-group__checkboxes-list">
                    <div className="journey-form-group__checkbox">
                      <Checkbox
                        id={journey.info.apiSlug}
                        icon={<CheckboxDefaultIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                        name="showPathName"
                        onChange={(e) =>
                          this.form.getFormElement(journey.info.apiSlug).onChangeEvent(e)
                        }
                        onBlur={(e) =>
                          this.form.getFormElement(journey.info.apiSlug).onBlurEvent(e)
                        }
                        checked={formValue[journey.info.apiSlug] || false}
                      />
                      <label
                        className="journey-form-group__checkbox-label"
                        htmlFor={journey.info.apiSlug}
                      >
                        <span className="journey-form-group__checkbox-label-text">
                          {t('journeys-manager.Entry screen')}
                        </span>
                        <span className="journey-form-group__checkbox-label-sub-text">
                          {t('journeys-manager.For every switching')}
                        </span>
                      </label>
                    </div>
                    {journey.locations.map((location) => (
                      <div key={location.apiSlug} className="journey-form-group__checkbox">
                        <Checkbox
                          id={location.apiSlug}
                          icon={<CheckboxDefaultIcon />}
                          checkedIcon={<CheckboxCheckedIcon />}
                          name="showPathName"
                          onChange={(e) =>
                            this.form.getFormElement(location.apiSlug).onChangeEvent(e)
                          }
                          onBlur={(e) => this.form.getFormElement(location.apiSlug).onBlurEvent(e)}
                          checked={formValue[location.apiSlug] || false}
                        />
                        <label
                          className="journey-form-group__checkbox-label"
                          htmlFor={location.apiSlug}
                        >
                          <span className="journey-form-group__checkbox-label-text">
                            {t(location.title)}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </form>
          </DialogContent>
          <DialogActions className="c-dialog-actions">
            <button
              type="button"
              className="c-button c-button--size-sm c-button--link select-locations-modal_action-cancel"
              onClick={() => this.close()}
            >
              <div className="c-button__text">{t('common.Cancel')}</div>
            </button>
            <button
              type="button"
              className="c-button c-button--size-sm c-button--with-border select-locations-modal_action-apply"
              onClick={() => this.form.submitForm()}
            >
              <div className="c-button__text">{t('common.Apply')}</div>
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SelectLocationsModal.propTypes = {
  handleClose: PropTypes.func,
  t: PropTypes.func,
  open: PropTypes.bool,
  selectedList: PropTypes.arrayOf(PropTypes.string),
};

SelectLocationsModal.defaultProps = {
  handleClose: () => {},
  t: () => {},
  open: false,
  selectedList: [],
};

export default withTranslation()(connect(null, null, null)(SelectLocationsModal));
