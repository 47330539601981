import Box from 'modules/common/components/Box';

export default class UndergroundTaskManagement4 {
  constructor(position, props = {}) {
    this.id = 'undergroundTaskManagement_4';
    this.component = Box;
    this.title = 'underground.Task Management.elements.Introducing UG Pro';

    this.video = '7b-1.mp4';
    this.props = {
      target: props.target || null,
      position,
      hashtags: ['productivity'],
    };
  }
}
