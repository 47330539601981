import Box from 'modules/common/components/Box';

export default class PlanningGradeСontrol3 {
  constructor(position) {
    this.id = 'planningGradeСontrol_3';
    this.component = Box;
    this.title = 'planning.Grade Control.elements.Grade Control Overview';
    this.content = '';
    this.img = '2c-1.jpg';
    this.video = '';
    this.modalType = 'gallery';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['productivity', 'cost'],
    };
  }
}
