import Box from 'modules/common/components/Box';

export default class DrillBlastBlastDesign3 {
  constructor(position) {
    this.id = 'drillBlastBlastDesign_3';
    this.component = Box;
    this.title = 'drill-blast.Blast Design.elements.Blast Design Tool';
    this.content = '';
    this.img = '';
    this.video = '3a-1.mp4';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['integration', 'dataAnalytics'],
    };
  }
}
