// @ts-nocheck
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import configApp from 'app/configs';
import i18n from 'i18nConfig';

import * as selectors from 'app/selectors';

import { getSiteMap } from 'core/utils';

import { withTranslation } from 'react-i18next';
import ButtonClose from './ButtonClose';
import Column from './Column';
import ButtonContactUs from './ContactUs/ButtonContactUs';

import './assets/styles/index.scss';

class SiteMap extends PureComponent {
  constructor(props) {
    super(props);

    this.siteModal = React.createRef();
    this.enableSiteMap = configApp.enableSiteMap;
    this.paths = [];

    this.state = {
      scrolledRight: false,
    };
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.checkOutsideClick);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { lomixConfig } = this.props;
    if (prevProps.lomixConfig !== lomixConfig) {
      const siteMap = getSiteMap(lomixConfig);
      this.paths = siteMap.paths;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.checkOutsideClick);
  }

  checkOutsideClick = (e) => {
    if (!this.siteModal?.current?.contains(e.target)) {
      this.handleClose();
    }
  };

  handleClose = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'closeSiteMap' });
  };

  handleEvent = ({ action, path }) => {
    const { onEvent } = this.props;
    onEvent({ action, path });
  };

  handleClickContactUs = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'openContactUs' });
  };

  handleScroll = (elem) => {
    const { scrollLeft } = elem.target;
    const { scrollWidth } = elem.target;
    const { clientWidth } = elem.target;

    if (scrollLeft + clientWidth >= scrollWidth) {
      this.setState({
        scrolledRight: true,
      });
    } else {
      this.setState({
        scrolledRight: false,
      });
    }
  };

  render() {
    const { config, opened, journey, locationId, availableWorkflowsOverview, t, lang } = this.props;
    const { scrolledRight } = this.state;

    const mapLabel = t('workflow.Mine Map');

    let workflowsOverviewPath = null;

    if (availableWorkflowsOverview) {
      workflowsOverviewPath = '/workflows-overview';
    } else {
      workflowsOverviewPath = '/workflows-overview/mine-map';
    }

    return (
      <div className={`${config.className} ${opened ? config.openClass : ''}`}>
        <div className={`${config.className}__container`} ref={this.siteModal}>
          <ButtonClose onClick={this.handleClose} />
          <div className={`${config.className}__content`}>
            <div className={`${config.className}__content-wrapper`}>
              <div className="container-paths container-paths_margin-left container-paths_margin-right">
                <a
                  className={`container-paths__item${
                    journey?.slug === 'workflows-overview' ? ' container-paths__item_selected' : ''
                  }`}
                  key={'key'}
                  href={workflowsOverviewPath}
                  onClick={(e) => {
                    e.preventDefault();

                    if (journey.slug === 'workflows-overview') return;

                    this.handleEvent({ action: 'goToPath', path: workflowsOverviewPath });
                  }}
                >
                  <span>{t(mapLabel)}</span>
                  <i className="container-paths__arrow_right" />
                </a>
              </div>
              <div className={`${config.className}__content-body container-paths_margin-right`}>
                <div
                  className={`${config.className}__content-body-child`}
                  onScroll={this.handleScroll.bind(this)}
                  style={{ zIndex: scrolledRight ? 28 : 26 }}
                >
                  <div className="container-paths container-paths_margin-top">
                    {this.paths.map((p, index) => {
                      return (
                        <Column
                          key={p.id}
                          path={p}
                          onEvent={this.handleEvent}
                          journey={journey}
                          locationId={locationId}
                          last={index === this.paths.length - 1}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${config.className}__content-footer ${config.className}__content-footer_margin-top`}
          >
            {<ButtonContactUs onClick={this.handleClickContactUs} language={lang} />}
          </div>
        </div>
      </div>
    );
  }
}

SiteMap.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  opened: PropTypes.bool,
  availableWorkflowsOverview: PropTypes.bool,
  journey: PropTypes.object,
  locationId: PropTypes.string,
  lomixConfig: PropTypes.array,
  onEvent: PropTypes.func,
  t: PropTypes.func,
  lang: PropTypes.any,
};

SiteMap.defaultProps = {
  config: {
    className: 'c-site-map-modal',
    openClass: 'is-open',
  },
  opened: false,
  availableWorkflowsOverview: false,
  journey: {},
  locationId: null,
  onEvent: () => {},
  t: () => {},
  lang: null,
  lomixConfig: [],
};

const mapStateToProps = (state) => {
  return {
    journey: selectors.getJourney(state),
    lomixConfig: selectors.getLomixConfig(state),
    availableWorkflowsOverview: selectors.isAvailableWorkflowsOverview(state),
    lang: i18n.language,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null, null, { forwardRef: true })(SiteMap),
);
