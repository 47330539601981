import Introduction from 'modules/material-movement-introduction/configs/config1200';
import SchedulingOptimising from 'modules/material-movement-scheduling-optimising/configs/config1200';
import FleetManagement from 'modules/material-movement-fleet-management/configs/config1200';
import OperatorAssist from 'modules/material-movement-operator-assist/configs/config1200';
import AssetHealth from 'modules/material-movement-asset-health/configs/config1200';
import DynamicData from 'modules/material-movement-dynamic-data/configs/config1200';

const config = {
  id: 4,
  title: 'material-movement.Material Movement',
  slug: 'material-movement',
  pdfPath: '04MaterialMovement',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_4_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [SchedulingOptimising, FleetManagement, OperatorAssist, AssetHealth, DynamicData],
};

export default config;
