const description = {
  id: '3 of 5',
  currentNumber: 3,
  totalNumber: 5,
  title: 'exploration.Resource Model.title',
  slug: 'resource-model',
  pdfPath: '03ResourceModel',
  apiSlug: 'exploration-resource-model',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-resource-geo',
  description: 'exploration.Resource Model.description',
  video: {
    name: 'path 1_location_3.mp4',
    stop: 1.71,
    backgroundImage: 'Path_1_location_3_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.01,
    dancingAnts: 3.5,
    secondaries: 4,
  },
};

export default description;
