import description from './description';
import ExplorationPlanning1 from './elements/explorationPlanning_1';
import ExplorationPlanning2 from './elements/explorationPlanning_2';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [22, 40.25], width: 6.375 }],
  dancingAnts: [['hs1', [0, 76.17], { direction: 'reverse' }]],
  tinyBois: [{ id: 'tb1', position: [22, 40.24], offset: [2.9, 6.1] }],
  elements: [new ExplorationPlanning1([19.58, 15.25]), new ExplorationPlanning2([19.58, 59.12])],
};

export default config;
