// @ts-nocheck
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isLocalhost } from 'serviceWorkerRegistration';

// import { eachFistLeetersUppercase } from 'app/helpers';

import { getFromLS } from 'core/services/localStorage';

import ProductDetailModal from './ProductDetailModal';
import SpreadOut from './assets/icons/spreadOut.svg';

import './assets/scss/index.scss';

const ProductDetailPanel = ({ journey, location }) => {
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const [locationFiles, setLocationFiles] = useState(null);
  const { t, i18n } = useTranslation();

  const openPropductInfo = () => {
    setInfoIsOpen(true);
  };

  const closePropductInfo = () => {
    setInfoIsOpen(false);
  };

  const getFiles = async (language = 'en') => {
    try {
      const response = await fetch(
        `/media/${language}/collateral.json${!isLocalhost ? `?id=${Date.now()}` : ''}`,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      const filesResponse = await response.json();

      return filesResponse;
    } catch (e) {
      if (getFromLS('pwa') === 'pwa') {
        const response = await fetch(`/media/${language}/collateral.json`, {
          headers: { 'Content-Type': 'application/json' },
        });

        const filesResponse = await response.json();

        return filesResponse;
      }

      throw e;
    }
  };

  const setPath = () => {
    const activeJourney = journey.activeLocation?.journey ?? journey;

    // let journeyTitle = eachFistLeetersUppercase(t(activeJourney?.title));
    // journeyTitle = journeyTitle.replace(/\s+/g, '');
    // const journeyPart = `0${activeJourney?.id}${journeyTitle}`;

    // const locationId =
    //   activeJourney.locations.findIndex((loc) => loc.slug === location?.slug) + 1 || 0;
    // let locationTitle = eachFistLeetersUppercase(t(location?.title));
    // locationTitle = locationTitle.replace(/\s+/g, '');
    // const locationPart = `0${locationId}${locationTitle}`;

    // return `${journeyPart}/${locationPart}`;
    return `${activeJourney.pdfPath}/${location.pdfPath}`;
  };

  useEffect(async () => {
    let filesResponse = (await getFiles(i18n.language)) || [];

    const locationFilesPath = setPath();
    let currentFiles = filesResponse.files.filter((file) => {
      return file.path === locationFilesPath;
    });

    if (i18n.language !== 'en' && currentFiles.length === 0) {
      filesResponse = (await getFiles('en')) || [];

      currentFiles = filesResponse.files.filter((file) => {
        return file.path === locationFilesPath;
      });
    }

    setLocationFiles(currentFiles);
  }, [location, i18n.language]);

  return (
    locationFiles &&
    locationFiles.length > 0 && (
      <>
        {!infoIsOpen && (
          <div className="panel product-detail bottom" onClick={openPropductInfo} role="button">
            {t(location.title)} {t('pdf.PRODUCT DETAILS')}
            <SpreadOut />
          </div>
        )}
        <ProductDetailModal
          locationFiles={locationFiles}
          isOpen={infoIsOpen}
          handleClose={closePropductInfo}
          location={location}
        />
      </>
    )
  );
};
ProductDetailPanel.propTypes = {
  location: PropTypes.any,
  journey: PropTypes.any,
};

ProductDetailPanel.defaultProps = {
  location: {},
  journey: {},
};

export default connect()(ProductDetailPanel);
