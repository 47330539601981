const description = {
  id: '5 of 5',
  currentNumber: 5,
  totalNumber: 5,
  code: 5,
  title: 'safety.Safety Dynamic Data.title',
  slug: 'dynamic-data',
  pdfPath: '05SafetyDynamicData',
  apiSlug: 'safety-dynamic-data',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineprotect-cas-analytics',
  description: 'safety.Safety Dynamic Data.description',
  video: {
    backgroundImage: 'Path_5_location_5_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
