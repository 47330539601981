import description from './description';
import ExplorationResourceModel1 from './elements/explorationResourceModel_1';
import ExplorationResourceModel2 from './elements/explorationResourceModel_2';
import ExplorationResourceModel3 from './elements/explorationResourceModel_3';
import ExplorationResourceModel4 from './elements/explorationResourceModel_4';
import ExplorationResourceModel5 from './elements/explorationResourceModel_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [13.7, 39.3], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [13.7, 39.3], offset: [2.9, 6.1] },
    { id: 'tb2', position: [13.7, 39.3], offset: [0.4, 4.8] },
    { id: 'tb3', position: [13.7, 39.3], offset: [5.65, 4.8] },
    { id: 'tb4', position: [13.7, 39.3], offset: [5.65, 1.2] },
  ],
  elements: [
    new ExplorationResourceModel1([12.4, 17.18]),
    new ExplorationResourceModel2([35.8, 60.2]),
    new ExplorationResourceModel3([12.4, 62.9]),
    new ExplorationResourceModel4([35.8, 46.62]),
    new ExplorationResourceModel5([43.65, 33.3]),
  ],
};

export default config;
