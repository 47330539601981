import Introduction from 'modules/survey-monitoring-introduction/configs/configDefault';
import SurveyMonitoringRealityCapture from 'modules/survey-monitoring-reality-capture/configs/configDefault';
import SurveyMonitoringPlanning from 'modules/survey-monitoring-planning/configs/configDefault';
import SurveyMonitoringMonitoring from 'modules/survey-monitoring-monitoring/configs/configDefault';
import SurveyMonitoringGeotech from 'modules/survey-monitoring-geotech/configs/configDefault';
import SurveyMonitoringSurveyAndMonitoringDynamicData from 'modules/survey-monitoring-survey-and-monitoring-dynamic-data/configs/configDefault';

const config = {
  id: 6,
  title: 'survey-monitoring.Survey Monitoring',
  slug: 'survey-monitoring',
  pdfPath: '06Survey&Monitoring',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_5_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [
    SurveyMonitoringRealityCapture,
    SurveyMonitoringPlanning,
    SurveyMonitoringMonitoring,
    SurveyMonitoringGeotech,
    SurveyMonitoringSurveyAndMonitoringDynamicData,
  ],
};

export default config;
