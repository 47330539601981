const description = {
  id: '3 of 3',
  currentNumber: 3,
  totalNumber: 3,
  title: 'underground.Development Drilling.title',
  slug: 'development-drilling',
  pdfPath: '03DevelopmentDrilling',
  apiSlug: 'underground-development-drilling',
  productDetailsLink:
    'https://hexagon.com/company/newsroom/press-releases/2022/mine-solutions-portfolio-acquisition-minnovare',
  description: 'underground.Development Drilling.description',
  video: {
    name: 'path 7_location_3.mp4',
    stop: 23.3,
    backgroundImage: 'Path_7_location_3_frame.jpg',
  },
  drawPoints: {
    hotspots: 22.5,
    dancingAnts: 23,
    secondaries: 23,
  },
};

export default description;
