const description = {
  id: '2 of 4',
  currentNumber: 2,
  totalNumber: 4,
  title: 'planning.Production Drilling.title',
  slug: 'production-drilling',
  pdfPath: '02ProductionDrilling',
  apiSlug: 'planning-production-drilling',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-op-pro-hp',
  description: 'planning.Production Drilling.description',
  video: {
    name: 'path 2_location_2.mp4',
    stop: 8,
    backgroundImage: 'Path_2_location_2_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 8.5,
    secondaries: 9,
  },
};

export default description;
