const description = {
  id: 'lead',
  title: 'journeys-manager.Lead',
  slug: 'lead',
  hidePath: true,
  hideHeader: true,
  // hideMarkerPathName: true,
  hideMarkerJourneyName: true,
  hideMarkerPathId: true,
  hasMainNav: true,
  video: {
    backgroundImage: 'path 020_2D_Mine_Map.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  dimensions: { width: 1024, height: 658 },
  description: 'journeys-manager.Lead Description',
};

export default description;
