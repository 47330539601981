import Box from 'modules/common/components/Box';

export default class ExplorationDynamicData3 {
  constructor(position) {
    this.id = 'ExplorationDynamicData_3';
    this.component = Box;
    this.title = 'exploration.Dynamic Data.elements.GeoSlate';
    this.video = '1e-1.mp4';
    this.props = {
      target: 'hs1',
      position,
      cornerFrom: ['bottom', 'left'],
      hashtags: ['integration'],
    };
  }
}
