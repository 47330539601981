const description = {
  id: '1 of 4',
  currentNumber: 1,
  totalNumber: 4,
  code: 1,
  title: 'planning.Capture Visualise Design.title',
  slug: 'capture-visualise-design',
  pdfPath: '01CaptureVisualiseDesign',
  apiSlug: 'planning-capture-visualise-design',
  productDetailsLink: 'https://hexagon.com/solutions/mine-reality-capture',
  description: 'planning.Capture Visualise Design.description',
  video: {
    name: 'path 2_location_1.mp4',
    stop: 6,
    backgroundImage: 'Path_2_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 2,
    dancingAnts: 5.5,
    secondaries: 6,
  },
};

export default description;
