// @ts-nocheck
import ReactGA from 'react-ga4';
import config from 'modules/analytics/config';

class GA {
  constructor() {
    const { google: googleConfig } = config;

    this.enableGA = googleConfig.enable;

    this.trackingId = googleConfig.trackingId;
    this.debug = googleConfig.debug;
    this.testMode = googleConfig.testMode;

    this.ga = ReactGA;
  }

  init() {
    const options = {
      // debug: this.debug,
      testMode: this.testMode,
      /* gaOptions: {
        name: 'tracker',
        userId: null,
      }, */
    };

    this.ga.initialize(this.trackingId, options);
    this.ga.set({ dimension1: 'Online' });
  }

  trackPage(url) {
    if (this.isEnable()) {
      const pageURL = url || `${window.location.pathname}${window.location.search}`;
      this.ga.send({ hitType: 'pageview', page: pageURL, dimension1: 'Online' });
    }
  }

  sendEvent(category, action, label, nonInteraction = true) {
    if (this.isEnable()) {
      this.ga.event(
        {
          category,
          action,
          label,
          nonInteraction,
        },
        {
          dimension1: 'Online',
        },
      );
    }
  }

  getGA() {
    return this.ga;
  }

  isEnable() {
    return this.enableGA;
  }

  enable() {
    this.enableGA = true;
    return true;
  }

  disable() {
    this.enableGA = false;
    return false;
  }

  sendGTEvent = (object) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(object);
  };
}

const objectGA = Object.freeze(new GA());
export default objectGA;

export const sendGTEvent = (object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
};
