/* eslint-disable no-lone-blocks */
/* eslint-disable no-unreachable */
/* eslint-disable react/sort-comp */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-empty,no-unused-vars */
/* eslint-disable prettier/prettier */
// @ts-nocheck
/* eslint-disable global-require,react/destructuring-assignment,react/prop-types,react/no-unused-state,no-restricted-syntax */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import config from 'modules/drill-blast/configs';

import withNavigate from 'app/routers/withNavigate';

import HeaderNavigation from 'modules/common/components/HeaderNavigation/index';

import LocationDrillBlastIntroduction from 'modules/drill-blast-introduction/layouts';
import LocationDrillBlastDesign from 'modules/drill-blast-design/layouts';
import LocationDrillBlastDrilling from 'modules/drill-blast-drilling/layouts';
import LocationDrillBlastDynamicData from 'modules/drill-blast-dynamic-data/layouts';
import LocationDrillBlastFragmentationAnalysis from 'modules/drill-blast-fragmentation-analysis/layouts';
import LocationDrillBlastMonitoring from 'modules/drill-blast-monitoring/layouts';
import LocationDrillBlastMovement from 'modules/drill-blast-movement/layouts';
import LocationDrillBlastSafety from 'modules/drill-blast-safety/layouts';

import { isNumeric } from 'core/utils';

class Main extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      location: null,
      locationIndex: null,
      locationIndexPrev: null,
      locationIndexNext: null,
      locationRequestDestroy: false,
      showNavigationArrows: false,
      backgroundImage: null,
    };

    this.cache = {
      action: {
        name: null,
        waitFor: null,
      },
    };
  }

  componentDidMount() {
    const { locationId } = this.props;
    this.init(locationId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { locationId } = this.props;

    if (prevProps.locationId !== locationId) {
      this.init(locationId);
    }
  }

  onEvent = ({ action, data }) => {
    switch (action) {
      case 'header:navigation:previous': {
        const { disablePrev } = this.state;

        if (disablePrev) {
          return false;
        }

        this.cache.action.name = 'location:prev';
        this.cache.action.waitFor = 'location:destroy';

        this.disableNavigation();

        this.props.onEvent({ action: 'location:prev:start' });

        this.setState({
          locationRequestDestroy: true,
        });
        break;
      }
      case 'header:navigation:next': {
        const { disableNext } = this.state;

        if (disableNext) {
          return false;
        }

        this.cache.action.name = 'location:next';
        this.cache.action.waitFor = 'location:destroy';

        this.disableNavigation();

        this.props.onEvent({ action: 'location:next:start' });

        this.setState({
          locationRequestDestroy: true,
        });
        break;
      }
      case 'location:ready': {
        this.enableNavigation();

        this.props.onEvent({
          action: 'location:ready',
          data: {
            lastLocation: this.isLastLocation(),
          },
        });

        // this.openPathsIfLastLocation();

        /* if (!this.state.video.play || !this.props.location?.video.name) {
          this.toggleIsStageAnimating(false);
        } */
        // this.setState({ canShowHashtagsModal: true });
        break;
      }
      case 'location:destroy:pre': {
        if (this.cache.action.waitFor === 'location:destroy') {
          switch (this.cache.action.name) {
            case 'location:next': {
              const { locationIndexNext } = this.state;

              if (isNumeric(locationIndexNext)) {
                const { journey } = this.props;

                const location = journey.locations[locationIndexNext];

                this.setBackgroundImage(location);
              }
              break;
            }
            case 'location:prev': {
              this.cache.action.name = null;
              this.cache.action.waitFor = null;

              this.props.onEvent({ action: 'location:prev:end' });

              setTimeout(() => {
                this.goToPrevLocation();
              }, 1500);
              break;
            }
            default: {
            }
          }
        } else {
          const { journey } = this.props;

          const { locationIndexNext } = this.state;

          if (isNumeric(locationIndexNext)) {
            const location = journey.locations[locationIndexNext];
            this.setBackgroundImage(location);
          } else if (
            data?.action === 'resumeFromCard' &&
            Array.isArray(journey.locations) &&
            journey.locations.length > 0
          ) {
            const location = journey.locations[0];
            this.setBackgroundImage(location);
          } else if (data?.action === 'cancelResumeFromCard') {
            this.cache.action.name = 'cancelResumeFromCard';
          }
        }
        break;
      }
      case 'location:destroy': {
        if (this.cache.action.waitFor === 'location:destroy') {
          switch (this.cache.action.name) {
            case 'location:next': {
              this.cache.action.name = null;
              this.cache.action.waitFor = null;
              this.goToNextLocation();
              break;
            }
            default: {
            }
          }
        } else if (this.cache.action.name === 'cancelResumeFromCard') {
          this.cache.action.name = null;
          this.cache.action.waitFor = null;
          this.props.onEvent({ action: 'togglePaths', data: { isOpen: true } });
        } else if (!this.props.params.locationId) {
          const { journey, navigate } = this.props;

          const locationId = journey.locations[0]?.slug;

          if (locationId) {
            this.props.onEvent({
              action: 'goToPathWithoutTransition',
              data: {
                path: `/${journey.slug}/${locationId}`,
              },
            });
            // navigate(`/${journey.slug}/${locationId}`);
            this.init(locationId);
          }
        }
        break;
      }
      case 'setCanNavigate': {
        this.props.onEvent({
          action: 'setCanNavigate',
          data: {
            value: data.value,
          },
        });
        this.checkLocation();
        break;
      }
      case 'toggleIsStageAnimating': {
        this.props.onEvent({ action: 'toggleIsStageAnimating', data });
        break;
      }
      case 'cancelResumeFromCard': {
        this.props.onEvent({ action: 'togglePaths', data: { isOpen: true } });
        break;
      }
      default: {
        //
      }
    }
  };

  goToNextLocation = () => {
    const {
      journey,
      // navigate,
      // setCanNavigate,
      // setLocationName,
      onEvent,
    } = this.props;

    const { locationIndexNext } = this.state;

    // setCanNavigate(false);
    // setLocationName(null);

    if (isNumeric(locationIndexNext)) {
      onEvent({
        action: 'goToPathWithoutTransition',
        data: {
          path: `/${journey.slug}/${journey.locations[locationIndexNext].slug}`,
        },
      });
      // navigate(`/${journey.slug}/${journey.locations[locationIndexNext].slug}`);
      // this.updateLocationByNext();
    } else {
      onEvent({ action: 'location:next:null' });
    }
  };

  goToPrevLocation = () => {
    const {
      journey,
      navigate,
      // setCanNavigate,
      // setLocationName,
      onEvent,
    } = this.props;

    const { locationIndexPrev } = this.state;

    // setCanNavigate(false);
    // setLocationName(null);

    if (isNumeric(locationIndexPrev)) {
      navigate(`/${journey.slug}/${journey.locations[locationIndexPrev].slug}`);
      // this.updateLocationByPrev();
    } else {
      onEvent('location:prev:null');
    }
  };

  init(locationId = null) {
    const { journey } = this.props;

    let location = null;
    let locationIndex = null;
    let locationIndexPrev = null;
    let locationIndexNext = null;

    let nextLabel = null;
    let nextTitle = null;

    if (locationId === 'introduction') {
      location = config.current.info;
    } else if (Array.isArray(journey?.locations)) {
      for (let i = 0, n = journey.locations.length; i < n; i += 1) {
        const lc = journey.locations[i];

        if (lc.slug === locationId) {
          location = lc;
          locationIndex = i;
          locationIndexPrev = i === 0 ? null : i - 1;
          locationIndexNext = i === n - 1 ? null : i + 1;

          if (locationIndexNext === null) {
            nextLabel = 'Path';

            const nextJourneyTitle = true;

            if (nextJourneyTitle) {
              nextTitle = this.props.nextTitle;
            }
          } else {
            nextLabel = 'Location';
            nextTitle = journey.locations[locationIndexNext].title;
          }

          nextTitle = nextTitle || 'N/A';
          break;
        }
      }
    }

    this.setBackgroundImage(location);

    this.setState({
      disablePrev: true,
      disableNext: true,
      locationRequestDestroy: false,
      showNavigationArrows: !!locationId && locationId !== 'introduction',
      location,
      locationIndex,
      locationIndexPrev,
      locationIndexNext,
      nextLabel,
      nextTitle,
    });

    return {
      location,
      locationIndex,
      locationIndexPrev,
      locationIndexNext,
    };
  }

  getLocation() {
    const { journey, audioMuted, enabledTransition } = this.props;

    const { location, locationRequestDestroy, backgroundImage } = this.state;

    switch (location?.slug) {
      case 'blast-design': {
        return (
          <LocationDrillBlastDesign
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }

      case 'drilling': {
        return (
          <LocationDrillBlastDrilling
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      case 'safety': {
        return (
          <LocationDrillBlastSafety
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      case 'blast-monitoring': {
        return (
          <LocationDrillBlastMonitoring
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      case 'blast-movement': {
        return (
          <LocationDrillBlastMovement
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      case 'fragmentation-analysis': {
        return (
          <LocationDrillBlastFragmentationAnalysis
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      case 'dynamic-data': {
        return (
          <LocationDrillBlastDynamicData
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      case 'introduction': {
        return (
          <LocationDrillBlastIntroduction
            drawPoints={journey?.info?.drawPoints}
            onEvent={this.onEvent}
            journey={journey} // TEMP. ONLY FOR TEST
            requestDestroy={locationRequestDestroy}
            audioMuted={audioMuted}
            enabledTransition={enabledTransition}
            backgroundImage={backgroundImage}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  /**
   * @deprecated
   */
  updateLocationByPrev() {
    let location = null;
    let locationIndex = null;
    let locationIndexPrev = null;
    let locationIndexNext = null;

    const { journey } = this.props;

    const { slug: locationId } = journey.locations[this.state.locationIndexPrev];

    for (let i = 0, n = journey.locations.length; i < n; i += 1) {
      const lc = journey.locations[i];

      if (lc.slug === locationId) {
        location = lc;
        locationIndex = i;
        locationIndexPrev = i === 0 ? null : i - 1;
        locationIndexNext = i === n - 1 ? null : i + 1;

        let nextLabel = null;
        let nextTitle = null;

        if (locationIndexNext === null) {
          nextLabel = 'Path';

          const nextJourneyTitle = true;

          if (nextJourneyTitle) {
            nextTitle = 'nextJourneyTitle';
          }
        } else {
          nextLabel = 'Location';
          nextTitle = journey.locations[locationIndexNext].title;
        }

        nextTitle = nextTitle || 'N/A';

        this.setBackgroundImage(location);

        this.setState({
          location,
          locationIndex,
          locationIndexPrev,
          locationIndexNext,
          nextLabel,
          nextTitle,
        });
        break;
      }
    }

    this.setState({
      disablePrev: true,
      disableNext: true,
      locationRequestDestroy: false,
    });

    return {
      location,
      locationIndex,
      locationIndexPrev,
      locationIndexNext,
    };
  }

  /**
   * @deprecated
   */
  updateLocationByNext() {
    let location = null;
    let locationIndex = null;
    let locationIndexPrev = null;
    let locationIndexNext = null;

    const { journey } = this.props;

    const { slug: locationId } = journey.locations[this.state.locationIndexNext];

    for (let i = 0, n = journey.locations.length; i < n; i += 1) {
      const lc = journey.locations[i];

      if (lc.slug === locationId) {
        location = lc;
        locationIndex = i;
        locationIndexPrev = i === 0 ? null : i - 1;
        locationIndexNext = i === n - 1 ? null : i + 1;

        let nextLabel = null;
        let nextTitle = null;

        if (locationIndexNext === null) {
          nextLabel = 'Path';

          const nextJourneyTitle = true;

          if (nextJourneyTitle) {
            nextTitle = 'nextJourneyTitle';
          }
        } else {
          nextLabel = 'Location';
          nextTitle = journey.locations[locationIndexNext].title;
        }

        nextTitle = nextTitle || 'N/A';

        this.setBackgroundImage(location);

        this.setState({
          location,
          locationIndex,
          locationIndexPrev,
          locationIndexNext,
          nextLabel,
          nextTitle,
        });
        break;
      }
    }

    this.setState({
      disablePrev: true,
      disableNext: true,
      locationRequestDestroy: false,
    });

    return {
      location,
      locationIndex,
      locationIndexPrev,
      locationIndexNext,
    };
  }

  checkLocation() {
    const { isStageAnimating } = this.props;

    const { locationIndexPrev } = this.state;

    const disablePrev = isStageAnimating || locationIndexPrev === null;
    const disableNext = isStageAnimating;

    this.setState({
      disablePrev,
      disableNext,
    });
  }

  enableNavigation() {
    const { locationIndexPrev } = this.state;

    this.setState({
      disablePrev: locationIndexPrev === null || false,
      disableNext: false,
    });
  }

  disableNavigation() {
    const { locationIndexPrev } = this.state;

    this.setState({
      disablePrev: locationIndexPrev === null || true,
      disableNext: true,
    });
  }

  setBackgroundImage(location) {
    this.setState({ backgroundImage: location?.video?.backgroundImage });
  }

  isLastLocation() {
    return this.state.location && this.state.location.slug !== 'introduction' && this.state.locationIndexNext === null;
  }

  render() {
    const { journey, openedDeviceRotate } = this.props;

    const { nextTitle, nextLabel, disablePrev, disableNext, showNavigationArrows } = this.state;

    // !!location?.video?.playDefaultSound

    return (
      <>
        <HeaderNavigation
          openedDeviceRotate={openedDeviceRotate}
          pathName={journey.title}
          nextTitle={nextTitle}
          nextLabel={nextLabel}
          disablePrev={disablePrev}
          disableNext={disableNext}
          showNavigationArrows={showNavigationArrows}
          onEvent={this.onEvent}
        />
        {this.getLocation()}
      </>
    );
  }
}

Main.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  enabledTransition: PropTypes.bool,
  audioMuted: PropTypes.bool,
  onEvent: PropTypes.func,
  nextTitle: PropTypes.string,
  nextLabel: PropTypes.string,
  locationId: PropTypes.string,
  authed: PropTypes.bool,
};

Main.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  enabledTransition: false,
  audioMuted: true,
  onEvent: () => {},
  nextTitle: '',
  nextLabel: '',
  locationId: null,
  authed: false,
};

const mapStateToProps = () => {
  const journey = config.current;

  return {
    config: {
      className: 'journey',
      delayPrimaries: 0.5,
      delaySecondaries: 0.25,
      staggerBreakdowns: 0.125,
      delayVideoBreakdown: 1.5,
    },
    journey,
  };
};

export default withNavigate(connect(mapStateToProps, null)(Main));
