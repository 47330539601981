// @ts-nocheck
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

import translationEN from './locales/en.json';
import translationZh from './locales/zh.json';

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZh,
  },
};

i18n
  .use(XHR)
  .use(detector)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    // lng: 'en', // default language
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    debug: true, // enable logging for development
    detection: {
      order: ['localStorage'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    fallbackLng: 'en', // fallback language in case translation not found

    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
    backend: {
      // configure backend options
      loadPath: '/locales/{{lng}}.json', // where to load translation files from
    },
    // react: {
    //   // configure react-i18next options
    //   useSuspense: true, // useSuspense option is deprecated
    // },
  });

export default i18n;
