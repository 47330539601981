import { combineReducers } from 'redux';

import { NAME } from 'app/constants';
import appReducer from 'app/reducers/app';

import { NAME as NAME_JOURNEYS_MANAGER } from 'modules/journeys-manager/constants';
import journeysManagerReducer from 'modules/journeys-manager/reducers';

import { NAME as NAME_JOURNEYS_MANAGER_LIST } from 'modules/journeys-manager-introduction/constants';
import journeysManagerListReducer from 'modules/journeys-manager-introduction/reducers';

import { NAME as NAME_JOURNEYS_MANAGER_JOURNEY } from 'modules/journeys-manager-journey/constants';
import journeysManagerJourneyReducer from 'modules/journeys-manager-journey/reducers';

import { NAME as NAME_LEAD } from 'modules/lead/constants';
import leadReducer from 'modules/lead/reducers';

const root = combineReducers({
  [NAME]: appReducer,
  [NAME_JOURNEYS_MANAGER]: journeysManagerReducer,
  [NAME_JOURNEYS_MANAGER_LIST]: journeysManagerListReducer,
  [NAME_JOURNEYS_MANAGER_JOURNEY]: journeysManagerJourneyReducer,
  [NAME_LEAD]: leadReducer,
});

export default root;
