const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  code: 4,
  title: 'material-movement.Asset Health.title',
  slug: 'asset-health',
  pdfPath: '04AssetHealth',
  apiSlug: 'material-movement-asset-health',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-asset-health',
  description: 'material-movement.Asset Health.description',
  video: {
    name: 'path 4_location_4.mp4',
    stop: 12,
    backgroundImage: 'Path_4_location_4_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 8,
    secondaries: 8.5,
  },
};

export default description;
