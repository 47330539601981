// @ts-nocheck
/* eslint-disable import/no-webpack-loader-syntax,react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { createBrowserHistory } from 'history';

import configAPP from 'app/configs';

import HexagonLogoSvg from 'assets/images/brand/hexagon-logo.svg';

import DeviceRotateSvg from 'app/assets/icons/device-rotate-small.svg';

import { getFromLS } from 'core/services/localStorage';
import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import ButtonExploreMore from 'app/components/ExploreMore/ButtonExploreMore';
import ButtonManageMyJourneyApp from 'app/components/modals/DownloadApp/ButtonManageMyJourneyApp';
import LoginMsal from 'modules/auth/components/LoginMsal';
import ProfileDropdown from 'modules/auth/components/ProfileDropdown';
import { withTranslation } from 'react-i18next';
import LanguageSelect from 'modules/common/components/LanguageSelect';
import ButtonContactUs from './modals/ContactUs/ButtonContactUs';
import ButtonDownloadApp from './modals/DownloadApp/ButtonDownloadApp';
import ButtonSiteMapApp from './modals/DownloadApp/ButtonSiteMapApp';

import Info from './notifications/Info';

function getStatusPWA() {
  return configAPP.enableExhibition && getFromLS('pwa') === 'pwa';
}

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.history = createBrowserHistory();

    this.state = {
      pwaExists: getStatusPWA(),
    };
  }

  componentDidMount() {
    window.addEventListener('storage', this.check, false);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.check, false);
  }

  check = () => {
    this.setState({
      pwaExists: getStatusPWA(),
    });
  };

  handleClickLogo = () => {
    const { onEvent, disableClickLogo } = this.props;

    if (disableClickLogo) return;

    onEvent({ action: 'openLogo' });
  };

  handleClickExploreMore = (event) => {
    const { onEvent } = this.props;
    onEvent(event);
  };

  handleClickContactUs = (event) => {
    const { onEvent } = this.props;
    onEvent(event);
  };

  handleClickManageMyJourney = (event) => {
    const { onEvent } = this.props;
    onEvent(event);
  };

  handleClickDownload = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'openDownload' });
  };

  handleClickSiteMap = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'openSiteMap' });
  };

  reload3D = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'workflowsOverview' });
  };

  render() {
    const {
      config,
      visible,
      showDeviceRotate,
      workflowsOverview,
      workflowsOverview2D,
      authed,
      showSiteMapDownload,
      showHeaderExploreMore,
      showMyJourneys,
      showContactUS,
      showDownload,
      showProfile,
      msalLogged,
      t,
    } = this.props;

    const { pwaExists } = this.state;

    return (
      <header
        className={`${config.className} ${visible ? config.visibleClass : ''} ${
          (workflowsOverview || workflowsOverview2D) && ALLOW_EVENTS_CLASS
        }`}
      >
        <div className={`${config.className}__main`}>
          <div className={`${config.className}__brand`}>
            <div
              className={`${config.className}__logo-container ${
                (workflowsOverview || workflowsOverview2D) && ALLOW_EVENTS_CLASS
              } ${(workflowsOverview || workflowsOverview2D) && 'no-link'}`}
              onClick={this.handleClickLogo}
            >
              <div className={`${config.className}__logo`}>
                <HexagonLogoSvg />
              </div>
            </div>
            <h1 className={`${config.className}__title`}>
              <span className={`${config.className}__title-inner`}>
                {t('Life-of-Mine Experience')}
              </span>
            </h1>
          </div>
          <div className={`${config.className}__buttons ${ALLOW_EVENTS_CLASS}`}>
            {showMyJourneys && msalLogged && (
              <ButtonManageMyJourneyApp onClick={this.handleClickManageMyJourney} />
            )}
            {showHeaderExploreMore && (
              <div className="margin-right-5">
                <ButtonExploreMore onClick={this.handleClickExploreMore} />
              </div>
            )}
            {showContactUS && <ButtonContactUs onClick={this.handleClickContactUs} />}

            {authed && showSiteMapDownload && (
              <>
                {configAPP.enableExhibition ? (
                  <ButtonSiteMapApp onClick={this.handleClickSiteMap} />
                ) : (
                  <>
                    {configAPP.enableDownload && !pwaExists && showDownload && (
                      <ButtonDownloadApp onClick={this.handleClickDownload} />
                    )}
                    {pwaExists && <ButtonSiteMapApp onClick={this.handleClickSiteMap} />}
                  </>
                )}
              </>
            )}
            <LanguageSelect />

            {!configAPP.enableExhibition && msalLogged && showProfile && <LoginMsal />}
            {authed && (!msalLogged || configAPP.enableExhibition) && <ProfileDropdown />}
          </div>
        </div>

        {showDeviceRotate ? (
          <div className={`${config.className}__third ${config.className}__third_margin`}>
            <div className={`${config.className}__third-device-rotate-container fade-in`}>
              <div className={`${config.className}__third-device-rotate`}>
                <DeviceRotateSvg />
              </div>
              <div className={`${config.className}__third-device-rotate-corner`}>
                <svg className="corner-line">
                  <line x1="0" x2="2rem" y1="2rem" y2="0" />
                </svg>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {workflowsOverview2D && <Info reload3D={this.reload3D} />}
      </header>
    );
  }
}

Header.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    visibleClass: PropTypes.string,
    disabledClass: PropTypes.string,
  }),
  showDeviceRotate: PropTypes.bool,
  workflowsOverview: PropTypes.bool,
  workflowsOverview2D: PropTypes.bool,
  onEvent: PropTypes.func,
  onDownloadEvent: PropTypes.func,
  showHeaderExploreMore: PropTypes.bool,
  showMyJourneys: PropTypes.bool,
  showContactUS: PropTypes.bool,
  showDownload: PropTypes.bool,
  showProfile: PropTypes.bool,
  disableClickLogo: PropTypes.bool,
  msalLogged: PropTypes.bool,
  i18n: PropTypes.object,
  t: PropTypes.func,
};

Header.defaultProps = {
  config: {
    className: 'header',
    visibleClass: 'is-visible',
    disabledClass: 'is-disabled',
  },
  showDeviceRotate: false,
  workflowsOverview: false,
  workflowsOverview2D: false,
  showHeaderExploreMore: false,
  showMyJourneys: true,
  showContactUS: true,
  showDownload: true,
  showProfile: true,
  disableClickLogo: false,
  onEvent: () => {},
  onDownloadEvent: () => {},
  msalLogged: false,
  t: false,
  i18n: null,
};

Header.id = 'Header';

const mapStateToProps = () => {
  return {
    showSiteMapDownload: true,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null, null, { forwardRef: true })(Header),
);
