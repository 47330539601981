import WorkflowsOverview from 'modules/workflows-overview/configs/config1200';
import WorkflowsOverview2D from 'modules/workflows-overview-2d/configs/config1200';
import Exploration from 'modules/exploration/configs/config1200';
import Planning from 'modules/planning/configs/config1200';
import DrillBlast from 'modules/drill-blast/configs/config1200';
import MaterialMovement from 'modules/material-movement/configs/config1200';
import Safety from 'modules/safety/configs/config1200';
import SurveyMonitoring from 'modules/survey-monitoring/configs/config1200';
import Underground from 'modules/underground/configs/config1200';
import Home from 'modules/home/configs/config1200';

import JourneysManager from 'modules/journeys-manager/configs/config1200';

import Lead from 'modules/lead/configs/config1200';

export default [
  Home,
  WorkflowsOverview,
  WorkflowsOverview2D,
  Exploration,
  Planning,
  DrillBlast,
  MaterialMovement,
  Safety,
  SurveyMonitoring,
  Underground,
  JourneysManager,
  Lead,
];
