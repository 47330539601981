const config = {
  id: '1 of 5',
  currentNumber: 1,
  totalNumber: 5,
  title: 'workflow.Mine Map',
  slug: 'mine-map',
  hasMainNav: true,
  isInPaths: true,
  hidePath: false,
  description: 'workflow.Mine Map description1',
  video: {
    backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
  pointers: [
    {
      id: 'exploration',
      label: {
        number: '01',
        title: 'exploration.Exploration',
      },
      position: [633, 147],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 38,
        height: 86,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 86,
        },
        rect: {
          x: 0.121094,
          y: 0.37207,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 19.086,
            y: 21.0859,
            r: 6.70138,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 19.0834,
            y: 21.0859,
            r: 2.43687,
          },
        },
        line: {
          x1: 19.0837,
          y1: 27.178,
          x2: 19.0837,
          y2: 85.0536,
          d: 'M18.4746 27.178H19.693V85.0536H18.4746V27.178Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 37,
        height: 86,
        scale: 0.00833333,
        viewBox: {
          x: 37,
          y: 86,
        },
        rect: {
          x: 0.121094,
          y: 0.37207,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 18.3939,
            y: 18.649,
            r: 9.13825,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 18.3959,
            y: 18.6485,
            r: 2.43687,
          },
        },
        line: {
          x1: 18.3942,
          y1: 27.178,
          x2: 18.3942,
          y2: 85.0536,
          d: 'M17.7852 27.178H19.0036V85.0536H17.7852V27.178Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Exploration_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Exploration-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Exploration_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'planning',
      label: {
        number: '02',
        title: 'planning.Planning',
      },
      position: [498, 168],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 38,
        height: 123,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 123,
        },
        rect: {
          x: 0.511719,
          y: 0.872559,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 19.086,
            y: 21.5859,
            r: 6.70138,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 19.0834,
            y: 21.5859,
            r: 2.43687,
          },
        },
        line: {
          x1: 19.0837,
          y1: 27.6785,
          x2: 19.0837,
          y2: 122.107,
          d: 'M18.4746 27.6785H19.693V122.107H18.4746V27.6785Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 38,
        height: 123,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 123,
        },
        rect: {
          x: 0.511719,
          y: 0.872559,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 18.7865,
            y: 19.149,
            r: 9.13825,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 18.7884,
            y: 19.149,
            r: 2.43687,
          },
        },
        line: {
          x1: 18.7888,
          y1: 27.6785,
          x2: 18.7888,
          y2: 122.107,
          d: 'M18.1797 27.6785H19.3981V122.107H18.1797V27.6785Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Planning_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Planning-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Planning_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'drill-blast',
      label: {
        number: '03',
        title: 'drill-blast.Drill Blast',
      },
      position: [467, 208],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 38,
        height: 97,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 97,
        },
        rect: {
          x: 0.509766,
          y: 0.132141,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 19.086,
            y: 20.8464,
            r: 6.70138,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 19.0834,
            y: 20.846,
            r: 2.43687,
          },
        },
        line: {
          x1: 19.0837,
          y1: 38.9141,
          x2: 19.0837,
          y2: 96.998,
          d: 'M18.4746 26.938H19.693V96.998H18.4746V26.938Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 38,
        height: 97,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 97,
        },
        rect: {
          x: 0.509766,
          y: 0.132141,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 18.7865,
            y: 18.409,
            r: 9.13825,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 18.7865,
            y: 18.4086,
            r: 2.43687,
          },
        },
        line: {
          x1: 18.7868,
          y1: 38.9136,
          x2: 18.7868,
          y2: 96.9975,
          d: 'M18.1777 26.9376H19.3962V96.9975H18.1777V26.9376Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Drill_and_Blast_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Drill_and_Blast-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Drill_and_Blast_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'material-movement',
      label: {
        number: '04',
        title: 'material-movement.Material Movement',
      },
      position: [536, 240],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 38,
        height: 68,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 68,
        },
        rect: {
          x: 0.509766,
          y: 0.633179,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 19.086,
            y: 21.347,
            r: 6.70138,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 19.0834,
            y: 21.3466,
            r: 2.43687,
          },
        },
        line: {
          x1: 19.0837,
          y1: 27.4391,
          x2: 19.0837,
          y2: 67.0382,
          d: 'M18.4746 27.4391H19.693V67.0382H18.4746V27.4391Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 38,
        height: 68,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 68,
        },
        rect: {
          x: 0.509766,
          y: 0.633179,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 18.7865,
            y: 18.9095,
            r: 9.13825,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 18.7865,
            y: 18.9097,
            r: 2.43687,
          },
        },
        line: {
          x1: 18.7868,
          y1: 27.4386,
          x2: 18.7868,
          y2: 67.0377,
          d: 'M18.1777 27.4386H19.3962V67.0377H18.1777V27.4386Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Material_Movement_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Material_Movement-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Material_Movement_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'safety',
      label: {
        number: '05',
        title: 'safety.Safety',
      },
      position: [487, 265],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 37,
        height: 56,
        scale: 0.00833333,
        viewBox: {
          x: 37,
          y: 56,
        },
        rect: {
          x: 0.222656,
          y: 1,
          width: 36.5556,
          height: 36.5556,
        },
        circle: {
          outer: {
            x: 18.5006,
            y: 21.7127,
            r: 6.70185,
            strokeWidth: 1.21852,
          },
          inner: {
            x: 18.4995,
            y: 21.7127,
            r: 2.43704,
          },
        },
        line: {
          x1: 18.4997,
          y1: 30.9546,
          x2: 18.4997,
          y2: 55.8312,
          d: 'M17.8906 28.4146H19.1091V55.8312H17.8906V28.4146Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 37,
        height: 56,
        scale: 0.00833333,
        viewBox: {
          x: 37,
          y: 56,
        },
        rect: {
          x: 0.222656,
          y: 1,
          width: 36.5556,
          height: 36.5556,
        },
        circle: {
          outer: {
            x: 18.5001,
            y: 19.2777,
            r: 9.13889,
            strokeWidth: 1.21852,
          },
          inner: {
            x: 18.5,
            y: 19.2781,
            r: 2.4375,
          },
        },
        line: {
          x1: 18.4997,
          y1: 30.9565,
          x2: 18.4997,
          y2: 55.8332,
          d: 'M17.8906 28.4165H19.1091V55.8332H17.8906V28.4165Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Safety_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Safety-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Safety_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'survey-monitoring',
      label: {
        number: '06',
        title: 'survey-monitoring.Survey Monitoring',
      },
      position: [416, 132],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 38,
        height: 140,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 140,
        },
        rect: {
          x: 0.509766,
          y: 0.13385,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 19.086,
            y: 20.847,
            r: 6.70138,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 19.0834,
            y: 20.8466,
            r: 2.43687,
          },
        },
        line: {
          x1: 19.0837,
          y1: 26.9391,
          x2: 19.0837,
          y2: 139.644,
          d: 'M18.4746 26.9391H19.693V139.644H18.4746V26.9391Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 38,
        height: 140,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 140,
        },
        rect: {
          x: 0.509766,
          y: 0.13385,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 18.7846,
            y: 18.4101,
            r: 9.13825,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 18.7865,
            y: 18.4102,
            r: 2.43687,
          },
        },
        line: {
          x1: 18.7868,
          y1: 26.9391,
          x2: 18.7868,
          y2: 139.644,
          d: 'M18.1777 26.9391H19.3962V139.644H18.1777V26.9391Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Monitoring_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Monitoring-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Monitoring_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'underground',
      label: {
        number: '07',
        title: 'underground.Underground',
      },
      position: [434, 235],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-24',
          },
        },
        // width: 2.275,
        width: 38,
        height: 55,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 55,
        },
        rect: {
          x: 0.509766,
          y: 0.6698,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 19.086,
            y: 21.3837,
            r: 6.70138,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 19.0834,
            y: 21.3838,
            r: 2.43687,
          },
        },
        line: {
          x1: 19.0837,
          y1: 27.4759,
          x2: 19.0837,
          y2: 54.8906,
          d: 'M18.4746 27.4759H19.693V54.8906H18.4746V27.4759Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 38,
        height: 55,
        scale: 0.00833333,
        viewBox: {
          x: 38,
          y: 55,
        },
        rect: {
          x: 0.509766,
          y: 0.6698,
          width: 36.553,
          height: 36.553,
        },
        circle: {
          outer: {
            x: 18.7846,
            y: 18.9468,
            r: 9.13825,
            strokeWidth: 1.21843,
          },
          inner: {
            x: 18.7865,
            y: 18.9464,
            r: 2.43687,
          },
        },
        line: {
          x1: 18.7868,
          y1: 27.4759,
          x2: 18.7868,
          y2: 54.8906,
          d: 'M18.1777 27.4759H19.3962V54.8906H18.1777V27.4759Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Underground_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Underground-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Underground_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
  ],
  connectivityLines: {
    offsetX: 22.4,
    offsetY: 72,
    width: 260,
    height: 124,
  },
};

export default config;
