import description from './description';
import MaterialMovementOperatorAssist1 from './elements/materialMovementOperatorAssist_1';
import MaterialMovementOperatorAssist2 from './elements/materialMovementOperatorAssist_2';
import MaterialMovementOperatorAssist3 from './elements/materialMovementOperatorAssist_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [57, 69.2], width: 7.375, rotation: [55, 0] }],
  dancingAnts: [['hs1', [0, 83], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [57, 69.2], offset: [5.7, 1.75] },
    { id: 'tb2', position: [57, 69.2], offset: [5.7, 5] },
  ],
  elements: [
    new MaterialMovementOperatorAssist1([67.1, 18.2], { cornerFrom: ['bottom', 'left'] }),
    new MaterialMovementOperatorAssist2([75, 42.9]),
    new MaterialMovementOperatorAssist3([75, 57.4]),
  ],
};

export default config;
