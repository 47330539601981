const description = {
  id: '3 of 5',
  currentNumber: 3,
  totalNumber: 5,
  code: 3,
  title: 'safety.Personal Alert.title',
  slug: 'personal-alert',
  pdfPath: '03PersonalAlert',
  apiSlug: 'safety-personal-alert',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineprotect-personal-alert',
  description: 'safety.Personal Alert.description',
  video: {
    name: 'path 4_location_7_1.mp4',
    stop: 23,
    backgroundImage: 'Path_4_location_7_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 23,
    dancingAnts: 23.5,
    secondaries: 24,
  },
};

export default description;
