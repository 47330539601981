// @ts-nocheck
import React from 'react';

import Download from './assets/icons/download.svg';

// eslint-disable-next-line react/prop-types
const ButtonDownloadApp = ({ onClick }) => {
  const handleClick = () => {
    onClick({ action: 'openDownload' });
  };

  return (
    <div className="c-download-app">
      {/* eslint-disable-next-line react/button-has-type */}
      <button className="c-button c-button--size-default" onClick={handleClick}>
        <div className="c-button__icon">
          <Download />
        </div>
      </button>
    </div>
  );
};

export default ButtonDownloadApp;
