import Box from 'modules/common/components/Box';

export default class ExplorationResourceModel5 {
  constructor(position, cornerFrom) {
    this.id = 'explorationResourceModel_5';
    this.component = Box;
    this.type = 'content';
    this.title = 'exploration.Resource Model.elements.Interpolation';
    this.content = 'exploration.Resource Model.elements.Interpolation Content';
    this.props = {
      target: 'tb4',
      position,
      hashtags: ['digitalisation'],
      cornerFrom: cornerFrom || null,
    };
  }
}
