import description from './description';
import ExplorationRockCharacterization1 from './elements/explorationRockCharacterization_1';
import ExplorationRockCharacterization2 from './elements/explorationRockCharacterization_2';
import ExplorationRockCharacterization3 from './elements/explorationRockCharacterization_3';
import ExplorationRockCharacterization4 from './elements/explorationRockCharacterization_4';
import ExplorationRockCharacterization5 from './elements/explorationRockCharacterization_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 53.7], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 32.03], { direction: 'reverse' }],
    ['hs1', [0, 19.33], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 53.7], offset: [0.1, 8.5] },
    { id: 'tb2', position: [16, 53.7], offset: [11.6, 8.5] },
    { id: 'tb3', position: [16, 53.7], offset: [5.7, 12] },
    { id: 'tb4', position: [16, 53.7], offset: [11.6, 3.2] },
  ],
  elements: [
    new ExplorationRockCharacterization1([11.9, 19.9]),
    new ExplorationRockCharacterization2([11.9, 74.4]),
    new ExplorationRockCharacterization3([27.6, 59.8], { cornerFrom: ['bottom', 'left'] }),
    new ExplorationRockCharacterization4([27.6, 72.1]),
    new ExplorationRockCharacterization5([27.6, 47.3]),
  ],
};

export default config;
