export default [
  {
    id: 0,
    name: 'exploration.Exploration',
    slug: 'exploration',
    icon: 'side-icon.png',
    description: 'exploration.workflowDescription',
  },
  {
    id: 1,
    name: 'planning.Planning',
    slug: 'planning',
    icon: 'side-icon.png',
    description: 'planning.workflowDescription',
  },
  {
    id: 2,
    name: 'drill-blast.Drill Blast',
    slug: 'drill-blast',
    icon: 'side-icon.png',
    description: 'drill-blast.workflowDescription',
  },
  {
    id: 3,
    name: 'material-movement.Material Movement',
    slug: 'material-movement',
    icon: 'side-icon.png',
    description: 'material-movement.workflowDescription',
  },
  {
    id: 4,
    name: 'safety.Safety',
    slug: 'safety',
    icon: 'side-icon.png',
    description: 'safety.workflowDescription',
  },
  {
    id: 5,
    name: 'survey-monitoring.Survey Monitoring',
    slug: 'survey-monitoring',
    icon: 'side-icon.png',
    description: 'survey-monitoring.workflowDescription',
  },
  {
    id: 6,
    name: 'underground.Underground',
    slug: 'underground',
    icon: 'side-icon.png',
    description: 'underground.workflowDescription',
  },
];
