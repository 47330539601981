/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isTablet, isFirefox } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PdfViewer from './PdfViewer';
import SavePdf from './assets/icons/save.svg';
import ZoomIncrease from './assets/icons/zoom-increase.svg';
import ZoomDecrease from './assets/icons/zoom-decrease.svg';

const PdfViewerContainer = ({ title, currentFilePath }) => {
  const [zoom, setZoom] = useState(1);
  const { t } = useTranslation();

  return !isTablet && !isFirefox ? (
    <iframe
      src={`${currentFilePath}#view=fitH`}
      title={title}
      height="100%"
      width="100%"
      type="application/pdf"
    />
  ) : isFirefox ? (
    <>
      <div className="file-controls">
        <div className="zoom">
          <ZoomIncrease
            onClick={() => {
              setZoom(zoom + 0.1);
            }}
          />
          <ZoomDecrease
            onClick={() => {
              setZoom(zoom - 0.1);
            }}
          />
        </div>
        <a href={currentFilePath} target="_blank " rel="noreferrer">
          <SavePdf />
        </a>
      </div>
      <div className="scroll-container">
        <PdfViewer currentFilePath={currentFilePath} zoom={zoom} />
      </div>
    </>
  ) : (
    <div className="empty-pdf">{t('pdf.empty')}</div>
  );
};

PdfViewerContainer.propTypes = {
  title: PropTypes.string,
  currentFilePath: PropTypes.string,
};

PdfViewerContainer.defaultProps = {
  title: '',
  currentFilePath: '',
};

export default PdfViewerContainer;
