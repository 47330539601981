const description = {
  id: '5 of 5',
  currentNumber: 5,
  totalNumber: 5,
  title: 'survey-monitoring.Dynamic Data.title',
  slug: 'survey-and-monitoring-dynamic-data',
  pdfPath: '05DynamicData',
  apiSlug: 'survey-monitoring-survey-and-monitoring-dynamic-data',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-minemonitoring',
  description: 'survey-monitoring.Dynamic Data.description',
  video: {
    backgroundImage: 'Path_5_location_5_frame.jpg',
    playDefaultSound: true,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
