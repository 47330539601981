import description from './description';
import ExplorationRockCharacterization1 from './elements/explorationRockCharacterization_1';
import ExplorationRockCharacterization2 from './elements/explorationRockCharacterization_2';
import ExplorationRockCharacterization3 from './elements/explorationRockCharacterization_3';
import ExplorationRockCharacterization4 from './elements/explorationRockCharacterization_4';
import ExplorationRockCharacterization5 from './elements/explorationRockCharacterization_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 45], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 32.03], { direction: 'reverse' }],
    ['hs1', [0, 19.33], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 45], offset: [0.1, 4.6] },
    { id: 'tb2', position: [16, 45], offset: [5.5, 4.6] },
    { id: 'tb3', position: [16, 45], offset: [2.9, 6.1] },
    { id: 'tb4', position: [16, 45], offset: [5.65, 1.2] },
  ],
  elements: [
    new ExplorationRockCharacterization1([12, 16.8]),
    new ExplorationRockCharacterization2([12, 63.7]),
    new ExplorationRockCharacterization3([27.6, 51.8], { cornerFrom: ['top', 'left'] }),
    new ExplorationRockCharacterization4([27.6, 63.7]),
    new ExplorationRockCharacterization5([27.6, 40]),
  ],
};

export default config;
