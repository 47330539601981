import StageCard from 'modules/common/components/StageCard';

const config = {
  id: 2,
  title: 'planning.Planning',
  slug: 'introduction',
  apiSlug: 'planning-introduction',
  description: 'planning.description',
  video: {
    backgroundImage: 'Path_2_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 1.5,
    secondaries: 2,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'planningIntroduction_1', component: StageCard }],
};

export default config;
