import Box from 'modules/common/components/Box';

export default class MaterialMovementFleetManagement3 {
  constructor(position, props = {}) {
    this.id = 'materialMovementFleetManagement_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'material-movement.Fleet Management.elements.Autonomy';
    this.content = 'material-movement.Fleet Management.elements.Autonomy Content';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: props.cornerFrom || null,
      hashtags: ['autonomy'],
    };
  }
}
