const description = {
  id: '2 of 5',
  currentNumber: 2,
  totalNumber: 5,
  code: 2,
  title: 'material-movement.Fleet Management.title',
  slug: 'fleet-management',
  pdfPath: '02FleetManagement',
  apiSlug: 'material-movement-fleet-management',
  productDetailsLink:
    'https://hexagon.com/products/product-groups/hxgn-mineoperate/fleet-management',
  description: 'material-movement.Fleet Management.description',
  video: {
    name: 'path 4_location_2.mp4',
    stop: 18.3,
    backgroundImage: 'Path_4_location_2_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
