import Box from 'modules/common/components/Box';

export default class Video {
  constructor(position) {
    this.video = '1b-1.mp4';

    this.title = 'exploration.Exploration Drilling.elements.Drillhole Software';
    this.id = 'explorationDrilling_3';
    this.component = Box;
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['digitalisation'],
    };
  }
}
