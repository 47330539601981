import Box from 'modules/common/components/Box';

export default class MaterialMovementPersonalAlert3 {
  constructor(position) {
    this.id = 'materialMovementPersonalAlert_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Personal Alert.elements.Precision and accuracy';
    this.content = 'safety.Personal Alert.elements.Precision and accuracy Content';
    this.props = {
      target: 'tb3',
      cornerFrom: ['top', 'left'],
      position,
      hashtags: ['healthSafety'],
    };
  }
}
