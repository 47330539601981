/* eslint-disable react/no-unused-prop-types */
/* eslint-disable class-methods-use-this */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Location from 'app/assets/icons/location_dark.svg';
import { withTranslation } from 'react-i18next';

class LocationMarket extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.stageCard = React.createRef();
  }

  getJourneyName(jourNum, jourName) {
    if (!jourNum && !jourName) return '';

    return `${jourNum === null ? '' : `0${jourNum}`.slice(-2)} ${
      jourName === null ? '' : jourName
    }:`.trim();
  }

  render() {
    const { config, jourNum, jourName, locName, t, locСurrentNumber, locTotalNumber } = this.props;

    return (
      <div className={config.className}>
        <div className={`${config.className}__marker-container`}>
          <Location />
        </div>
        <h3 className={`${config.className}__location`}>
          <span className={`${config.className}__location-label`}>
            {`${this.getJourneyName(jourNum, t(jourName))} ${t('Location')} ${t('location.number', {
              current: locСurrentNumber,
              total: locTotalNumber,
            })}`}
          </span>
          <span className={`${config.className}__location-name`}>{t(locName)}</span>
        </h3>
      </div>
    );
  }
}

LocationMarket.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
  }),
  locName: PropTypes.string,
  locId: PropTypes.string,
  locСurrentNumber: PropTypes.string,
  locTotalNumber: PropTypes.string,
  jourName: PropTypes.string,
  jourNum: PropTypes.number,
  t: PropTypes.func,
};

LocationMarket.defaultProps = {
  config: {
    className: 'c-location-marker',
  },
  locName: 'Location',
  locId: 'AA',
  locСurrentNumber: '0',
  locTotalNumber: '0',
  jourName: 'Journey',
  jourNum: 0,
  t: () => {},
};

LocationMarket.id = 'LocationMarket';

const mapStateToProps = () => {
  return {};
};

export default withTranslation()(
  connect(mapStateToProps, null, null, { forwardRef: true })(LocationMarket),
);
