import description from './description';
import PlanningDynamicData1 from './elements/planningDynamicData_1';

const config = {
  ...description,
  id: '4 of 4',
  currentNumber: 4,
  totalNumber: 4,
  hotspots: [{ id: 'hs1', position: [68.63, 68.53], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [41.99, 0]],
    ['hs1', [51.75, 0], { direction: 'reverse' }],
    ['hs1', [61.62, 0]],
    ['hs1', [0, 69.33], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [new PlanningDynamicData1([27.6, 27.15])],
};

export default config;
