import description from './description';
import MaterialMovementFleetManagement1 from './elements/materialMovementFleetManagement_1';
import MaterialMovementFleetManagement2 from './elements/materialMovementFleetManagement_2';
import MaterialMovementFleetManagement3 from './elements/materialMovementFleetManagement_3';
import MaterialMovementFleetManagement4 from './elements/materialMovementFleetManagement_4';
import MaterialMovementFleetManagement5 from './elements/materialMovementFleetManagement_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [18, 42.3], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 22.36]],
    ['hs1', [39.89, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [18, 42.3], offset: [5.7, 1.475] },
    { id: 'tb2', position: [18, 42.3], offset: [5.7, 4.45] },
    { id: 'tb3', position: [18, 42.3], offset: [0.2, 4.45] },
    { id: 'tb4', position: [18, 42.3], offset: [3, 6] },
  ],
  elements: [
    new MaterialMovementFleetManagement1([11.7, 15.3], { size: 'wide' }),
    new MaterialMovementFleetManagement2([51.3, 32.3]),
    new MaterialMovementFleetManagement3([35.4, 45.8]),
    new MaterialMovementFleetManagement4([11.7, 58.6]),
    new MaterialMovementFleetManagement5([35.4, 58.5]),
  ],
};

export default config;
