const description = {
  id: '2 of 5',
  currentNumber: 2,
  totalNumber: 5,
  title: 'survey-monitoring.Planning.title',
  slug: 'planning',
  pdfPath: '02Planning',
  apiSlug: 'survey-monitoring-planning',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-survey',
  description: 'survey-monitoring.Planning.description',
  video: {
    name: 'path 5_location_2.mp4',
    stop: 6.5,
    backgroundImage: 'Path_5_location_2_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 3.5,
    secondaries: 4,
  },
};

export default description;
