import Box from 'modules/common/components/Box';

export default class MaterialMovementOperatorAssist3 {
  constructor(position) {
    this.id = 'materialMovementOperatorAssist_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'material-movement.Operator Assist.elements.Reverse Assist';
    this.content = 'material-movement.Operator Assist.elements.Reverse Assist Content';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['autonomy', 'productivity'],
    };
  }
}
