import description from './description';
import ExplorationResourceModel1 from './elements/explorationResourceModel_1';
import ExplorationResourceModel2 from './elements/explorationResourceModel_2';
import ExplorationResourceModel3 from './elements/explorationResourceModel_3';
import ExplorationResourceModel4 from './elements/explorationResourceModel_4';
import ExplorationResourceModel5 from './elements/explorationResourceModel_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 45.8], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 45.8], offset: [2.9, 6.1] },
    { id: 'tb2', position: [16, 45.8], offset: [0.4, 4.8] },
    { id: 'tb3', position: [16, 45.8], offset: [5.65, 4.8] },
    { id: 'tb4', position: [16, 45.8], offset: [5.65, 1.2] },
  ],
  elements: [
    new ExplorationResourceModel1([12, 16.8]),
    new ExplorationResourceModel2([27.6, 58.8]),
    new ExplorationResourceModel3([12, 62.8]),
    new ExplorationResourceModel4([27.6, 47.9], ['top', 'left']),
    new ExplorationResourceModel5([27.6, 37], ['top', 'left']),
  ],
};

export default config;
