import Box from 'modules/common/components/Box';

export default class MaterialMovementAssetHealth3 {
  constructor(position) {
    this.id = 'materialMovementAssetHealth_3';
    this.component = Box;
    this.title = 'material-movement.Asset Health.title';
    this.content = '';
    this.video = '4d-1.mp4';
    this.props = { target: 'tb2', position, hashtags: ['digitalisation', 'cost'] };
  }
}
