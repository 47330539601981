/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

class ButtonClose extends PureComponent {
  handleClose = () => {
    const { onClick } = this.props;
    onClick({ action: 'close' });
  };

  render() {
    return (
      <button className="c-button c-button--size-sm close-button" onClick={this.handleClose}>
        <div className="c-button__text">
          <svg className="c-video-modal__close-button-svg" viewBox="0 0 100 100">
            <line x1="0" x2="100" y1="100" y2="0" />
            <line x1="0" x2="100" y1="0" y2="100" />
          </svg>
        </div>
      </button>
    );
  }
}

ButtonClose.propTypes = {
  onClick: PropTypes.func,
};

ButtonClose.defaultProps = {
  onClick: () => {},
};

export default connect(null, null, null, { forwardRef: true })(ButtonClose);
