import description from './description';
import MaterialMovementFleetManagement1 from './elements/materialMovementFleetManagement_1';
import MaterialMovementFleetManagement2 from './elements/materialMovementFleetManagement_2';
import MaterialMovementFleetManagement3 from './elements/materialMovementFleetManagement_3';
import MaterialMovementFleetManagement4 from './elements/materialMovementFleetManagement_4';
import MaterialMovementFleetManagement5 from './elements/materialMovementFleetManagement_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 51.5], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 22.36]],
    ['hs1', [39.89, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 51.5], offset: [11.2, 2.4] },
    { id: 'tb2', position: [16, 51.5], offset: [11.2, 9.4] },
    { id: 'tb3', position: [16, 51.5], offset: [0.2, 9.4] },
    { id: 'tb4', position: [16, 51.5], offset: [6, 12] },
  ],
  elements: [
    new MaterialMovementFleetManagement1([12, 18.5]),
    new MaterialMovementFleetManagement2([27.6, 42.5], { cornerFrom: ['top', 'left'] }),
    new MaterialMovementFleetManagement3([27.6, 54.5], { cornerFrom: ['top', 'left'] }),
    new MaterialMovementFleetManagement4([12, 71.4]),
    new MaterialMovementFleetManagement5([27.6, 66.5], { cornerFrom: ['top', 'left'] }),
  ],
};

export default config;
