import CaptureVisualiseDesign from 'modules/planning-capture-visualise-design/configs/config1024';
import ProductionDrilling from 'modules/planning-production-drilling/configs/config1024';
import GradeControl from 'modules/planning-grade-control/configs/config1024';
import PlanningDynamicData from 'modules/planning-dynamic-data/configs/config1024';
import Introduction from 'modules/planning-introduction/configs/config1024';

const config = {
  id: 2,
  title: 'planning.Planning',
  pdfPath: '02Planning',
  slug: 'planning',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_2_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [CaptureVisualiseDesign, ProductionDrilling, GradeControl, PlanningDynamicData],
};

export default config;
