import Box from 'modules/common/components/Box';

export default class UndergroundDevelopmentDrilling3 {
  constructor(position, props = {}) {
    this.id = 'undergroundDevelopmentDrilling_3';
    this.component = Box;
    this.title = 'underground.Development Drilling.elements.Revolutionising UG drilling';
    this.video = '7c-1.mp4';
    this.props = {
      target: props.target || 'tb2',
      position,
      hashtags: ['productivity'],
    };
  }
}
