import Box from 'modules/common/components/Box';

export default class MaterialMovementFleetManagement2 {
  constructor(position, props = {}) {
    this.id = 'materialMovementFleetManagement_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'material-movement.Fleet Management.elements.Optimisation Engine';
    this.content = 'material-movement.Fleet Management.elements.Optimisation Engine Content';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: props.cornerFrom || null,
      hashtags: ['cost', 'productivity'],
    };
  }
}
