import description from './description';
import MaterialMovementSchedulingOptimising1 from './elements/materialMovementSchedulingOptimising_1';
import MaterialMovementSchedulingOptimising2 from './elements/materialMovementSchedulingOptimising_2';
import MaterialMovementSchedulingOptimising3 from './elements/materialMovementSchedulingOptimising_3';
import MaterialMovementSchedulingOptimising4 from './elements/materialMovementSchedulingOptimising_4';
import MaterialMovementSchedulingOptimising5 from './elements/materialMovementSchedulingOptimising_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 47], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 25.29], { direction: 'reverse' }],
    ['hs1', [0, 76.07]],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 47], offset: [5.7, 1.475] },
    { id: 'tb2', position: [16, 47], offset: [0.2, 4.45] },
    { id: 'tb3', position: [16, 47], offset: [5.7, 4.45] },
    { id: 'tb4', position: [16, 47], offset: [3, 6] },
  ],
  elements: [
    new MaterialMovementSchedulingOptimising1([11.8, 16.8]),
    new MaterialMovementSchedulingOptimising2([27.6, 36.9]), // .Activity Scheduler content
    new MaterialMovementSchedulingOptimising3([11.8, 64.35]), // .Activity Scheduler video
    new MaterialMovementSchedulingOptimising4([27.6, 47.5], { cornerFrom: ['top', 'left'] }), // Schedule OPTIMISER content
    new MaterialMovementSchedulingOptimising5([27.8, 58.8]), // Schedule OPTIMISER video
  ],
};

export default config;
