const description = {
  id: '6 of 7',
  currentNumber: 6,
  totalNumber: 7,
  title: 'drill-blast.Fragmentation Analysis.title',
  slug: 'fragmentation-analysis',
  pdfPath: '06FragmentationAnalysis',
  apiSlug: 'drill-blast-fragmentation-analysis',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-split-fragmentation-analysis',
  description: 'drill-blast.Fragmentation Analysis.description',
  video: {
    name: 'path 3_location_6.mp4',
    stop: 9,
    backgroundImage: 'Path_3_location_6_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 7.5,
    secondaries: 8,
  },
};

export default description;
