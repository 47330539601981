const description = {
  id: '1 of 5',
  currentNumber: 1,
  totalNumber: 5,
  code: 1,
  title: 'safety.Vehicle Collision Avoidance.title',
  slug: 'vehicle-collision-avoidance',
  pdfPath: '01VehicleCollisionAvoidance',
  apiSlug: 'safety-vehicle-collision-avoidance',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineprotect-collision-avoidance-system',
  description: 'safety.Vehicle Collision Avoidance.description',
  video: {
    name: 'path 4_location_5.mp4',
    stop: 17,
    backgroundImage: 'Path_4_location_5_frame.jpg',
  },
  drawPoints: {
    hotspots: 13,
    dancingAnts: 17,
    secondaries: 17.5,
  },
};

export default description;
