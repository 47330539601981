import Box from 'modules/common/components/Box';

export default class MaterialMovementDynamicData2 {
  constructor(position) {
    this.id = 'materialMovementDynamicData_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'material-movement.Material Movement Dynamic Data.elements.Bringing data together';
    this.content =
      'material-movement.Material Movement Dynamic Data.elements.Bringing data together Content';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['cost', 'esg', 'productivity', 'dataAnalytics'],
    };
  }
}
