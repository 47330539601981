import { getWindowDimensions } from 'app/helpers';

import LocationExplorationIntroduction from 'modules/exploration-introduction/layouts';
import LocationExplorationDrillholeDesign from 'modules/exploration-drillhole-design/layouts';
import LocationExplorationDrilling from 'modules/exploration-drilling/layouts';
import LocationExplorationResourceModel from 'modules/exploration-resource-model/layouts';
import LocationExplorationRockCharacterization from 'modules/exploration-rock-characterisation/layouts';
import LocationExplorationDynamicData from 'modules/exploration-dynamic-data/layouts';

import LocationPlanningIntroduction from 'modules/planning-introduction/layouts';
import LocationPlanningCaptureVisualiseDesign from 'modules/planning-capture-visualise-design/layouts';
import LocationPlanningDynamicData from 'modules/planning-dynamic-data/layouts';
import LocationPlanningGradeControl from 'modules/planning-grade-control/layouts';
import LocationPlanningProductionDrilling from 'modules/planning-production-drilling/layouts';

import LocationDrillBlastIntroduction from 'modules/drill-blast-introduction/layouts';
import LocationDrillBlastDesign from 'modules/drill-blast-design/layouts';
import LocationDrillBlastDrilling from 'modules/drill-blast-drilling/layouts';
import LocationDrillBlastDynamicData from 'modules/drill-blast-dynamic-data/layouts';
import LocationDrillBlastFragmentationAnalysis from 'modules/drill-blast-fragmentation-analysis/layouts';
import LocationDrillBlastMonitoring from 'modules/drill-blast-monitoring/layouts';
import LocationDrillBlastMovement from 'modules/drill-blast-movement/layouts';
import LocationDrillBlastSafety from 'modules/drill-blast-safety/layouts';

import LocationMaterialMovementSchedulingOptimising from 'modules/material-movement-scheduling-optimising/layouts';
import LocationMaterialMovementFleetManagement from 'modules/material-movement-fleet-management/layouts';
import LocationMaterialMovementOperatorAssist from 'modules/material-movement-operator-assist/layouts';
import LocationMaterialMovementAssetHealth from 'modules/material-movement-asset-health/layouts';
import LocationMaterialMovementDynamicData from 'modules/material-movement-dynamic-data/layouts';
import LocationMaterialMovementIntroduction from 'modules/material-movement-introduction/layouts';

import LocationSafetyIntroduction from 'modules/safety-introduction/layouts';
import LocationSafetyVehicleCollisionAvoidance from 'modules/safety-vehicle-collision-avoidance/layouts';
import LocationSafetytOperatorAlertnessSystem from 'modules/safety-operator-alertness-system/layouts';
import LocationSafetyPersonalAlert from 'modules/safety-personal-alert/layouts';
import LocationSafetySlopeMonitoring from 'modules/safety-slope-monitoring/layouts';
import LocationSafetyDynamicData from 'modules/safety-dynamic-data/layouts';

import LocationSurveyMonitoringIntroduction from 'modules/survey-monitoring-introduction/layouts';
import LocationSurveyMonitoringRealityCapture from 'modules/survey-monitoring-reality-capture/layouts';
import LocationSurveyMonitoringPlanning from 'modules/survey-monitoring-planning/layouts';
import LocationSurveyMonitoringMonitoring from 'modules/survey-monitoring-monitoring/layouts';
import LocationSurveyMonitoringGeotech from 'modules/survey-monitoring-geotech/layouts';
import LocationSurveyMonitoringSurveyAndMonitoringDynamicData from 'modules/survey-monitoring-survey-and-monitoring-dynamic-data/layouts';

import LocationUndergroundIntroduction from 'modules/underground-introduction/layouts';
import LocationUndergroundSafety from 'modules/underground-safety/layouts';
import LocationUndergroundTaskManagement from 'modules/underground-task-management/layouts';
import LocationUndergroundDevelopmentDrilling from 'modules/underground-development-drilling/layouts';

import config1024 from './config1024';
import config1200 from './config1200';
import config3840 from './config3840';
import configDefault from './configDefault';

const config = {
  c1024: config1024,
  c1200: config1200,
  c3840: config3840,
  cDefault: configDefault,
  get current() {
    const { width } = getWindowDimensions();

    if (width < 1200) {
      return config1024;
    }
    if (width >= 1200 && width < 1920) {
      return config1200;
    }

    return configDefault;
  },
  layouts: {
    'exploration-introduction': {
      layout: LocationExplorationIntroduction,
    },
    'exploration-planning': {
      layout: LocationExplorationDrillholeDesign,
    },
    'exploration-exploration-drilling': {
      layout: LocationExplorationDrilling,
    },
    'exploration-resource-model': {
      layout: LocationExplorationResourceModel,
    },
    'exploration-rock-characterisation': {
      layout: LocationExplorationRockCharacterization,
    },
    'exploration-exploration-dynamic-data': {
      layout: LocationExplorationDynamicData,
    },
    'planning-introduction': {
      layout: LocationPlanningIntroduction,
    },
    'planning-capture-visualise-design': {
      layout: LocationPlanningCaptureVisualiseDesign,
    },
    'planning-production-drilling': {
      layout: LocationPlanningProductionDrilling,
    },
    'planning-grade-control': {
      layout: LocationPlanningGradeControl,
    },
    'planning-planning-dynamic-data': {
      layout: LocationPlanningDynamicData,
    },
    'drill-blast-introduction': {
      layout: LocationDrillBlastIntroduction,
    },
    'drill-blast-design': {
      layout: LocationDrillBlastDesign,
    },
    'drill-blast-drilling': {
      layout: LocationDrillBlastDrilling,
    },
    'drill-blast-safety': {
      layout: LocationDrillBlastSafety,
    },
    'drill-blast-blast-monitoring': {
      layout: LocationDrillBlastMonitoring,
    },
    'drill-blast-blast-movement': {
      layout: LocationDrillBlastMovement,
    },
    'drill-blast-fragmentation-analysis': {
      layout: LocationDrillBlastFragmentationAnalysis,
    },
    'drill-blast-dynamic-data': {
      layout: LocationDrillBlastDynamicData,
    },
    'material-movement-introduction': {
      layout: LocationMaterialMovementIntroduction,
    },
    'material-movement-scheduling-optimising': {
      layout: LocationMaterialMovementSchedulingOptimising,
    },
    'material-movement-fleet-management': {
      layout: LocationMaterialMovementFleetManagement,
    },
    'material-movement-operator-assist': {
      layout: LocationMaterialMovementOperatorAssist,
    },
    'material-movement-asset-health': {
      layout: LocationMaterialMovementAssetHealth,
    },
    'material-movement-dynamic-data': {
      layout: LocationMaterialMovementDynamicData,
    },
    'safety-introduction': {
      layout: LocationSafetyIntroduction,
    },
    'safety-vehicle-collision-avoidance': {
      layout: LocationSafetyVehicleCollisionAvoidance,
    },
    'safety-operator-alertness-system': {
      layout: LocationSafetytOperatorAlertnessSystem,
    },
    'safety-personal-alert': {
      layout: LocationSafetyPersonalAlert,
    },
    'safety-slope-monitoring': {
      layout: LocationSafetySlopeMonitoring,
    },
    'safety-dynamic-data': {
      layout: LocationSafetyDynamicData,
    },
    'survey-monitoring-introduction': {
      layout: LocationSurveyMonitoringIntroduction,
    },
    'survey-monitoring-reality-capture': {
      layout: LocationSurveyMonitoringRealityCapture,
    },
    'survey-monitoring-planning': {
      layout: LocationSurveyMonitoringPlanning,
    },
    'survey-monitoring-monitoring': {
      layout: LocationSurveyMonitoringMonitoring,
    },
    'survey-monitoring-geotech': {
      layout: LocationSurveyMonitoringGeotech,
    },
    'survey-monitoring-survey-and-monitoring-dynamic-data': {
      layout: LocationSurveyMonitoringSurveyAndMonitoringDynamicData,
    },
    'underground-introduction': {
      layout: LocationUndergroundIntroduction,
    },
    'underground-safety': {
      layout: LocationUndergroundSafety,
    },
    'underground-task-management': {
      layout: LocationUndergroundTaskManagement,
    },
    'underground-development-drilling': {
      layout: LocationUndergroundDevelopmentDrilling,
    },
  },
};

Object.freeze(config);

export default config;
