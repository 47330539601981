import description from './description';
import MaterialMovementOperatorAlertness1 from './elements/materialMovementOperatorAlertness_1';
import MaterialMovementOperatorAlertness2 from './elements/materialMovementOperatorAlertness_2';
import MaterialMovementOperatorAlertness3 from './elements/materialMovementOperatorAlertness_3';
import MaterialMovementOperatorAlertness4 from './elements/materialMovementOperatorAlertness_4';
import MaterialMovementOperatorAlertness5 from './elements/materialMovementOperatorAlertness_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [18, 43.85], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.5]],
    ['hs1', [0, 84], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [18, 43.85], offset: [5.1, 5.3] },
    { id: 'tb2', position: [18, 43.85], offset: [6, 1.9] },
    { id: 'tb3', position: [18, 43.85], offset: [3.4, 5.9] },
    { id: 'tb4', position: [18, 43.85], offset: [1, 5.3] },
  ],
  elements: [
    new MaterialMovementOperatorAlertness1([11.7, 16.5], { size: 'wide' }),
    new MaterialMovementOperatorAlertness2([11.7, 59]),
    new MaterialMovementOperatorAlertness3([35.4, 59], { target: 'tb3' }),
    new MaterialMovementOperatorAlertness4([44.6, 43.9], { target: 'tb2' }),
    new MaterialMovementOperatorAlertness5([59.2, 59], { target: 'tb1' }),
  ],
};

export default config;
