const description = {
  id: '3 of 5',
  currentNumber: 3,
  totalNumber: 5,
  title: 'survey-monitoring.Monitoring.title',
  slug: 'monitoring',
  pdfPath: '03Monitoring',
  apiSlug: 'survey-monitoring-monitoring',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-minemonitoring',
  description: 'survey-monitoring.Monitoring.description',
  video: {
    name: 'path 5_location_3.mp4',
    stop: 13.5,
    backgroundImage: 'Path_5_location_3_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
