import description from './description';
import MaterialMovementPersonalAlert1 from './elements/materialMovementPersonalAlert_1';
import MaterialMovementPersonalAlert2 from './elements/materialMovementPersonalAlert_2';
import MaterialMovementPersonalAlert3 from './elements/materialMovementPersonalAlert_3';
import MaterialMovementPersonalAlert4 from './elements/materialMovementPersonalAlert_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.09, 49.7], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 29.88], { direction: 'reverse' }],
    ['hs1', [0, 90.42], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.09, 49.7], offset: [0.4, 4.8] },
    { id: 'tb2', position: [16.09, 49.7], offset: [5.5, 4.8] },
    { id: 'tb3', position: [16.09, 49.7], offset: [5.5, 1.1] },
  ],
  elements: [
    new MaterialMovementPersonalAlert1([11.8, 18.8]),
    new MaterialMovementPersonalAlert2([11.8, 65.5]),
    new MaterialMovementPersonalAlert3([27.6, 43.62]),
    new MaterialMovementPersonalAlert4([27.6, 55.6]),
  ],
};

export default config;
