const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  code: 4,
  title: 'safety.Slope Monitoring.title',
  slug: 'slope-monitoring',
  pdfPath: '04SlopeMonitoring',
  apiSlug: 'safety-slope-monitoring',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-minemonitoring',
  description: 'safety.Slope Monitoring.description',
  video: {
    name: 'path 4_location_7.mp4',
    stop: 15.5,
    backgroundImage: 'Path_4_location_7_frame.jpg',
  },
  drawPoints: {
    hotspots: 6,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
