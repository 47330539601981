const description = {
  id: '1 of 5',
  currentNumber: 1,
  totalNumber: 5,
  code: 1,
  title: 'material-movement.Scheduling Optimising.title',
  slug: 'scheduling-optimising',
  pdfPath: '01Scheduling&Optimising',
  apiSlug: 'material-movement-scheduling-optimising',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-open-pit-engineering-short-term',
  description: 'material-movement.Scheduling Optimising.description',
  video: {
    name: 'path 4_location_1.mp4',
    stop: 7.5,
    backgroundImage: 'Path_4_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 8.5,
    secondaries: 9,
  },
};

export default description;
