/* eslint-disable prefer-const */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-danger */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/sort-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from 'app/selectors';

import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import { isChrome, isSafari, isFirefox, isIOS, isTablet, isEdge } from 'react-device-detect';
import {
  accessibleOnClick,
  breakPointHeight,
  getRemAsPx,
  percentToRem,
  isDifferent,
  getPixelsAsPercentage,
} from 'app/helpers';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';

import Hashtags from 'modules/common/components/Hashtags';
import i18n from 'i18nConfig';

const HEADER_HEIGHT = 121;

class Box extends PureComponent {
  constructor(props) {
    super(props);

    this.box = React.createRef();
    this.background = React.createRef();
    this.expandToggle = React.createRef();
    this.expandToggleSVG = React.createRef();

    this.hashtags = React.createRef();
    this.announce = React.createRef();

    this.content = React.createRef();
    this.header = React.createRef();
    this.body = React.createRef();
    this.lineInnerTop = React.createRef();
    this.lineInnerRight = React.createRef();
    this.lineInnerBevel = React.createRef();
    this.lineInnerBottom = React.createRef();
    this.lineInnerLeft = React.createRef();
    // this.lineOuterSVG = React.createRef();
    // this.lineOuterX = React.createRef();
    // this.lineOuterY = React.createRef();
    this.lineConnectorSVG = React.createRef();
    this.lineConnector = React.createRef();

    const corner = {
      selected: ['bottom', 'left'],
      coords: [],
    };

    this.boxRect = {
      top: 0,
      y: 0,
      offsetTop: 0,
      right: 0,
      offsetRight: 0,
      bottom: 0,
      offsetBottom: 0,
      left: 0,
      x: 0,
      offsetLeft: 0,
      width: 0,
      offsetWidth: 0,
      height: 0,
      offsetHeight: 0,
      centerX: 0,
      centerY: 0,
      offsetFromBottom: 0,
    };

    this.origin = [0, 0];

    // this.outerOffsetPx = 0;
    this.bevelOffsetPx = 0;

    this.state = {
      isExpanded: false,
      corner,
      direction: ['bottom', 'left'],
      height: 0,
      lineAtts: {
        innerTop: { x1: '100%', x2: 0 },
        innerRight: { y1: this.bevelOffsetPx },
        innerBevel: {
          x1: 0,
          x2: this.bevelOffsetPx,
          y1: this.bevelOffsetPx,
          y2: 0,
        },
        innerBottom: { x1: this.bevelOffsetPx, x2: '100%' },
        innerLeft: { y1: '100%', y2: 0 },
        // outerX: { x1: 0, x2: '100%', y1: '100%', y2: '100%' },
        // outerY: { x1: 0, x2: 0, y1: '100%', y2: 0 },
        connector: { x1: 0, x2: '100%', y1: '100%', y2: 0 },
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('mousedown', this.checkOutsideClick);
    i18n.on('languageChanged', this.onLanguageChange);
  }

  componentDidUpdate(prevProps, prevState) {
    const { position, hashtags, isExpandable } = this.props;
    const { corner, direction, isExpanded, height } = this.state;

    if (prevState.isExpanded !== isExpanded) {
      this.toggleExpand(prevState.isExpanded);
    }

    if (prevProps.position !== position) {
      this.setPosition();
    }

    if (prevState.corner !== corner) {
      this.drawMainLines();
    }

    if (prevState.direction !== direction) {
      this.drawConnectorLine();
    }

    if (prevState.hashtags !== hashtags && isExpandable) {
      const boxRect = this.setBoxRect();
      const newCorner = this.setCorner();
      this.updateHeight();
      this.updateConnectorLine(boxRect, newCorner);
      this.sizeConnectorLine();
      this.safariFix();
    }

    if (prevState.height !== height) {
      this.updateMainLines();
      this.safariFix();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('mousedown', this.checkOutsideClick);
  }

  safariFix = () => {
    const { config } = this.props;
    if (isSafari || (isChrome && isTablet) || isEdge) {
      this.lineInnerBevel.current.style.transform = `translate(calc(${this.boxRect.width}px - ${config.bevelOffset}), calc(${this.boxRect.height}px - ${config.bevelOffset}))`; // this is how position of bevelLine will be updated
    }
  };

  // TODO: check
  callAfterChangeCoordinateSystem() {
    this.setCorner();
    this.setDirection();
    this.sizeConnectorLine();
  }

  getExpandCorner() {
    const { cornerFrom } = this.props;
    const {
      corner: { selected },
    } = this.state;

    return cornerFrom || selected;
  }

  getCoordinateSystem() {
    return [this.boxRect, this.origin];
  }

  // Positioning
  // These all update props and the dom in accordance with the box and its origins' positions
  setPosition() {
    const { position } = this.props;
    const height = breakPointHeight();

    const topInRem = percentToRem(height, position[1]);

    gsap.set(this.box.current, {
      right: null,
      bottom: null,
      left: !isNaN(position[0]) ? `${position[0]}%` : position[0],
      width: null,
      height: null,
      top: !isNaN(position[1]) ? `${topInRem}rem` : position[1],
    });

    this.updateHeight();
  }

  setBoxRect() {
    const boxRect = this.box?.current?.getBoundingClientRect();

    if (this.box.current) {
      boxRect.offsetTop = this.box?.current?.offsetTop;
      boxRect.offsetLeft = this.box?.current?.offsetLeft;
      boxRect.offsetWidth = this.box?.current?.offsetWidth;
      boxRect.offsetHeight = this.box.current.offsetHeight;
      boxRect.offsetRight = this.box.current.offsetLeft + this.box.current.offsetWidth;
      boxRect.offsetBottom = this.box.current.offsetTop + this.box.current.offsetHeight;
      boxRect.centerX = boxRect.left + boxRect.width / 2;
      boxRect.centerY = boxRect.top + boxRect.height / 2;
      boxRect.offsetFromBottom =
        window.innerHeight - (this.boxRect.offsetTop + this.boxRect.offsetHeight);
      boxRect.offsetFromTop = this.box.current.offsetTop;
      boxRect.offsetFromRight =
        window.innerWidth - (this.boxRect.offsetLeft + this.boxRect.offsetWidth);
      boxRect.offsetFromLeft = this.box.current.offsetLeft;
      this.boxRect = boxRect;
    }

    return this.boxRect;
  }

  setOrigin() {
    const { target } = this.props;

    let origin = null;

    if (target) {
      const targetRect = this.target?.getBoundingClientRect();

      if (targetRect) {
        origin = [targetRect.left + targetRect.width / 2, targetRect.top + targetRect.height / 2]; // X, Y
      } else {
        origin = [this.boxRect.left, this.boxRect.bottom]; // X, Y
      }
    } else {
      origin = [this.boxRect.left, this.boxRect.bottom]; // X, Y
    }

    this.origin = origin;
  }

  setCorner() {
    const { bevelOffsetPx } = this;
    const { cornerFrom } = this.props;

    const selectedCorner = cornerFrom || [
      this.origin[1] - this.boxRect.centerY > 0 ? 'bottom' : 'top',
      this.origin[0] - this.boxRect.centerX > 0 ? 'right' : 'left',
    ];

    // Now to calculate the corner coordinates
    // This is the pure JS way of doing it since the CSS way isn't vueJS friendly
    const baseX = selectedCorner[1] === 'right' ? this.boxRect.right : this.boxRect.left;
    const offsetX =
      // eslint-disable-next-line no-nested-ternary
      selectedCorner[1] === 'left' ? 0 : selectedCorner[0] === 'bottom' ? bevelOffsetPx * -1 : 0;
    const baseY = selectedCorner[0] === 'top' ? this.boxRect.top : this.boxRect.bottom;
    const offsetY = selectedCorner[0] === 'top' ? 0 : 0;

    // Compare and update if different
    const corner = {
      selected: selectedCorner,
      coords: [baseX + offsetX, baseY + offsetY],
    };

    if (isDifferent(this.state.corner, corner)) {
      this.setState({ corner });
    }

    return corner;
  }

  setDirection() {
    const { corner } = this.state;

    const direction = [
      this.origin[1] - corner.coords[1] > 0 ? 'bottom' : 'top',
      this.origin[0] - corner.coords[0] > 0 ? 'right' : 'left',
    ]; // Y, X

    if (isDifferent(this.state.direction, direction)) {
      this.setState({ direction });
    }
  }

  getTimeline() {
    return this.timeline;
  }

  // Utilities
  // These just help things be a little cleaner

  // Determines if this box is drawing to (or expanding from) a specific corner or side
  isCorner(y = null, x = null) {
    return this.isOrientation('corner', y, x);
  }

  isExpandCorner(y = null, x = null) {
    return this.isOrientation('cornerFrom', y, x);
  }

  // Determines if this box is drawing from a specific corner or side
  isDirection(y = null, x = null) {
    return this.isOrientation('direction', y, x);
  }

  // Under the hood work for isCorner and isDirection
  isOrientation(prop, y = null, x = null) {
    let propToGet = null;

    if (prop === 'cornerFrom') {
      propToGet = this.getExpandCorner();
    } else if (prop === 'corner') {
      propToGet = this.state.corner.selected;
    } else {
      propToGet = this.state[prop];
    }

    const isY = propToGet[0] === y;
    const isX = propToGet[1] === x;

    if (y && x) {
      return isY && isX;
    }

    if (y) {
      return isY;
    }

    if (x) {
      return isX;
    }

    return false;
  }

  // Sets all px equivalent REM unit values
  setRemPxSizes() {
    const { config } = this.props;
    // const { width } = getWindowDimensions();
    // let offset = config.outerOffset;

    // if (width < 1200) {
    //   offset = config.outerSmallOffset;
    // }
    // if (width >= 2560) {
    //   offset = config.outerBigOffset;
    // }
    // this.outerOffsetPx = getRemAsPx(offset);
    this.bevelOffsetPx = getRemAsPx(config.bevelOffset);
  }

  animate(isIn = true) {
    if (isIn) {
      this.timeline.restart();
    } else {
      this.timeline.reversed(true);
    }

    return this;
  }

  updateHeight = () => {
    this.setBoxRect();
    this.setState({ height: this.boxRect.offsetHeight });
  };

  onUpdateLines = () => {
    const boxRect = this.setBoxRect();
    this.setState({ height: this.boxRect.offsetHeight });
    const corner = this.setCorner();
    this.updateConnectorLine(boxRect, corner);
  };

  // Expands or collapses the box
  // We need to be careful which corner we anchor to so that the box expands in a sensible way
  toggleExpand(isExpanded) {
    const { config, title, locationName } = this.props;

    if (!isExpanded) {
      sendGTEvent({ event: 'text_modal', modal: `${title}`, location: `${locationName}` });
    }
    const onUpdate = () => {
      this.updateHeight();

      if (!isExpanded && this.boxRect.offsetFromBottom < 0) {
        gsap.to(this.box.current, {
          top: this.boxRect.offsetTop + this.boxRect.offsetFromBottom - 8,
          onUpdate: this.onUpdateLines,
        });
      }

      if (!isExpanded && this.boxRect.offsetFromTop < HEADER_HEIGHT) {
        gsap.to(this.box.current, {
          top: HEADER_HEIGHT,
          bottom: null,
          onUpdate: this.onUpdateLines,
        });
      }

      if (!isExpanded && this.boxRect.offsetFromRight < 0) {
        gsap.to(this.box.current, {
          left: this.boxRect.offsetLeft + this.boxRect.offsetFromRight - 8,
          onUpdate: this.onUpdateLines,
        });
      }

      if (!isExpanded && this.boxRect.offsetFromLeft < 0) {
        gsap.to(this.box.current, {
          left: 8,
          onUpdate: this.onUpdateLines,
        });
      }

      const boxRect = this.setBoxRect();
      const corner = this.setCorner();
      this.updateConnectorLine(boxRect, corner);
      this.sizeConnectorLine();

      this.safariFix();

      if (this.hashtags.current) {
        gsap.set(this.hashtags.current, { opacity: 0 });
      }
    };

    // First, we need to lock dimensions to transition later.
    gsap.set(this.box.current, { height: this.boxRect.height, onUpdate: onUpdate.bind(this) });

    if (this.header.current) {
      gsap.set(this.header.current, { width: '100%' });
    }

    // If we're expanding in any way that isn't from the top left, we need to set the offsets
    // They need to be relative to the corner from which they will expand
    if (!isExpanded && (this.isExpandCorner('bottom') || this.isExpandCorner(null, 'right'))) {
      if (this.isExpandCorner('bottom')) {
        const offset = getPixelsAsPercentage(
          window.innerHeight - (this.boxRect.offsetTop + this.boxRect.offsetHeight),
          'y',
        );

        gsap.set(this.box.current, {
          top: 'auto',
          bottom: `${offset}%`,
          onUpdate: onUpdate.bind(this),
        });
      }

      if (this.isExpandCorner(null, 'right')) {
        const offset = getPixelsAsPercentage(
          window.innerWidth - (this.boxRect.offsetLeft + this.boxRect.offsetWidth),
        );

        gsap.set(this.box.current, {
          left: 'auto',
          right: `${offset}%`,
          onUpdate: onUpdate.bind(this),
        });
      }
    }

    const width = getComputedStyle(this.box.current).getPropertyValue(
      isExpanded ? config.collapsedWidthProp : config.expandedWidthProp,
    );
    const duration = isExpanded ? config.durationCollapse : config.durationExpand;

    const onComplete = () => {
      if (isExpanded) {
        this.drawConnectorLine();
        this.sizeConnectorLine();
        this.setPosition();

        if (this.header.current) {
          gsap.set(this.header.current, { width: null });
        }
      }

      const { boxRect } = this;
      const { hideHashtags } = this.props;
      const corner = this.setCorner();
      this.updateConnectorLine(boxRect, corner);

      if (this.hashtags.current && !hideHashtags) {
        gsap.to(this.hashtags.current, { opacity: 1 });
      }
    };

    // Note that, if we're collapsing, we have to reset the positioning props
    gsap.to(this.box.current, {
      width,
      height: 'auto',
      duration,
      onUpdate: onUpdate.bind(this),
      onComplete: onComplete.bind(this),
    });

    if (this.hashtags.current) {
      gsap.to(this.hashtags.current, { opacity: 0 });
    }
  }

  init() {
    const { target, isExpandable, config } = this.props;
    const { isExpanded } = this.state;

    // Make sure GSAP is always looking gawgeous
    gsap.registerPlugin(DrawSVGPlugin);
    gsap.config({ force3D: true });

    // Set all those pesky elements
    this.target = target ? document.getElementById(target) : null;

    this.contentChildren = this.content.current.children;

    this.expandToggleLines = isExpandable ? this.expandToggleSVG.current.children : false;

    this.lines = [
      this.lineInnerTop.current,
      this.lineInnerRight.current,
      this.lineInnerBevel.current,
      this.lineInnerBottom.current,
      this.lineInnerLeft.current,
      // this.lineOuterX.current,
      // this.lineOuterY.current,
    ];

    if (this.target) {
      this.lines.push(this.lineConnector.current);
    }

    this.setRemPxSizes();
    // this.setBoxRect();
    this.setBoxRect();
    this.setState({ height: this.boxRect.offsetHeight });
    this.setPosition();
    this.setOrigin();
    this.setCorner();
    this.setDirection();
    this.drawMainLines();
    this.drawConnectorLine();
    this.sizeConnectorLine();
    // Now, reset the box visually
    if (isExpanded) {
      this.toggleExpand(isExpanded);
    }

    if (isExpandable) {
      gsap.set(this.expandToggleLines, { drawSVG: false });
    }

    gsap.set(this.lines, { drawSVG: false });
    gsap.set(this.background.current, { opacity: 0 });
    gsap.set(this.contentChildren, {
      y: config.contentChildrenY,
      opacity: 0,
    });
    gsap.set(this.box.current, { visibility: 'visible' });

    // Finally, create the animation timelines and be done
    this.createTheSacredTimeline();

    return this;
  }

  drawMainLines() {
    const { config } = this.props;
    const { lineAtts } = this.state;
    let bevelOffsetValue = 0;

    if (isChrome && !(isIOS && isTablet)) {
      bevelOffsetValue = config.bevelOffset;
    } else if (isSafari || isFirefox || (isChrome && isTablet) || isEdge) {
      this.setRemPxSizes();
      bevelOffsetValue = this.bevelOffsetPx;
    }

    const newLineAtts = {
      innerTop: {
        x1: this.isCorner('top', 'left') ? 0 : '100%',
        x2: this.isCorner('top', 'left') ? '100%' : 0,
      },
      innerRight: {
        y1: bevelOffsetValue,
      },
      innerBevel: {
        x1: this.isCorner('top', 'left') ? bevelOffsetValue : 0,
        x2: this.isCorner('top', 'left') ? 0 : bevelOffsetValue,
        y1: this.isCorner('top', 'left') ? 0 : bevelOffsetValue,
        y2: this.isCorner('top', 'left') ? bevelOffsetValue : 0,
      },
      innerBottom: {
        x1:
          this.isCorner('top', 'left') || this.isCorner('bottom', 'right')
            ? '100%'
            : bevelOffsetValue,
        x2:
          this.isCorner('top', 'left') || this.isCorner('bottom', 'right')
            ? bevelOffsetValue
            : '100%',
      },
      innerLeft: {
        y1: this.isCorner('top', 'left') || this.isCorner('bottom', 'right') ? 0 : '100%',
        y2: this.isCorner('top', 'left') || this.isCorner('bottom', 'right') ? '100%' : 0,
      },
    };

    if (isDifferent(lineAtts, newLineAtts)) {
      this.setState({
        lineAtts: { ...lineAtts, ...newLineAtts },
      });
    }

    this.linesInnerOrigin = [
      this.isCorner('top') ? this.lineInnerTop.current : this.lineInnerBottom.current,
      // eslint-disable-next-line no-nested-ternary
      this.isCorner(null, 'right')
        ? this.isCorner('top')
          ? this.lineInnerRight.current
          : this.lineInnerBevel.current
        : this.lineInnerLeft.current,
    ];
    this.linesInnerOpposite = [
      this.isCorner('top') ? this.lineInnerBottom.current : this.lineInnerTop.current,
      this.isCorner(null, 'right') ? this.lineInnerLeft.current : this.lineInnerRight.current,
      this.isCorner('top') || this.isCorner(null, 'left')
        ? this.lineInnerBevel.current
        : this.lineInnerRight.current,
    ];
  }

  updateMainLines() {
    const { config } = this.props;
    const { lineAtts } = this.state;
    let bevelOffsetValue = 0;

    if (isChrome && !(isIOS && isTablet)) {
      bevelOffsetValue = config.bevelOffset;
    } else if (isSafari || isFirefox || (isChrome && isTablet) || isEdge) {
      this.setRemPxSizes();
      bevelOffsetValue = this.bevelOffsetPx;
    }

    const newLineAtts = {
      innerBevel: {
        x1: this.isCorner('top', 'left') ? bevelOffsetValue : 0,
        x2: this.isCorner('top', 'left') ? 0 : bevelOffsetValue,
        y1: this.isCorner('top', 'left') ? 0 : bevelOffsetValue,
        y2: this.isCorner('top', 'left') ? bevelOffsetValue : 0,
      },
    };

    if (isDifferent(lineAtts, newLineAtts)) {
      this.setState({
        lineAtts: { ...lineAtts, ...newLineAtts },
      });
    }
  }

  drawConnectorLine() {
    const { lineAtts } = this.state;
    const { position } = this.props;
    const height = breakPointHeight();

    const topInRem = percentToRem(height, position[1]);

    gsap.set(this.box.current, {
      top: !isNaN(position[1]) ? `${topInRem}rem` : position[1],
    });

    const connectorAtts = {
      x1: this.isDirection(null, 'right') ? '100%' : 0,
      x2: this.isDirection(null, 'right') ? 0 : '100%',
      y1: this.isDirection('top') ? 0 : '100%',
      y2: this.isDirection('top') ? '100%' : 0,
    };

    if (isDifferent(lineAtts.connector, connectorAtts)) {
      this.setState({
        lineAtts: {
          ...lineAtts,
          connector: connectorAtts,
        },
      });
    }

    if (this.props.isExpandable) {
      this.updateConnectorLine(this.boxRect, this.state.corner);
    }
  }

  sizeConnectorLine() {
    if (!this.props.isExpandable) {
      const { target } = this.props;
      const { corner } = this.state;
      // Now, we can set the height and width of the connector!
      // At this point, it should just the (absolute) difference between these corner coordinates to the origin coordinates
      if (!target) {
        return;
      }

      const connectorWidth = Math.abs(corner.coords[0] - this.origin[0]);
      const connectorHeight = Math.abs(corner.coords[1] - this.origin[1]);

      gsap.set(this.lineConnectorSVG.current, {
        width: connectorWidth,
        height: connectorHeight,
      });
    }
  }

  updateConnectorLine(boxRect, corner) {
    let top = 0;
    let left = 0;

    if (corner.selected[0] === 'bottom' && corner.selected[1] === 'left') {
      top = this.origin[1] - boxRect.top;
      left = -1 * (boxRect.left - this.origin[0]);
    } else if (corner.selected[0] === 'bottom' && corner.selected[1] === 'right') {
      top = this.origin[1] - boxRect.top;
      left = this.origin[0] - boxRect.left;
    } else if (corner.selected[0] === 'top' && corner.selected[1] === 'left') {
      const originY = this.origin[1];
      const cornerY = boxRect.top;
      top = originY - cornerY;
      left = -1 * (boxRect.left - this.origin[0]);
    } else if (corner.selected[0] === 'top' && corner.selected[1] === 'right') {
      top = this.origin[1] - boxRect.top;
      left = this.origin[0] - boxRect.left;
    }

    const vectorX = corner.coords[0] - this.origin[0];
    const vectorY = corner.coords[1] - this.origin[1];
    const angleDeg = (Math.atan2(vectorY, vectorX) * 180) / Math.PI;
    const length = Math.ceil(Math.sqrt(vectorX ** 2 + vectorY ** 2));

    gsap.set(this.lineConnectorSVG.current, {
      width: length,
      left,
      top: `${top}px`,
      transform: `rotate(${angleDeg}deg)`,
      transformOrigin: '0 0',
    });
  }

  // Creates the sacred timelines
  // Note that the labels here are to show how the groups of elements animate relative to eachother in time
  createTheSacredTimeline() {
    const { config, target, isExpandable } = this.props;

    const timeline = gsap.timeline({ paused: true });

    if (target) {
      timeline.addLabel('connector').to(this.lineConnector.current, {
        drawSVG: true,
        duration: config.durationConnector,
      });
    }

    timeline
      .to(
        this.linesInnerOrigin,
        { drawSVG: true, duration: config.durationInnerOrigin },
        `>${config.delayInnerOrigin}`,
      )
      .to(
        this.linesInnerOpposite,
        { drawSVG: true, duration: config.durationInnerOpposite },
        `>${config.delayInnerOpposite}`,
      )
      .to(this.lineInnerBevel.current, { strokeDasharray: 'none' }) // done for drawing bevel line in paths box
      .to(this.background.current, { opacity: 1 }, `>${config.delayBackground}`)
      .to(
        this.contentChildren,
        {
          y: 0,
          opacity: 1,
          visibility: 'visible',
          stagger: config.staggerContentChildren,
          duration: config.durationContentChildren,
        },
        `>${config.delayContentChildren}`,
      );

    if (isExpandable) {
      timeline.to(
        this.expandToggleLines,
        { drawSVG: true, duration: config.durationExpandToggle },
        `<${config.delayExpandToggle}`,
      );
    }

    this.timeline = timeline;

    return this;
  }

  onLanguageChange = () => {
    setTimeout(() => {
      this.onResize();
    }, 0);
  };

  onResize = () => {
    this.setRemPxSizes();
    this.updateHeight();
    this.setOrigin();
    this.callAfterChangeCoordinateSystem();
    this.getCoordinateSystem();
    this.drawConnectorLine();

    const { isExpanded } = this.state;

    if (isExpanded) {
      this.toggleExpand(!isExpanded);
    }
  };

  checkOutsideClick = (e) => {
    const { isExpanded } = this.state;
    if (isExpanded && this.box.current && !this.box.current.contains(e.target)) {
      this.setState({ isExpanded: !isExpanded });
    }
  };

  onExpandToggleClick = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  render() {
    const {
      config,
      contentAdditionalClasses,
      additionalClasses,
      color,
      size,
      id,
      isExpandable,
      target,
      box,
      index,
      children,
      // hideOuterLine,
      hashtags,
      // announce,
    } = this.props;

    const { isExpanded, corner, direction, lineAtts } = this.state;

    // TODO: TEMP!  REMOVE
    const $slots = {
      header:
        !!box &&
        !!box.headerSlot &&
        (box.headerSlot.components.length > 0 || box.headerSlot.content.length > 0),
      body:
        !!box &&
        !!box.bodySlot &&
        (box.bodySlot.components.length > 0 || box.bodySlot.content.length > 0),
      announce:
        !!box &&
        !!box.announceSlot &&
        (box.announceSlot.components.length > 0 || box.announceSlot.content.length > 0),

      default: !!box && !!box.defaultSlot,
    };

    return (
      <div
        ref={this.box}
        className={`${config.className} ${config.className}--color-${color} ${
          config.className
        }--size-${size} ${config.className}--corner-${corner.selected[0]}-${corner.selected[1]} ${
          config.className
        }--direction-${direction[0]}-${direction[1]} ${
          isExpandable ? config.expandableClass : ''
        } ${isExpanded ? config.expandedClass : ''} ${
          (Array.isArray(additionalClasses) && additionalClasses.join(' ')) || additionalClasses
        }`}
      >
        <div ref={this.background} className={`${config.className}__background`} />

        {isExpandable && (
          <button
            ref={this.expandToggle}
            className={`${config.className}__expand-toggle`}
            onClick={this.onExpandToggleClick}
          >
            <svg
              ref={this.expandToggleSVG}
              viewBox="0 0 100 100"
              className={`${config.className}__expand-toggle-icon`}
            >
              <line x1="55" x2="100" y1="45" y2="0" />
              <line x1="100" x2="65" y1="0" y2="0" />
              <line x1="100" x2="100" y1="0" y2="35" />
              <line x1="45" x2="0" y1="55" y2="100" />
              <line x1="0" x2="0" y1="100" y2="65" />
              <line x1="0" x2="35" y1="100" y2="100" />
            </svg>
          </button>
        )}

        <div
          ref={this.content}
          className={`${config.className}__content ${
            (Array.isArray(contentAdditionalClasses) && contentAdditionalClasses.join(' ')) ||
            contentAdditionalClasses
          }`}
        >
          {$slots.header && (
            <header
              {...accessibleOnClick(this.onExpandToggleClick)}
              ref={this.header}
              className={`${config.className}__header`}
            >
              {box.headerSlot.components.map((component, cindex) => {
                return (
                  <component.name
                    key={`box${index}headerComponents${cindex}`}
                    {...component.props}
                  />
                );
              })}

              {box.headerSlot.content.map((component, cindex) => {
                return (
                  <component.name
                    key={`box${index}headerContent${cindex}`}
                    {...component.props}
                    dangerouslySetInnerHTML={{ __html: component.html }}
                  />
                );
              })}
            </header>
          )}

          {$slots.body && (
            <div ref={this.body} className={`${config.className}__body`}>
              {box.bodySlot.components.map((component, cindex) => {
                return (
                  <component.name key={`box${index}bodyComponents${cindex}`} {...component.props} />
                );
              })}

              {box.bodySlot.content.map((component, cindex) => {
                return (
                  <component.name
                    key={`box${index}bodyContent${cindex}`}
                    {...component.props}
                    dangerouslySetInnerHTML={{ __html: component.html }}
                  />
                );
              })}
            </div>
          )}

          {$slots.default &&
            box.defaultSlot.components.map((component, cindex) => {
              return (
                <component.name
                  key={`box${index}defaultComponents${cindex}`}
                  {...component.props}
                />
              );
            })}

          {$slots.default &&
            box.defaultSlot.content.map((component, cindex) => {
              return (
                <component.name
                  key={`box${index}defaultContent${cindex}`}
                  {...component.props}
                  dangerouslySetInnerHTML={{ __html: component.html }}
                />
              );
            })}

          {$slots.announce && (
            <div ref={this.announce}>
              {box.announceSlot.components.map((component, cindex) => {
                return (
                  <component.name
                    key={`box${index}announceComponents${cindex}`}
                    {...component.props}
                  />
                );
              })}
            </div>
          )}

          {!$slots.default && children}

          {hashtags && isExpandable && (
            <div ref={this.hashtags}>
              <Hashtags tags={hashtags} id={id} key={id} />
            </div>
          )}
        </div>

        <svg className={`${config.className}__inner-line`}>
          <line
            ref={this.lineInnerTop}
            x1={lineAtts.innerTop.x1}
            x2={lineAtts.innerTop.x2}
            y1="0"
            y2="0"
            style={{ strokeDasharray: '0px, 999999px' }}
          />
          <line
            ref={this.lineInnerRight}
            x1="100%"
            x2="100%"
            y1={lineAtts.innerRight.y1}
            y2="100%"
            style={{ strokeDasharray: '0px, 999999px' }}
          />
          <line
            ref={this.lineInnerBevel}
            x1={lineAtts.innerBevel.x1}
            x2={lineAtts.innerBevel.x2}
            y1={lineAtts.innerBevel.y1}
            y2={lineAtts.innerBevel.y2}
            style={{ strokeDasharray: '0px, 999999px' }}
          />
          <line
            ref={this.lineInnerBottom}
            x1={lineAtts.innerBottom.x1}
            x2={lineAtts.innerBottom.x2}
            y1="100%"
            y2="100%"
            style={{ strokeDasharray: '0px, 999999px' }}
          />
          <line
            ref={this.lineInnerLeft}
            x1="0"
            x2="0"
            y1={lineAtts.innerLeft.y1}
            y2={lineAtts.innerLeft.y2}
            style={{ strokeDasharray: '0px, 999999px' }}
          />
        </svg>

        {/* <svg
          ref={this.lineOuterSVG}
          className={`${config.className}__outer-line`}
          style={{
            visibility: hideOuterLine ? 'hidden' : 'visible',
          }}
        >
          <line
            ref={this.lineOuterX}
            x1={lineAtts.outerX.x1}
            x2={lineAtts.outerX.x2}
            y1={lineAtts.outerX.y1}
            y2={lineAtts.outerX.y2}
            style={{ strokeDasharray: '0px, 999999px' }}
          />
          <line
            ref={this.lineOuterY}
            x1={lineAtts.outerY.x1}
            x2={lineAtts.outerY.x2}
            y1={lineAtts.outerY.y1}
            y2={lineAtts.outerY.y2}
            style={{ strokeDasharray: '0px, 999999px' }}
          />
        </svg> */}

        {target && (
          <svg
            ref={this.lineConnectorSVG}
            className={`${config.className}__connector-line ${
              !isExpandable ? `${config.className}__connector-line-fixed` : ''
            }`}
          >
            {isExpandable ? (
              <line
                ref={this.lineConnector}
                x1="0"
                x2="100%"
                y1="0"
                y2="0"
                style={{ strokeDasharray: '0px, 999999px' }}
              />
            ) : (
              <line
                ref={this.lineConnector}
                x1={lineAtts.connector.x1}
                x2={lineAtts.connector.x2}
                y1={lineAtts.connector.y1}
                y2={lineAtts.connector.y2}
                style={{ strokeDasharray: '0px, 999999px' }}
              />
            )}
          </svg>
        )}
      </div>
    );
  }
}

const config = {
  className: 'c-box',
  expandableClass: 'is-expandable',
  expandedClass: 'is-expanded',
  collapsedWidthProp: '--collapsed-width',
  expandedWidthProp: '--expanded-width',
  bevelOffset: '2rem',
  durationCollapse: 0.4,
  durationExpand: 0.5,
  durationConnector: 0.5,
  delayInnerOrigin: -0.5,
  durationInnerOrigin: 0.5,
  delayBackground: -0.35,
  durationBackground: 0.75,
  delayInnerOpposite: -0.5,
  durationInnerOpposite: 0.5,
  delayExpandToggle: -0.25,
  durationExpandToggle: 0.75,
  contentChildrenY: '0.75rem',
  delayContentChildren: -0.25,
  staggerContentChildren: 0.15,
  durationContentChildren: 0.2,
};

Box.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    expandableClass: PropTypes.string,
    expandedClass: PropTypes.string,
    collapsedWidthProp: PropTypes.string,
    expandedWidthProp: PropTypes.string,
    // outerOffset: PropTypes.string,
    bevelOffset: PropTypes.string,
    durationCollapse: PropTypes.number,
    durationExpand: PropTypes.number,
    durationConnector: PropTypes.number,
    // delayOuter: PropTypes.number,
    // durationOuter: PropTypes.number,
    delayInnerOrigin: PropTypes.number,
    durationInnerOrigin: PropTypes.number,
    delayBackground: PropTypes.number,
    durationBackground: PropTypes.number,
    delayInnerOpposite: PropTypes.number,
    durationInnerOpposite: PropTypes.number,
    delayExpandToggle: PropTypes.number,
    durationExpandToggle: PropTypes.number,
    contentChildrenY: PropTypes.string,
    delayContentChildren: PropTypes.number,
    staggerContentChildren: PropTypes.number,
    durationContentChildren: PropTypes.number,
  }),
  contentAdditionalClasses: PropTypes.any,
  additionalClasses: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
  cornerFrom: PropTypes.array,
  id: PropTypes.string,
  isExpandable: PropTypes.bool,
  position: PropTypes.array,
  target: PropTypes.string,
  index: PropTypes.number,
  // hideOuterLine: PropTypes.bool,
  hashtags: PropTypes.array,
  title: PropTypes.string,
  locationName: PropTypes.string,
  lang: PropTypes.any,
};

Box.defaultProps = {
  config,
  contentAdditionalClasses: [],
  additionalClasses: [],
  color: 'default',
  size: 'default',
  cornerFrom: null,
  id: '',
  isExpandable: false,
  position: ['auto', 'auto'],
  target: '',
  index: 0,
  // hideOuterLine: false,
  hashtags: [],
  title: '',
  locationName: '',
  lang: null,
};

Box.id = 'Box';

const emptyArr = [];

const mapStateToProps = (state, props) => {
  return {
    additionalClasses: props.additionalClasses || emptyArr,
    contentAdditionalClasses: props.contentAdditionalClasses || emptyArr,
    hideHashtags: selectors.getHideHashtags(state),
    lang: i18n.language,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Box);
