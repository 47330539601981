const description = {
  id: '1 of 7',
  currentNumber: 1,
  totalNumber: 7,
  title: 'drill-blast.Blast Design.title',
  slug: 'blast-design',
  pdfPath: '01BlastDesign',
  apiSlug: 'drill-blast-design',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-open-pit-engineering-operational',
  description: 'drill-blast.Blast Design.description',
  video: {
    name: 'path 3_location_1.mp4',
    stop: 17,
    backgroundImage: 'Path_3_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 17,
    secondaries: 17.5,
  },
};

export default description;
