import Introduction from 'modules/drill-blast-introduction/configs/config1024';
import DrillBlastDesign from 'modules/drill-blast-design/configs/config1024';
import DrillBlastDrilling from 'modules/drill-blast-drilling/configs/config1024';
import DrillBlastSafety from 'modules/drill-blast-safety/configs/config1024';
import DrillBlastMonitoring from 'modules/drill-blast-monitoring/configs/config1024';
import DrillBlastMovement from 'modules/drill-blast-movement/configs/config1024';
import DrillBlastFragmentationAnalysis from 'modules/drill-blast-fragmentation-analysis/configs/config1024';
import DrillBlastDynamicData from 'modules/drill-blast-dynamic-data/configs/config1024';

const config = {
  id: 3,
  title: 'drill-blast.Drill Blast',
  slug: 'drill-blast',
  pdfPath: '03Drill&Blast',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_3_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [
    DrillBlastDesign,
    DrillBlastDrilling,
    DrillBlastSafety,
    DrillBlastMonitoring,
    DrillBlastMovement,
    DrillBlastFragmentationAnalysis,
    DrillBlastDynamicData,
  ],
};

export default config;
