import Box from 'modules/common/components/Box';

export default class ExplorationPlanning2 {
  constructor(position) {
    this.id = 'explorationPlanning_2';
    this.component = Box;
    this.title = 'exploration.Drillhole Design.elements.Mineplan Overview';
    this.content = '';
    this.img = '';
    this.video = '1a-1.mp4';
    this.props = { target: 'tb1', position, hashtags: ['cost', 'productivity'] };
  }
}
