import StageCard from 'modules/common/components/StageCard';

const description = {
  id: 6,
  currentNumber: 6,
  title: 'survey-monitoring.Survey Monitoring',
  slug: 'introduction',
  apiSlug: 'survey-monitoring-introduction',
  description: 'survey-monitoring.description',
  video: {
    backgroundImage: 'Path_5_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1,
    secondaries: 1,
  },
  hotspots: [],
  tinyBois: [],
  elements: [{ id: 'surveyMonitoringIntroduction_1', component: StageCard }],
};

export default description;
