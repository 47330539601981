import Box from 'modules/common/components/Box';

export default class MaterialMovementAssetHealth4 {
  constructor(position) {
    this.id = 'materialMovementAssetHealth_4';
    this.component = Box;
    this.type = 'content';
    this.title = 'material-movement.Asset Health.elements.Enterprise Asset Management';
    this.content = 'material-movement.Asset Health.elements.Enterprise Asset Management Content';
    this.props = {
      cornerFrom: ['top', 'left'],
      position,
    };
  }
}
