import Introduction from 'modules/lead-journeys/configs/config1024';
import description from './description';

const config = {
  ...description,
  info: Introduction,
  locations: [],
  activeLocation: null,
};

export default config;
