import description from './description';
import ExplorationDynamicData1 from './elements/explorationDynamicData_1';
import ExplorationDynamicData2 from './elements/explorationDynamicData_2';
import ExplorationDynamicData3 from './elements/explorationDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [52.44, 57.16], width: 6.875, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [
    new ExplorationDynamicData1([12.4, 15.88], { size: 'wide' }),
    new ExplorationDynamicData2([59.27, 16.27]),
    new ExplorationDynamicData3([67.08, 40.23]),
  ],
};

export default config;
