import Box from 'modules/common/components/Box';

export default class DrillBlastBlastDynamicData1 {
  constructor(position) {
    this.id = 'drillBlastBlastDynamicData_1';
    this.component = Box;
    this.isPrimary = true;
    this.type = 'marker';
    this.props = { target: 'hs1', position, cornerFrom: ['top', 'right'] };
  }
}
