/* eslint-disable no-empty */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable prettier/prettier */
// @ts-nocheck
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import * as THREE from 'three';

// import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import config from 'modules/workflows-overview/config';

import Buildings from 'modules/workflows-overview/assets/models/Hexagon_Buildings_GLB.glb';
import Terrain_Tunnel_Entrance from 'modules/workflows-overview/assets/models/Terrain_Tunnel_Entrance.glb';

import DrillingMachine_Solo from 'modules/workflows-overview/assets/models/Hexagon_DrillingMachine_Solo_v3.glb';
import DrillSpots_Solo from 'modules/workflows-overview/assets/models/3JS_Hexagon_DrillingSpots_Solo_v2.glb';

import Planning_Combined from 'modules/workflows-overview/assets/models/3JS_Hexagon_Planing_Combined_Reverse_v5.glb';

import Monitoring_Blocks from 'modules/workflows-overview/assets/models/3JS_Hexagon_Monitoring_Combined_Reverse_v4.glb';

import Drill_Blast from 'modules/workflows-overview/assets/models/3JS_Hexagon_Drill_Blast_Scene_v4.glb';
import Material_Movement from 'modules/workflows-overview/assets/models/3JS_Hexagon_Material_Move_Export_v5.glb';

import Terrain from 'modules/workflows-overview/assets/models/Hexagon_Terrain_v3.glb';
import Terrain_Explosion from 'modules/workflows-overview/assets/models/Hexagon_Terrain_v3_Expl_Transp.glb';

import Safety from 'modules/workflows-overview/assets/models/3JS_Personal_Safety.glb';

import CustomSinCurve from 'modules/workflows-overview/services/CustomSinCurve';

// import TerrainSurface_Color from 'modules/threejs/assets/models/Tex/Terrain_combined_UWs.png';
// import TerrainAmbient_Occlusion from 'modules/threejs/assets/models/Tex/TerrainAmbient_Occlusion.jpg';

import { getPxAsRem } from 'app/helpers';
import { GLTFLoader } from './GLTFLoader';

import { READY, PENDING } from './constants';

import { get, set } from './DB';

export default class SceneService {
  constructor(options = {}) {
    this.state = null;

    this.position = 0;

    this.fraction = 0;

    if (options?.listener) {
      this.listener = options.listener;
    }

    this.indicator = {
      totalSize: this.getTotalSizeModels(),
      loadedSize: 0,
      percentages: 0,
      models: {},
      count: 0,
    };

    this.scene = new THREE.Scene();

    this.lods = {
      // Camera
      camera: {
        lod: new THREE.LOD(),
        name: 'camera',
        path: {
          points: [
            /* {
              position: new THREE.Vector3(438.20587328769335, 1353.792084095213, 1491.6994876375825),
            }, */
            {
              position: new THREE.Vector3(409.3355381477025, 1218.553880895423, 1347.210276468731),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(149.50252188778518, 1.4100520973129536, 46.80737594906871),
        },
        hideBadges: {
          positionY: 30,
        },
      },
      terrain: {
        lod: new THREE.LOD(),
        revision: config.models.terrain.revision,
      },
      // Exploration
      DrillingMachine_Solo: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.DrillingMachine_Solo.revision,
        position: config.models.vehicles.DrillingMachine_Solo.position,
        model: DrillingMachine_Solo,
        name: 'DrillingMachine_Solo',
        originName: 'PositionPoint_Drilling_Machine',
        path: {
          points: [
            /* {
              position: new THREE.Vector3(450.55030719923656, 130.2951201563639, -727.6341255010008),
            }, */
            {
              position: new THREE.Vector3(
                490.4628987982312,
                150.61108872024278,
                -940.9113965804681,
              ), // 393.342535324219, 68.26337986735643, -830.7478629101969),
            },
            /* { v2 
              position: new THREE.Vector3(-311.93116885618184, 250.5165123413119, -505.674182472378),
            }, */
            /* {
              position: new THREE.Vector3(-363.95526569077686, 376.63476367952137, -849.7270457953941),
            }, */
            /* { v2
              position: new THREE.Vector3(70.69770967112436, 124.12865876562921, -671.1564700094791),
            },
            {
              position: new THREE.Vector3(377.6929204235542, 68.95921420262597, -697.8745126422433), // 393.342535324219, 68.26337986735643, -830.7478629101969),
            } */
          ],
          currentIndex: -1,
          target: new THREE.Vector3(572.8997334821545, 72.1832995158836, -760.1727425096522),
        },
        target: new THREE.Vector3(1463.9986572265625, 210.15057373046875, -836.0482788085938),
        distanceForStopAnimation: 1500,
      },
      DrillSpots_Solo: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.DrillSpots_Solo.revision,
        position: config.models.vehicles.DrillSpots_Solo.position,
        model: DrillSpots_Solo,
        target: new THREE.Vector3(1463.9986572265625, 210.15057373046875, -836.0482788085938),
        distanceForStopAnimation: 1500,
      },
      // Planning
      Planning_Combined: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Planning_Combined.revision,
        position: config.models.vehicles.Planning_Combined.position,
        model: Planning_Combined,
        name: 'Planning_Combined',
        originName: 'Planning_Combined',
        path: {
          points: [
            /* { 
              position: new THREE.Vector3(116.14469780198894, 230.98049098785305, 30.44771899042965),
            }, */
            {
              position: new THREE.Vector3( // -72.76822827757042, y: 20.013866507845698, z: -68.17910079758768
                -72.76822827757042,
                20.013866507845698,
                -68.17910079758768,
                // -78.80612510780544,
                // 5.00327862665293,
                // -85.905523260352751,
              ),
            },
            /* {
              position: new THREE.Vector3(
                -20.80612510780544,
                15.90327862665293,
                -110.905523260352751,
              ),
            }, */
          ],
          currentIndex: -1,
          target: new THREE.Vector3(-20.80612510780544, 15.90327862665293, -110.905523260352751),
          offsetTarget: 100,
          movingTime: 3000,
        },
        hideObjects: [
          /* 'ABC_Schallwellen_Horizontal_Control',
          'GridSelection_MotionGroups',
          'Terrain_Base_Plate_Triangle',
          // 'Drone',
          'ScanPlane_Drone', */
          // 'Drone',
          // 'Leica_Cam',
          // 'GS1_Morph_Meshes',
          // 'GS2_Morph_Meshes',
          // 'GS3_Morph_Meshes',
          // 'GS4_Morph_Meshes',
          // 'GS5_Morph_Meshes',
          'Shine',
          'Drone',
        ],
        target: new THREE.Vector3(-38.73071386792841, 108.6277546208801, -245.32516265718897),
        distanceForStopAnimation: 600,
      },
      Planning_Drone_Scan_Solo: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Planning_Drone_Scan_Solo.revision,
        position: config.models.vehicles.Planning_Drone_Scan_Solo.position,
        // model: Planning_Drone_Scan_Solo,
        name: 'Planning_Drone_Scan_Solo',
        originName: 'Planning_Drone_Scan_Solo',
        path: {
          points: [
            {
              position: new THREE.Vector3(
                150.14469780198894,
                322.98049098785305,
                89.44771899042965,
              ),
            },
            {
              position: new THREE.Vector3(
                106.80612510780544,
                215.90327862665293,
                -7.905523260352751,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(),
          offsetTarget: 100,
        },
        hideObjects: [],
      },
      Planning_GridMotion_Solo: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Planning_GridMotion_Solo.revision,
        position: config.models.vehicles.Planning_GridMotion_Solo.position,
        // model: Planning_GridMotion_Solo,
        name: 'Planning_GridMotion_Solo',
        originName: 'Planning_GridMotion_Solo',
        path: {
          points: [
            {
              position: new THREE.Vector3(
                150.14469780198894,
                322.98049098785305,
                89.44771899042965,
              ),
            },
            {
              position: new THREE.Vector3(
                106.80612510780544,
                215.90327862665293,
                -7.905523260352751,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(),
          offsetTarget: 100,
        },
        hideObjects: [],
      },
      Planning_ScanDevice_Waves_Solo: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Planning_ScanDevice_Waves_Solo.revision,
        position: config.models.vehicles.Planning_ScanDevice_Waves_Solo.position,
        // model: Planning_ScanDevice_Waves_Solo,
        name: 'Planning_ScanDevice_Waves_Solo',
        originName: 'Planning_ScanDevice_Waves_Solo',
        path: {
          points: [
            {
              position: new THREE.Vector3(
                150.14469780198894,
                322.98049098785305,
                89.44771899042965,
              ),
            },
            {
              position: new THREE.Vector3(
                106.80612510780544,
                215.90327862665293,
                -7.905523260352751,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(),
          offsetTarget: 100,
        },
        hideObjects: [],
      },
      Buildings: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Buildings.revision,
        position: config.models.vehicles.Buildings.position,
        model: Buildings,
        name: 'Buildings',
        originName: 'Buildings',
        path: {
          points: [],
          currentIndex: -1,
          target: new THREE.Vector3(),
          offsetTarget: 100,
        },
        hideObjects: [],
      },
      Terrain_Tunnel_Entrance: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Terrain_Tunnel_Entrance.revision,
        position: config.models.vehicles.Terrain_Tunnel_Entrance.position,
        model: Terrain_Tunnel_Entrance,
        name: 'Terrain_Tunnel_Entrance',
        originName: 'Terrain_Tunnel_Entrance',
        path: {
          points: [],
          currentIndex: -1,
          target: new THREE.Vector3(),
          offsetTarget: 100,
        },
        hideObjects: [],
      },
      // Survey & Monitoring
      Monitoring_Blocks: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Monitoring_Blocks.revision,
        position: config.models.vehicles.Monitoring_Blocks.position,
        model: Monitoring_Blocks,
        name: 'Monitoring_Blocks',
        originName: 'Monitoring_Blocks',
        path: {
          points: [
            /* { 
              position: new THREE.Vector3(-223.3187865917874, 124.3142839769906, -130.93301269164579),
            }, */
            {
              position: new THREE.Vector3( // -212.49935148477505, y: 119.56323845265432, z: -121.9756332694208
                -212.49935148477505,
                119.56323845265432,
                -121.9756332694208,
                // -220.20304497467112,
                // 105.94823035517612,
                // -140.176860181895,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(-0.5293349390362891, 95.02942657470705967, -50.13590528145198),
          offsetTarget: 100,
        },
        hideObjects: [
          'ArcSAR_ARM_ROTATOR',
          // 'ArcSAR_TRAILER',
        ], // -1408.9149169921875, y: 79.01423645019531, z: 889.449462890625
        target: new THREE.Vector3(418.7418465155095, 93.24946948219389, 83.47662854862989),
        distanceForStopAnimation: 1000,
      },
      // Drill & Blast
      Drill_Blast: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Drill_Blast.revision,
        position: config.models.vehicles.Drill_Blast.position,
        model: Drill_Blast,
        name: 'Drill_Blast',
        originName: 'Drill_Blast',
        path: {
          points: [
            /* { 
              position: new THREE.Vector3(124.4069031370867, 95.77736564925837, -96.00653801689839),
            }, */
            {
              position: new THREE.Vector3(135.81060813265404, 67.4096569468774, -140.985662164082),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(32.74498099502648, -0.02942657470703125, -45.872187810959076),
          offsetTarget: 100,
        },
        hideObjects: [],
        target: new THREE.Vector3(37.97134408165245, 62.552245542889125, -22.422636235352055),
        distanceForStopAnimation: 500,
      },
      // Material Movement
      Material_Movement: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Material_Movement.revision,
        position: config.models.vehicles.Material_Movement.position,
        model: Material_Movement,
        name: 'Material_Movement',
        originName: 'Material_Movement',
        path: {
          points: [
            /* { 
              position: new THREE.Vector3(90.55571964531344, 47.14527683816297, 0.100421000478146),
            }, */
            {
              position: new THREE.Vector3(
                110.55571964531344,
                57.14527683816297,
                15.100421000478146,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(288.21556698436234, 0.086395263671875, -22.48868143047639),
          offsetTarget: 100,
        },
        hideObjects: ['Arrows_R', 'Arrows_F', 'DumpTruck', 'Stones'],
        target: config.models.vehicles.Material_Movement.position,
        distanceForStopAnimation: 600,
      },
      // UNDERGROUND
      Tunnel: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Tunnel.revision,
        position: config.models.vehicles.Tunnel.position,
        model: null,
        name: 'Tunnel',
        originName: 'Tunnel',
        path: {
          points: [
            /* { 
              position: new THREE.Vector3(950.1122370922244, -50.406725269528, 70.7145848596653),
            }, */
            {
              position: new THREE.Vector3(957.7868356560259, -144.96054516738158, 192.387724117108),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(192.75771347915577, -90.35798336740116, -154.08435899017698),
          forceChangeTarget: true,
          offsetTarget: 0,
        },
        hideObjects: [],
      },
      Terrain_Explosion: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Terrain_Explosion.revision,
        position: config.models.vehicles.Terrain_Explosion.position,
        model: Terrain_Explosion,
        name: 'Terrain_Explosion',
        originName: 'Terrain_Explosion',
        path: {
          points: [
            {
              position: new THREE.Vector3(
                110.55571964531344,
                57.14527683816297,
                15.100421000478146,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(288.21556698436234, 0.086395263671875, -22.48868143047639),
          offsetTarget: 100,
        },
        hideObjects: [],
        target: config.models.vehicles.Terrain_Explosion.position,
        distanceForStopAnimation: 600,
      },
      // Safety
      Safety: {
        lod: new THREE.LOD(),
        revision: config.models.vehicles.Safety.revision,
        position: config.models.vehicles.Safety.position,
        model: Safety,
        name: 'Safety',
        originName: 'Personal_Safety_Animation',
        path: {
          points: [
            /* { 
              position: new THREE.Vector3(90.55571964531344, 47.14527683816297, 0.100421000478146),
            }, */
            {
              position: new THREE.Vector3(
                207.55571964531344,
                37.14527683816297,
                112.100421000478146,
              ),
            },
          ],
          currentIndex: -1,
          target: new THREE.Vector3(180.18865966796875, 10.88319206237793, 149.9568634033203),
          offsetTarget: 100,
        },
        hideObjects: [],
        target: config.models.vehicles.Safety.position,
        distanceForStopAnimation: 600,
      },
    };

    this.addLODIntoScene(
      this.lods.Planning_Combined.lod,
      this.lods.Planning_Combined.position,
      'Planning_Combined',
    );
    this.addLODIntoScene(
      this.lods.Monitoring_Blocks.lod,
      this.lods.Monitoring_Blocks.position,
      'Monitoring_Blocks',
    );
    this.addLODIntoScene(this.lods.Drill_Blast.lod, this.lods.Drill_Blast.position, 'Drill_Blast');
    this.addLODIntoScene(
      this.lods.Material_Movement.lod,
      this.lods.Material_Movement.position,
      'Material_Movement',
    );
    this.addLODIntoScene(this.lods.Buildings.lod, this.lods.Buildings.position, 'Buildings');
    this.addLODIntoScene(this.lods.Tunnel.lod, this.lods.Tunnel.position, 'Tunnel');
    this.addLODIntoScene(
      this.lods.Terrain_Explosion.lod,
      this.lods.Terrain_Explosion.position,
      'Terrain_Explosion',
    );

    this.addLODIntoScene(
      this.lods.Safety.lod,
      this.lods.Safety.position,
      'Safety',
    );

    this.splashes = {
      defaultLineColor: 0xffffff,
      currentIndex: -1,
      repeat: true,
      chains: [
        {
          connectorLineId: 'Planning:Exploration',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Planning:SurveyMonitoring',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Planning:DrillBlast',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Planning:Material_Movement',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Planning:Buildings',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Planning:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Planning:Safety',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Exploration:SurveyMonitoring',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Exploration:DrillBlast',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Exploration:Material_Movement',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Exploration:Buildings',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Exploration:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Exploration:Safety',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'SurveyMonitoring:DrillBlast',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'SurveyMonitoring:Material_Movement',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'SurveyMonitoring:Buildings',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'SurveyMonitoring:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'SurveyMonitoring:Safety',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'DrillBlast:Material_Movement',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'DrillBlast:Buildings',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'DrillBlast:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'DrillBlast:Safety',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Material_Movement:Buildings',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Material_Movement:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Material_Movement:Safety',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Buildings:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Safety:Buildings',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
        {
          connectorLineId: 'Safety:Tunnel',
          connectorLineColorAfterStart: 0x00ff00,
          connectorLineColorAfterFinished: 0xffffff,
          startPoint: 'startPoint',
          time: 2000,
          fraction: 0,
        },
      ],
    };

    this.connectorLinesMetaData = {
      target: new THREE.Vector3(100.7198486328125, 0.014208750799298286, -40.46917724609375),
      distanceForHide: 500,
    };

    this.connectorLinesByIds = new Map([
      [
        'Planning:Exploration',
        {
          name: 'Planning:Exploration',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          delPositionY: 100,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Planning:SurveyMonitoring',
        {
          name: 'Planning:SurveyMonitoring',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Planning:DrillBlast',
        {
          name: 'Planning:DrillBlast',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Planning:Material_Movement',
        {
          name: 'Planning:Material_Movement',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Planning:Buildings',
        {
          name: 'Planning:Buildings',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          delPositionY: 120,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Planning:Tunnel',
        {
          name: 'Planning:Tunnel',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 120,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Planning:Safety',
        {
          name: 'Planning:Safety',
          startPoint: {
            name: 'Planning_Combined',
            modelName: 'Planning_Combined',
            position: null,
          },
          endPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Exploration:SurveyMonitoring',
        {
          name: 'Exploration:SurveyMonitoring',
          startPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          endPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          delPositionY: 200,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Exploration:DrillBlast',
        {
          name: 'Exploration:DrillBlast',
          startPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          endPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          delPositionY: 150,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Exploration:Material_Movement',
        {
          name: 'Exploration:Material_Movement',
          startPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          endPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          delPositionY: 250,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Exploration:Buildings',
        {
          name: 'Exploration:Buildings',
          startPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          endPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          delPositionY: 250,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Exploration:Tunnel',
        {
          name: 'Exploration:Tunnel',
          startPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 250,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Exploration:Safety',
        {
          name: 'Exploration:Safety',
          startPoint: {
            name: 'DrillingMachine_Solo',
            modelName: 'DrillingMachine_Solo',
            position: null,
          },
          endPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          delPositionY: 250,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'SurveyMonitoring:DrillBlast',
        {
          name: 'SurveyMonitoring:DrillBlast',
          startPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          endPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'SurveyMonitoring:Material_Movement',
        {
          name: 'SurveyMonitoring:Material_Movement',
          startPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          endPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'SurveyMonitoring:Buildings',
        {
          name: 'SurveyMonitoring:Buildings',
          startPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          endPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          delPositionY: 10,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'SurveyMonitoring:Tunnel',
        {
          name: 'SurveyMonitoring:Buildings',
          startPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 10,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'SurveyMonitoring:Safety',
        {
          name: 'SurveyMonitoring:Safety',
          startPoint: {
            name: 'Monitoring_Blocks',
            modelName: 'Monitoring_Blocks',
            position: null,
          },
          endPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'DrillBlast:Material_Movement',
        {
          name: 'DrillBlast:Material_Movement',
          startPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          endPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'DrillBlast:Buildings',
        {
          name: 'DrillBlast:Buildings',
          startPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          endPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'DrillBlast:Tunnel',
        {
          name: 'DrillBlast:Buildings',
          startPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'DrillBlast:Safety',
        {
          name: 'DrillBlast:Safety',
          startPoint: {
            name: 'Drill_Blast',
            modelName: 'Drill_Blast',
            position: null,
          },
          endPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Material_Movement:Buildings',
        {
          name: 'Material_Movement:Buildings',
          startPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          endPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Material_Movement:Tunnel',
        {
          name: 'Material_Movement:Tunnel',
          startPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Material_Movement:Safety',
        {
          name: 'Material_Movement:Safety',
          startPoint: {
            name: 'Material_Movement',
            modelName: 'Material_Movement',
            position: null,
          },
          endPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Buildings:Tunnel',
        {
          name: 'Buildings:Tunnel',
          startPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 10,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Safety:Buildings',
        {
          name: 'Safety:Buildings',
          startPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          endPoint: {
            name: 'Buildings',
            modelName: 'Buildings',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
      [
        'Safety:Tunnel',
        {
          name: 'Safety:Tunnel',
          startPoint: {
            name: 'Safety',
            modelName: 'Safety',
            position: null,
          },
          endPoint: {
            name: 'Tunnel',
            modelName: 'Tunnel',
            position: null,
          },
          delPositionY: 0,
          model: null,
          path: null,
          splash: {
            direction: 'forward',
            model: null,
            fraction: 0,
            deltaFraction: 0.005, // spead: 1 call = 0.005 unit per 1: // TODO: Add deltaTime and normilize
          },
        },
      ],
    ]);

    this.connectorLinesIds = [
      'Planning:Exploration',
      'Planning:SurveyMonitoring',
      'Planning:DrillBlast',
      'Planning:Material_Movement',
      'Planning:Buildings',
      'Planning:Tunnel',
      'Planning:Safety',
      'Exploration:SurveyMonitoring',
      'Exploration:DrillBlast',
      'Exploration:Material_Movement',
      'Exploration:Buildings',
      'Exploration:Tunnel',
      'Exploration:Safety',
      'SurveyMonitoring:DrillBlast',
      'SurveyMonitoring:Material_Movement',
      'SurveyMonitoring:Buildings',
      'SurveyMonitoring:Tunnel',
      'SurveyMonitoring:Safety',
      'DrillBlast:Material_Movement',
      'DrillBlast:Tunnel',
      'DrillBlast:Buildings',
      'DrillBlast:Safety',
      'Material_Movement:Buildings',
      'Material_Movement:Tunnel',
      'Material_Movement:Safety',
      'Buildings:Tunnel',
      'Safety:Buildings',
      'Safety:Tunnel',
    ];
  }

  reInit() {
    this.state = null;

    this.position = 0;

    this.fraction = 0;

    this.indicator = {
      totalSize: this.getTotalSizeModels(),
      loadedSize: 0,
      percentages: 0,
      models: {},
      count: 0,
    };

    this.load();
  }

  async cachingFiles(path) {
    try {
      const storage = await caches.open('threejs');
      // const response = await storage.match(Terrain);

      // if (!response) {
      // storage.put(Terrain, model);
      // }

      const response = await fetch(path);

      if (response.body) {
        storage.put([path], response.clone());

        /* const data = await response.arrayBuffer();
        const glbBlob = new Blob([data], { type: 'model/gltf-binary' });
        const r = window.URL.createObjectURL(glbBlob); */

        // const data = await response.arrayBuffer();
      }
    } catch {}
  }

  async getCachedFile(path) {
    try {
      const storage = await caches.open('threejs');
      const response = await storage.match(path);

      if (response) {
        const data = await response.arrayBuffer();
        return data;
        // const glbBlob = new Blob([data], { type: 'model/gltf-binary' });
        // const r = window.URL.createObjectURL(glbBlob);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  addLODIntoScene(lod, position, name) {
    lod.position.x = position.x;
    lod.position.y = position.y;
    lod.position.z = position.z;

    lod.updateMatrix();

    lod.name = name;

    lod.visible = false;

    this.scene.add(lod);
  }

  createGround(color = 0x00ff00) {
    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.scale.set(100, 1, 100);
    mesh.position.set(0, -0.5, 0);

    // this.scene.add(mesh);

    return mesh;
  }

  initConnectorLines() {
    let i = -1;
    const n = this.connectorLinesIds.length;

    while (++i < n) {
      const id = this.connectorLinesIds[i];
      const connectorLine = this.connectorLinesByIds.get(id);

      connectorLine.startPoint.position =
        this.lods[connectorLine.startPoint.modelName].lod.position;
      connectorLine.endPoint.position = this.lods[connectorLine.endPoint.modelName].lod.position;

      const { mesh, curve } = this.createConnectorLine(
        connectorLine.startPoint.position,
        connectorLine.endPoint.position,
        connectorLine.delPositionY,
      );

      connectorLine.model = mesh;
      connectorLine.path = curve;

      const arrow = this.getArrow();

      const pointsPath = this.getPointsPath(curve);
      // const path = this.getPath(pointsPath);

      connectorLine.pointsPath = pointsPath;
      connectorLine.splash.model = arrow;

      arrow.visible = false;

      this.scene.add(arrow);
      // this.scene.add(path);
    }
  }

  /**
   *
   * @param {Vector3} startPoint
   * @param {Vector3} endPoint
   */
  createConnectorLine(startPoint, endPoint, delPositionY = 0) {
    const middlePoint = startPoint.clone();
    middlePoint.add(endPoint).divideScalar(2);

    const maxPositionY = endPoint.y > startPoint.y ? endPoint.y : startPoint.y;

    middlePoint.setY(maxPositionY + delPositionY);

    const curve = new THREE.CatmullRomCurve3([startPoint, middlePoint, endPoint]);

    const geometry = new THREE.TubeGeometry(curve, 100, 0.5, 20, false);
    const material = new THREE.MeshBasicMaterial({
      color: 0xffffff, // 0x049ef4,
      opacity: 0,
      transparent: true,
      side: THREE.DoubleSide,
    });

    /* const material = new THREE.ShaderMaterial({
      uniforms: {
        color1: {
          value: new THREE.Color("red")
        },
        color2: {
          value: new THREE.Color("purple")
        }
      },
      vertexShader: `
        varying vec2 vUv;
    
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
        }
      `,
      fragmentShader: `
        uniform vec3 color1;
        uniform vec3 color2;
      
        varying vec2 vUv;
        
        void main() {
          
          gl_FragColor = vec4(mix(color1, color2, vUv.y), 0.5);
        }
      `,
    }); */

    const mesh = new THREE.Mesh(geometry, material);

    this.scene.add(mesh);

    return { mesh, curve };
  }

  initConnectLines() {
    // x: 80, color: 0x000066, z: -25
    let path = new CustomSinCurve(1, [0, 0.5, -10], [80, 0.5, -25]);
    this.connectorLine1 = path;
    let geometry = new THREE.TubeGeometry(path, 100, 0.04, 20, false);
    const material = new THREE.MeshBasicMaterial({
      color: 0x049ef4,
      opacity: 0.5,
      transparent: true,
      side: THREE.DoubleSide,
    });
    this.connectorLine1Mesh = new THREE.Mesh(geometry, material);
    this.scene.add(this.connectorLine1Mesh);

    path = new CustomSinCurve(1, [0, 0.5, -10], [-20, 0.5, -50]); // x: -20, z: -50
    this.connectorLine2 = path;
    geometry = new THREE.TubeGeometry(path, 100, 0.04, 20, false);
    this.connectorLine2Mesh = new THREE.Mesh(geometry, material.clone());
    this.scene.add(this.connectorLine2Mesh);
  }

  createInfoPanel(text = 'Hello, world!') {
    const canvas1 = document.createElement('canvas');
    const context1 = canvas1.getContext('2d');
    context1.font = 'Bold 0.625rem Hexagon';
    context1.fillStyle = 'rgba(255,0,0,1)';
    context1.fillText(text, 0, 60);

    // canvas contents will be used for a texture
    const texture1 = new THREE.Texture(canvas1);
    texture1.needsUpdate = true;

    const material1 = new THREE.MeshBasicMaterial({ map: texture1, side: THREE.DoubleSide });
    material1.transparent = true;

    const mesh1 = new THREE.Mesh(new THREE.PlaneGeometry(50, 10), material1);
    mesh1.position.set(0, 5, 0);
    // mesh1.rotation.x = -0.9;
    // shape.add(mesh1);
    // Note that mesh1 gets added to the shape and not to the scene

    this.scene.add(mesh1);

    const { sprite, texture } = this.makeTextSprite(' Hello \n New', {
      fontsize: 16,
      textColor: { r: 255, g: 255, b: 255, a: 1.0 },
    });
    sprite.position.set(1, 1, 1);
    this.scene.add(sprite);

    const { texture: texture2 } = this.makeTextSprite(
      'Work with the latest blasthole data. Streamline audits and rapidly execute grade control workflows. ',
      { fontsize: 16, textColor: { r: 255, g: 255, b: 255, a: 1.0 } },
    );
    const text1 = this.createText2D(texture2);
    text1.position.set(0, 2, 1);
    this.scene.add(text1);
  }

  makeTextSprite(message, parameters) {
    if (parameters === undefined) {
      parameters = {};
    }

    const fontface = parameters.hasOwnProperty('fontface') ? parameters.fontface : 'Courier New';
    const fontsize = parameters.hasOwnProperty('fontsize') ? parameters.fontsize : 18;
    const fontsizeInRem = getPxAsRem(fontsize);
    const borderThickness = parameters.hasOwnProperty('borderThickness')
      ? parameters.borderThickness
      : 4;
    const borderColor = parameters.hasOwnProperty('borderColor')
      ? parameters.borderColor
      : { r: 0, g: 0, b: 0, a: 1.0 };
    const backgroundColor = parameters.hasOwnProperty('backgroundColor')
      ? parameters.backgroundColor
      : { r: 0, g: 0, b: 255, a: 1.0 };
    const textColor = parameters.hasOwnProperty('textColor')
      ? parameters.textColor
      : { r: 0, g: 0, b: 0, a: 1.0 };

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `Bold ${fontsizeInRem}rem ${fontface}`;
    const metrics = context.measureText(message);
    const textWidth = metrics.width;

    context.fillStyle = `rgba(${backgroundColor.r},${backgroundColor.g},${backgroundColor.b},${backgroundColor.a})`;
    context.strokeStyle = `rgba(${borderColor.r},${borderColor.g},${borderColor.b},${borderColor.a})`;
    context.fillStyle = `rgba(${textColor.r}, ${textColor.g}, ${textColor.b}, 1.0)`;
    context.fillText(message, borderThickness, fontsize + borderThickness);

    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;

    const spriteMaterial = new THREE.SpriteMaterial({ map: texture, useScreenCoordinates: false });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(0.5 * fontsize, 0.25 * fontsize, 0.75 * fontsize);
    return { sprite, texture };
  }

  createText2D(tex, width = 2, height = 2, segW = 1, segH = 1) {
    const plane = new THREE.PlaneGeometry(width, height, segW, segH);
    const planeMat = new THREE.MeshBasicMaterial({
      map: tex,
      overdraw: true,
      color: new THREE.Color(0x00ff00), // color: 0xffffff, transparent: true
    });
    const mesh = new THREE.Mesh(plane, planeMat);
    mesh.doubleSided = true;
    return mesh;
  }

  initCubeMotion() {
    // the path
    this.path = new THREE.Path([new THREE.Vector2(0, 0), new THREE.Vector2(10, 10)]);
    const arcRadius = 5;
    this.path.moveTo(0, 0 + arcRadius);
    this.path.absarc(0, 0, arcRadius, Math.PI / 2, 0, false);
    this.path.lineTo(20, 20);

    this.scene.add(this.path);

    this.previousAngle = this.getAngle(this.position);
    this.previousPoint = this.path.getPointAt(this.position);
  }

  initPaths() {
    this.arrow1 = this.getArrow();
    this.pointsPath1 = this.getPointsPath(this.connectorLine1);
    let path = this.getPath(this.pointsPath1);

    this.scene.add(this.arrow1);
    this.scene.add(path);

    this.arrow2 = this.getArrow();
    this.pointsPath2 = this.getPointsPath(this.connectorLine2);
    path = this.getPath(this.pointsPath2);

    this.scene.add(this.arrow2);
    this.scene.add(path);
  }

  getArrow() {
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
    });
    const coneGeom = new THREE.ConeGeometry(1, 2, 10);
    coneGeom.translate(0, 2.5, 0);

    const cone = new THREE.Mesh(coneGeom, material);
    const cylinder = new THREE.CylinderGeometry(0.5, 0.5, 5, 10);

    cylinder.merge(cone.geometry, cone.matrix);
    cylinder.scale(1, 1, 1);

    const arrow = new THREE.Mesh(cylinder, material);
    arrow.frustumCulled = false;

    return arrow;
  }

  getPointsPath(curve) {
    const pointsPath = new THREE.CurvePath();
    pointsPath.add(curve);
    /* const firstLine = new THREE.LineCurve3(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(10, 10, 10)
    );

    const firstLine2 = new THREE.LineCurve3(
      new THREE.Vector3(10, 10, 10),
      new THREE.Vector3(10, 0, 10)
    );

    const firstLine3 = new THREE.LineCurve3(
      new THREE.Vector3(10, 0, 10),
      new THREE.Vector3(0, 0, 0)
    ); */

    // pointsPath.add(firstLine2);
    // pointsPath.add(firstLine3);

    /* const firstLine = new THREE.LineCurve3(
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(-1, 0, 0)
    );
    const secondLine = new THREE.LineCurve3(
      new THREE.Vector3(-1, 0, 0),
      new THREE.Vector3(-1, 1, 0),
    );
  
    const thirdLine = new THREE.LineCurve3(
      new THREE.Vector3(-1, 1, 0),
      new THREE.Vector3(-1, 1, 1)
    );
  
    const bezierLine = new THREE.CubicBezierCurve3(
      new THREE.Vector3(-1, 1, 1),
      new THREE.Vector3(-0.5, 1.5, 0),
      new THREE.Vector3(2.0, 1.5, 0),
      new THREE.Vector3(-1, 0, 1)
    );
    pointsPath.add(firstLine);
    pointsPath.add(secondLine);
    pointsPath.add(thirdLine);
    pointsPath.add(bezierLine); */
    return pointsPath;
  }

  getPath(pointsPath) {
    const material = new THREE.LineBasicMaterial({
      color: 0x9132a8,
    });

    const points = pointsPath.curves.reduce((p, d) => [...p, ...d.getPoints(20)], []);

    const geometry = new THREE.BufferGeometry().setFromPoints(points);

    return new THREE.Line(geometry, material);
  }

  initHotspots() {
    const geometry = new THREE.RingGeometry(1, 2, 32, 1);
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00, side: THREE.DoubleSide });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0.5, -40);
    mesh.rotateX((90 * Math.PI) / 180);
    // mesh.scale.set(0.05, 0.05, 0.05);
    this.scene.add(mesh);
  }

  initLights() {
    const light = new THREE.DirectionalLight(0xffffff, 1.3);
    light.position.set(100000, 100000, 100000);

    light.castShadow = true;
    this.scene.add(light);

    const light1 = new THREE.DirectionalLight(0xffffff, 1.3);
    light1.position.set(-100000, 100000, -100000);

    light1.castShadow = true;
    this.scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff, 1.3);
    light2.position.set(0, -100000, 0);

    light2.castShadow = true;
    // this.scene.add(light2);
    /* light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 512;

    light.shadow.camera.near = 100;
    light.shadow.camera.far = 1200;

    light.shadow.camera.left = - 1000;
    light.shadow.camera.right = 1000;
    light.shadow.camera.top = 350;
    light.shadow.camera.bottom = - 350; */

    const ambientLight = new THREE.AmbientLight(0x222222);
    this.scene.add(ambientLight);
  }

  initHelpers() {
    const axesHelper = new THREE.AxesHelper(10);
    this.scene.add(axesHelper);
  }

  load() {
    this.onUpdate({ source: 'scene', action: 'pending' });

    this.loadModelGLTF(
      this.lods.DrillingMachine_Solo.model,
      null,
      'DrillingMachine_Solo',
      this.lods.DrillingMachine_Solo.revision,
    );
    this.loadModelGLTF(
      this.lods.DrillSpots_Solo.model,
      null,
      'DrillSpots_Solo',
      this.lods.DrillSpots_Solo.revision,
    );

    this.loadModelGLTF(
      this.lods.Planning_Combined.model,
      null,
      'Planning_Combined',
      this.lods.Planning_Combined.revision,
    );

    this.loadModelGLTF(
      this.lods.Monitoring_Blocks.model,
      null,
      'Monitoring_Blocks',
      this.lods.Monitoring_Blocks.revision,
    );

    this.loadModelGLTF(
      this.lods.Drill_Blast.model,
      null,
      'Drill_Blast',
      this.lods.Drill_Blast.revision,
    );
    this.loadModelGLTF(
      this.lods.Material_Movement.model,
      null,
      'Material_Movement',
      this.lods.Material_Movement.revision,
    );

    this.loadModelGLTF(
      this.lods.Safety.model,
      null,
      'Safety',
      this.lods.Safety.revision,
    );

    this.loadModelGLTF(this.lods.Buildings.model, null, 'Buildings', this.lods.Buildings.revision);
    this.loadModelGLTF(
      this.lods.Terrain_Tunnel_Entrance.model,
      null,
      'Terrain_Tunnel_Entrance',
      this.lods.Terrain_Tunnel_Entrance.revision,
    );

    this.loadModelGLTF(
      this.lods.Terrain_Explosion.model,
      null,
      'Terrain_Explosion',
      this.lods.Terrain_Explosion.revision,
    );

    this.loadTerrainGLTF(this.lods.terrain.revision);
    // this.loadTerrainFBX();
  }

  getActiveScene() {
    return this.scene;
  }

  onUpdate(options) {
    if (typeof this.listener?.onUpdate === 'function') {
      this.listener.onUpdate(options);
    }
  }

  isEmpty() {
    return this.state === null;
  }

  isPending() {
    return this.state === PENDING;
  }

  isReady() {
    return this.state === READY;
  }
  /*
  async loadTerrain() {
    const loadedTextures = await this.getTextures();

    const fbxLoader = new FBXLoader();

    fbxLoader.load(
      Terrain,
      (object) => {
        this.terrain = true;

        object.scale.set(0.01, 0.01, 0.01);

        object.children.forEach((c) => {
          c.position.set(0, 0, 0);

          if (c.isMesh && c.name === 'Terrain') {
            // c.material.map = loadedTextures.map.val;
            // c.material.bumpMap = loadedTextures.bumpMap.val;
            // c.material.aoMap = loadedTextures.aoMap.val;
            // c.material.normalMap = loadedTextures.normalMap.val;
            // c.material.envMap = loadedTextures.normalMap.envMap;
          }
        });

        this.scene.add(object);

        // this.state = READY;

        // this.showAllObjects();

        // this.onUpdate({ source: 'scene', action: 'ready' });
        // this.initScene();

        // this.scene.add(object);
      },
    );
  } */

  async loadModelGLTF(path, type, name, revision = config.defaultRevision) {
    const gltfLoader = new GLTFLoader();

    const cachedModel = await get(name); // await this.getCachedFile(Terrain);

    if (cachedModel && cachedModel.revision === revision) {
      // const test = await get('name');

      // const loader = new THREE.JSONLoader();
      gltfLoader.parse(cachedModel.data, THREE.LoaderUtils.extractUrlBase(path), (model) => {
        this.loadedModel(model, type, name);
      });
    } else {
      gltfLoader.load(
        path,
        async (model, data) => {
          await set(name, { id: name, name, revision, data });

          this.loadedModel(model, type, name);
        },
        (xhr) => {
          // this.sendProgress(xhr.loaded, name);
        },
        (xhr) => {
          reject(
            new Error(`gltfLoader:${xhr}An error occurred loading while loading: ${entry.url}`),
          );
        },
      );
    }
  }

  async loadedModel(model, type, name) {
    // model.scene.scale.set(1, 1, 1);
    const object = model.scene;

    let distance = 100;

    if (type === 'low') {
      distance = 120;

      object.children.forEach((c) => {
        if (c.type === 'Mesh') {
          c.material.needsUpdate = true;
          c.material.color.setHex(0xffffff);
        }
      });
    }

    if (name) {
      /* if (name === 'vehicle_4') {
          this.lods[name].lod.scale.set(2.5, 2.5, 2.5);
        } */

      const names = [
        'DrillingMachine_Solo',
        'DrillSpots_Solo',
        'Monitoring_Blocks',
        'Drill_Blast',
        'Material_Movement',
        'Planning_Combined',
        'Buildings',
        'Terrain_Tunnel_Entrance',
        'Terrain_Explosion',
        'Safety'
      ];

      if (names.includes(name)) {
        object.visible = false;

        this.scene.add(object);

        this.lods[name].loadedModel = model;
        this.lods[name].originModel = model.scene.getObjectByName(this.lods[name].originName);

        this.lods[name].lod.name = name;

        if (name === 'Monitoring_Blocks') {
          const obj = model.scene.getObjectByName('ArcSAR_MOVER');

          if (obj) {
            const { x, y, z } = obj.position;
            this.lods[name].lod.position.set(x, y, z);
          }
        } else if (name === 'DrillingMachine_Solo') {
          this.lods[name].lod.position.setX(520);
          this.lods[name].lod.position.setY(100);
          this.lods[name].lod.position.setZ(-680);
        }

        if (Array.isArray(this.lods[name].hideObjects)) {
          let i = -1;
          const n = this.lods[name].hideObjects.length;

          while (++i < n) {
            const m = model.scene.getObjectByName(this.lods[name].hideObjects[i]);

            if (m) {
              m.visible = false;
            }
          }
        }

        /* const c = model.scene.getObjectByName('Drilling_Machine');

          c.position.x = this.lods[name].position.x;
          c.position.y = this.lods[name].position.y;
          c.position.z = this.lods[name].position.z;

          c.updateMatrix(); */
      } else {
        this.lods[name].lod.addLevel(object, distance);
      }

      this.sendProgress(config.models.vehicles[name].size.total, name);
      this.indicator.count += 1;
    } else {
      object.scale.set(1.57, 1.57, 1.57);
      this.carLOD.addLevel(object, distance);
      this.sendProgress(
        config.models.cars.cybertruck.lod.hight.size.total,
        type === 'low' ? 'cybertruck_low' : 'cybertruck',
      );
      this.indicator.count += 1;
    }

    this.onUpdate({ source: 'scene', action: 'loadedModel', data: { name } });

    this.checkReady();
  }
  /*
  async loadTerrainFBX() {
    const loadedTextures = await this.getTextures();

    // const total = 3635877; // bytes

    const fbxLoader = new FBXLoader();

    fbxLoader.load(Terrain, 
      (model) => {
        // loadedTextures.map.val.flipY = false;

        const object = model.getObjectByName('Terrain_KuchenModel_AR_VR_PBR');

        // object.material.map = loadedTextures.map.val;
        // object.material.side = THREE.DoubleSide;
        // model.scale.set(0.01, 0.01, 0.01);

        // const mixer = new THREE.AnimationMixer( model.scene );
        // const clips = model.scene.animations;

        // object.material.map = loadedTextures.map.val;
        // object.material.map.flipY = false;
        // object.material.needsUpdate = true;

        // object.material.aoMap = loadedTextures.aoMap.val;

        // If texture is used for color information, set colorspace.
        // object.material.map.encoding = THREE.sRGBEncoding;

        // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
        // object1.materialmap.flipY = false;

        // object.scene.position.set(0, 0, 0);
        // model.scene.scale.set(0.1, 0.1, 0.1);
        this.lods.terrain.loadedModel = model;

        // model.scene.visible = false;
        model.visible = false;

        this.scene.add(model);

        this.sendProgress(config.models.terrain.size.total, 'terrain');
        this.indicator.count += 1;
        // this.model = model;
        // this.terrain = true;

        this.checkReady();
      },
      (xhr) => {
        this.sendProgress(xhr.loaded, 'terrain');
      },
    );
  } */

  async loadTerrainGLTF(revision = config.defaultRevision) {
    // const loadedTextures = await this.getTextures();

    const gltfLoader = new GLTFLoader();

    const cachedTerrain = await get('terrain'); // await this.getCachedFile(Terrain);

    if (cachedTerrain && cachedTerrain.revision === revision) {
      // const loader = new THREE.JSONLoader();
      gltfLoader.parse(cachedTerrain.data, THREE.LoaderUtils.extractUrlBase(Terrain), (model) => {
        this.loadedTerrain(model);
      });
    } else {
      gltfLoader.load(
        Terrain,
        async (model, data) => {
          await set('terrain', { id: 'terrain', name: 'terrain', revision, data });
          this.loadedTerrain(model);
        },
        (xhr) => {
          this.sendProgress(xhr.loaded - 1, 'terrain');
        },
        (xhr) => {
          reject(
            new Error(`gltfLoader:${xhr}An error occurred loading while loading: ${entry.url}`),
          );
        },
      );
    }
  }

  async loadedTerrain(model /* , loadedTextures */) {
    // model.scene.name = 'terrain';

    // const JSONParser = new THREE.ObjectLoader();
    // const myObject3D = JSONParser.parse(test);
    // loadedTextures.map.val.flipY = false;
    // this.cachingFiles(model);
    // const object = model.scene.getObjectByName('Terrain_KuchenModel_ARVR_PBR');

    // const mixer = new THREE.AnimationMixer( model.scene );
    // const clips = model.scene.animations;
    // object.material.map = loadedTextures.map.val;
    // object.material.map.flipY = false;
    // object.material.needsUpdate = true;
    // object.material.aoMap = loadedTextures.aoMap.val;

    // If texture is used for color information, set colorspace.
    // object.material.map.encoding = THREE.sRGBEncoding;

    // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
    // object1.materialmap.flipY = false;

    // object.scene.position.set(0, 0, 0);
    // model.scene.scale.set(0.1, 0.1, 0.1);
    this.lods.terrain.loadedModel = model;

    model.scene.visible = false;

    this.scene.add(model.scene);

    this.sendProgress(config.models.terrain.size.total, 'terrain');
    this.indicator.count += 1;
    // this.model = model;
    // this.terrain = true;

    this.checkReady();
  }
  /*
  async loadTerrainFBX1() {
    const loadedTextures = await this.getTextures();

    // const total = 3635877; // bytes

    const gltfLoader = new FBXLoader();

    gltfLoader.load(TerrainFBX, (model) => {
      this.initLights();
      this.initHelpers();
  
      loadedTextures.map.val.flipY = false;

      // const object = model.scene.getObjectByName('Drilling_Machine');

      this.model = model;

      // object.material.map = loadedTextures.map.val;
      // object.material.aoMap = loadedTextures.aoMap.val;

      // If texture is used for color information, set colorspace.
      // object.material.map.encoding = THREE.sRGBEncoding;

      // UVs use the convention that (0, 0) corresponds to the upper left corner of a texture.
      // object1.materialmap.flipY = false;

      // object.scene.position.set(0, 0, 0);
      // model.scene.scale.set(0.1, 0.1, 0.1);

      this.scene.add(model);

      this.checkReady();
      // this.terrain = true;
    }, (xhr) => {
      this.sendProgress(xhr.loaded, 'terrain');
    },
    (xhr) => {
      reject(new Error(`gltfLoader:${xhr}An error occurred loading while loading: ${entry.url}`));
    },);
  } */

  getTextures() {
    return new Promise((resolve, reject) => {
      const loader = new THREE.TextureLoader();

      const textures = {
        map: {
          // url: TerrainSurface_Color,
          val: null,
        },
        aoMap: {
          // url: TerrainAmbient_Occlusion,
          val: null,
        },
        /* bumpMap: {
          url: GroundDirtRough002_BUMP_4K,
          val: null,
        },
        normalMap: {
          url: GroundDirtRough002_NRM_4K,
          val: null,
        },
        envMap: {
          url: GroundDirtRough002_REFL_4K,
          val: null,
        }, */
      };

      const texturePromises = [];

      for (const key in textures) {
        texturePromises.push(
          new Promise((resolve, reject) => {
            const entry = textures[key];
            const { url } = entry;

            loader.load(
              url,
              (texture) => {
                entry.val = texture;

                this.sendProgress(config.models.terrain[key].size.total, key);
                this.indicator.count += 1;

                if (entry.val instanceof THREE.Texture) resolve(entry);
              },
              (xhr) => {
                this.sendProgress(xhr.loaded, key);
              },
              (xhr) => {
                reject(new Error(`${xhr}An error occurred loading while loading: ${entry.url}`));
              },
            );
          }),
        );
      }

      Promise.all(texturePromises).then((loadedTextures) => {
        resolve(textures);
      });
    });
  }

  sendProgress(loadedSize, from = null) {
    if (typeof loadedSize !== 'undefined' && from) {
      this.indicator.models[from] = {
        loadedSize,
      };

      this.updateIndicatorLoadedSize();

      this.indicator.percentages = (this.indicator.loadedSize / this.indicator.totalSize) * 100;
    }

    this.onUpdate({
      source: 'scene',
      action: 'progress',
      data: { percentages: this.indicator.percentages },
    });
  }

  updateIndicatorLoadedSize() {
    this.indicator.loadedSize = 0;

    Object.keys(this.indicator.models).forEach((k) => {
      this.indicator.loadedSize += this.indicator.models[k].loadedSize;
    });
  }

  checkReady = () => {
    if (this.indicator.percentages >= 100 /* && this.indicator.count === 13 */) {
      this.state = READY;

      this.initLights();
      // this.initHelpers();

      this.showAllObjects();

      this.onUpdate({ source: 'scene', action: 'ready' });
    }
  };

  movingObjects(deltatTime) {
    this.movingCube();
    this.movingArrows(deltatTime);
  }

  movingCube() {
    this.position += 0.001;

    const point = this.path.getPointAt(this.position);

    if (point) {
      this.cubeForMotion.position.setX(point.x);
      this.cubeForMotion.position.setZ(point.y);

      const axis = new THREE.Vector3(0, 1, 0);
      const angle = this.getAngle(this.position);

      this.cubeForMotion.quaternion.setFromAxisAngle(axis, angle);

      this.previousPoint = point;
      this.previousAngle = angle;
    }
  }

  movingArrows = (deltatTime = 0) => {
    let i = -1;
    const n = this.connectorLinesIds.length;

    while (++i < n) {
      const connector = this.connectorLinesByIds.get(this.connectorLinesIds[i]);

      // const speed = 2;

      const up = new THREE.Vector3(0, 1, 0);

      const newPosition = connector.pointsPath.getPoint(connector.splash.fraction);

      if (newPosition) {
        this.movingArrow(
          connector.splash.model,
          connector.pointsPath,
          newPosition,
          up,
          connector.splash.fraction,
        );
      }

      connector.splash.fraction += connector.splash.deltaFraction; // TODO: deltatTime * speed;

      if (connector.splash.fraction > 1) {
        connector.splash.fraction = 0;
      }
    }
  };

  movingArrowInConnectorLine = (connectorLineId, fraction) => {
    const connector = this.connectorLinesByIds.get(connectorLineId);

    const up = new THREE.Vector3(0, 1, 0);

    const newPosition = connector.pointsPath.getPoint(fraction);

    if (newPosition) {
      this.movingArrow(connector.splash.model, connector.pointsPath, newPosition, up, fraction);
    }
  };

  getAngle(position) {
    const tangent = this.path.getTangent(position).normalize();

    const angle = -Math.atan(tangent.x / tangent.y);

    return angle;
  }

  movingArrow(arrow, pointsPath, newPosition, up, fraction) {
    const axis = new THREE.Vector3();

    const tangent = pointsPath.getTangent(fraction);
    arrow.position.copy(newPosition);
    axis.crossVectors(up, tangent).normalize();

    const radians = Math.acos(up.dot(tangent));

    arrow.quaternion.setFromAxisAngle(axis, radians);
  }

  showAllObjects() {
    if (this.lods?.terrain?.loadedModel) {
      this.lods.terrain.loadedModel.visible = true;
    }

    for (const [key, value] of Object.entries(this.lods)) {
      value.lod.visible = true;

      if (value.loadedModel?.scene) {
        value.loadedModel.scene.visible = true;
      } else if (value.loadedModel) {
        value.loadedModel.visible = true;
      }
    }
  }

  getTotalSizeModels() {
    const totalSize =
      config.models.terrain.size.total +
      // config.models.terrain.map.size.total +
      // config.models.terrain.aoMap.size.total +
      config.models.vehicles.Buildings.size.total +
      config.models.vehicles.Terrain_Tunnel_Entrance.size.total +
      // config.models.vehicles.vehicle_1.size.total +
      // config.models.vehicles.vehicle_2.size.total +
      /// config.models.vehicles.vehicle_3.size.total +
      // config.models.vehicles.vehicle_4.size.total +
      config.models.vehicles.DrillingMachine_Solo.size.total +
      config.models.vehicles.DrillSpots_Solo.size.total +
      config.models.vehicles.Planning_Combined.size.total +
      // config.models.vehicles.Planning_Drone_Scan_Solo.size.total +
      // config.models.vehicles.Planning_GridMotion_Solo.size.total +
      // config.models.vehicles.Planning_ScanDevice_Waves_Solo.size.total +
      config.models.vehicles.Monitoring_Blocks.size.total +
      config.models.vehicles.Drill_Blast.size.total +
      config.models.vehicles.Material_Movement.size.total +
      config.models.vehicles.Terrain_Explosion.size.total + 
      config.models.vehicles.Safety.size.total;
    // config.models.cars.cybertruck.lod.hight.size.total +
    // config.models.cars.cybertruck.lod.hight.size.total;

    return totalSize;
  }

  clear() {
    this.scene.traverse((object) => {
      this.clearMesh(object);
    });

    for (const [, value] of Object.entries(this.lods)) {
      if (value.loadedModel?.scene) {
        this.scene.remove(value.loadedModel.scene);
        value.loadedModel = null;
      } else if (value.loadedModel) {
        this.scene.remove(value.loadedModel);
        value.loadedModel = null;
      }

      this.scene.remove(value.lod);
      value.lod = null;
    }

    for (let i = this.scene.children.length - 1; i >= 0; i--) {
      const obj = this.scene.children[i];
      this.scene.remove(obj);
    }

    this.scene = null;

    this.state = null;

    this.position = null;

    this.fraction = null;

    this.listener = null;

    this.indicator = null;

    this.lods = null;

    this.splashes = null;

    this.connectorLinesMetaData = null;
    this.connectorLinesByIds = null;

    this.connectorLinesIds = null;
  }

  cleanMaterial(material) {
    /* if (material.map) {
      material.map.dispose();
    }
    if (material.lightMap) {
        material.lightMap.dispose();
    }
    if (material.bumpMap) {
        material.bumpMap.dispose();
    }
    if (material.normalMap) {
        material.normalMap.dispose();
    }
    if (material.specularMap) {
        material.specularMap.dispose();
    }
    if (material.envMap) {
        material.envMap.dispose();
    } */

    // dispose textures
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }

    material.dispose();
  }

  listChildren(children) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      if (child.children.length > 0) {
        this.listChildren(child.children);
      } else {
        this.clearMesh(child);
      }
    }
  }

  clearMesh(object) {
    if (object.children.length > 0) {
      this.listChildren(object.children);
    } else {
      if (!object.isMesh) return;

      object.geometry.dispose();

      if (object.material.isMaterial) {
        this.cleanMaterial(object.material);
      } else {
        // an array of materials
        for (const material of object.material) this.cleanMaterial(material);
      }
    }
  }
}
