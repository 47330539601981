const description = {
  id: '5 of 5',
  currentNumber: 5,
  totalNumber: 5,
  code: 5,
  title: 'material-movement.Material Movement Dynamic Data.title',
  slug: 'dynamic-data',
  pdfPath: '05MaterialMovementDynamicData',
  apiSlug: 'material-movement-dynamic-data',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-interval-control',
  description: 'material-movement.Material Movement Dynamic Data.description',
  video: {
    name: 'path 4_location_8.mp4',
    stop: 32,
    backgroundImage: 'Path_4_location_8_frame.jpg',
  },
  drawPoints: {
    hotspots: 32,
    dancingAnts: 32.1,
    secondaries: 32.2,
  },
};

export default description;
