/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-self-compare */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-properties */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* eslint-disable no-empty */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import actions from 'app/actions';

import * as selectors from 'app/selectors';

import withNavigate from 'app/routers/withNavigate';

import './assets/scss/index.scss';

import * as THREE from 'three';
import Cookies from 'js-cookie';

import LinearProgressWittTips from 'modules/workflows-overview/components/LinearProgressWithTips';

import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

import RendererService from 'modules/workflows-overview/services/RendererService';
import CameraService from 'modules/workflows-overview/services/CameraService';
import SceneService from 'modules/workflows-overview/services/SceneService';
import ControlsService from 'modules/workflows-overview/services/ControlsService';

import Sidebar from 'modules/workflows-overview/components/Sidebar';

import eventBus from 'EventBus';

import { saveToLS } from 'core/services/localStorage';

import { isNumeric } from 'core/utils';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';

// import { loginMsal } from 'modules/auth/authMsalService';
import * as auth from 'modules/auth';

import PointerContainer from 'modules/workflows-overview/components/Pointer/PointerContainer';
import { ALLOW_EVENTS_CLASS } from 'app/constants/app';
import PowerOfOneCard from 'modules/mine-map/components/PowerOfOneDesc/PowerOfOneCard';
import GestureHint from '../GestureHint/index1';

class MineMap extends PureComponent {
  constructor(props) {
    super(props);

    this.backgrounds = React.createRef();
    this.backgroundImage = React.createRef();

    this.materialMovementBadge = React.createRef();
    this.planningBadge = React.createRef();
    this.explorationBadge = React.createRef();
    this.surveyMonitoringBadge = React.createRef();
    this.drillBlastBadge = React.createRef();
    this.undergroundBadge = React.createRef();
    this.safetyBadge = React.createRef();

    this.routeName = '';

    this.state = {
      state: null,
      progress: 0,
      showSidebar: false,
      currentLocation: null,
      activeSidebar: false,
      pointerSize: 0,
      showGestureHint: false,
      showSignUpForm: false,
      showPowerCard: true,
    };

    this.gTime = 0;

    this.selected = null;

    this.timeForRotation = { t: 0 };

    this.position = 0;

    this.fraction = 0;

    this.initedScene = false;

    this.animations = {};
    this.activeAnimationModelNames = new Map();
    this.runnedAnimations = false;

    this.gTime = 0;

    this.badgesHasOcclusions = false;
    this.previousShowedBadges = null;
    this.showedBadges = false;

    this.badges = {
      planningPosition: [Infinity, Infinity],
      drillblastPosition: [Infinity, Infinity],
      drillingMachinePosition: [Infinity, Infinity],
      surveyMonitoringPosition: [Infinity, Infinity],
      materialMovementPosition: [Infinity, Infinity],
      undergroundPosition: [Infinity, Infinity],
      safetyPosition: [Infinity, Infinity],
      showBadgeMaterialMovement: false,
      showBadgePlanning: false,
      showBadgeExploration: false,
      showBadgeSurveyMonitoring: false,
      showBadgeDrillBlast: false,
      showBadgeUnderground: false,
      showBadgeSafety: false,
      opacity: 0,
    };

    this.connectorLines = {
      opacity: 0,
      showed: false,
      movingArrows: false,
      scheduledProcess: null,
      startedProcess: null,
    };

    this.maxDistanceForScaleBadges = null;

    this.animationID = null;
  }

  componentDidMount() {
    // window.addEventListener('resize', this.updateConfig, false);
    this.visit();

    eventBus.on('resetView', this.eventBusResetView);

    if (Cookies.get('mine_map') !== 'visited') {
      this.setState({ showGestureHint: true });
      Cookies.set('mine_map', 'visited', { expires: 2 * 365 });
    }

    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { lomixConfig, isUserLoggedIn, onEvent } = this.props;
    const { showSidebar, currentLocation } = this.state;

    if (prevProps.isUserLoggedIn !== isUserLoggedIn && isUserLoggedIn) {

      if (showSidebar && currentLocation) {
        const journey = lomixConfig.find((j) => j.slug === currentLocation) || lomixConfig[0];

        const eventText = `deeg_deeper_into_${journey.title}`;
        sendGTEvent({ event: eventText });
        this.clickOnCloseSidebar();

        onEvent({
          action: 'journey:change',
          data: {
            id: currentLocation,
          },
        });

        this.setState({
          currentLocation: null,
        });
      }
    }
    
    if (prevState.currentLocation !== currentLocation && currentLocation) {
      this.setState({ showPowerCard: false });
    }

    if (prevState.currentLocation !== currentLocation && currentLocation === null) {
      this.setState({ showPowerCard: true });
    }
  }

  componentWillUnmount() {
    this.clear();
  }

  visit() {
    this.props.onEvent({ action: 'mine-map:visited' });
  }

  isFirstVisit() {
    return this.props.visited <= 1;
  }

  eventBusResetView = () => {
    return this.resetView();
  };

  onResize = () => {
    // TODO
  };

  goToPath = (path) => {
    const { navigate } = this.props;
    navigate(path);
  };

  handleEvent = () => {};

  init = () => {
    const canvas = document.getElementById('container');

    if (!canvas) return;

    this.clock = new THREE.Clock();

    this.pointer = new THREE.Vector2();

    this.initRenderer(canvas);

    this.initCamera();

    this.initScene();

    this.initControls();

    this.initRaycaster();

    this.initEvents();

    this.scene.load();

    this.renderer.render(this.scene.getActiveScene(), this.camera.getActiveCamera());
  };

  initRenderer(canvas) {
    this.renderer = new RendererService({ canvas });

    this.renderer
      .getActiveRenderer()
      .getContext()
      .canvas.addEventListener('webglcontextlost', this.webglcontextlost, false);

    this.renderer
      .getActiveRenderer()
      .getContext()
      .canvas.addEventListener('webglcontextrestored', this.webglcontextlost, false);
  }

  webglcontextlost = (event) => {
    event.preventDefault();

    const { setReplaceJourney, setToJourney, setCanNavigate, showInfo3D } = this.props;

    this.props.showInfo3D({
      show: true,
      error3D: true,
    });

    setToJourney('/workflows-overview/mine-map');
    setReplaceJourney(true);
    setCanNavigate(true);

    /* cancelAnimationFrame(this.animationID);

    this.reset();

    this.scene.reInit(); */
  };

  // webglcontextlost = () => {};

  initCamera() {
    this.camera = new CameraService();
  }

  initScene() {
    this.scene = new SceneService({ listener: this });
  }

  onUpdate({ source, action, data = {} }) {
    const target = `${source}:${action}`;

    switch (target) {
      case 'controls:change': {
        if (this.state.state !== 'ready') return;
        const camera = this.camera.getActiveCamera();

        this.badgesHasOcclusions = camera.position.y <= this.scene.lods.camera.hideBadges.positionY;

        if (!this.runnedMovingCameraToObject) {
          if (this.runnedAnimations) {
            for (const [name] of this.activeAnimationModelNames.entries()) {
              const modelConfig = this.scene.lods[name];

              if (modelConfig?.target) {
                const position = modelConfig.target;

                const distanceValue = camera.position.distanceTo(position);

                const distanceForStopAnimation = modelConfig.distanceForStopAnimation || 10;

                if (distanceValue >= distanceForStopAnimation) {
                  // DrillingMachine_Solo
                  this.deactivateAnimation(name);
                  this.activeAnimationModelNames.delete(name);
                  this.runnedAnimations = false;
                }
              }
            }

            if (!this.runnedAnimations) {
              // this.recalculateBadgesPositions(true);
              this.recalculateBadgesSize();
              this.checkConnectorlines(camera);
            }
          } else {
            // this.recalculateBadgesPositions();
            this.recalculateBadgesSize();
            this.checkConnectorlines(camera);
          }
        }

        this.showBadges();

        break;
      }
      case 'scene:pending': {
        this.setState({
          state: 'pending',
        });
        break;
      }
      case 'scene:progress': {
        const { percentages } = data;

        this.setState({
          progress: percentages,
        });
        break;
      }
      case 'scene:loadedModel': {
        break;
      }
      case 'scene:ready': {
        this.controls.setCenter(this.controls.getActiveControls().target);

        this.scene.initConnectorLines();

        this.recalculateBadgesSize();

        this.renderer.root.setClearColor(0x000000, 0);
        this.renderer.render(this.scene.getActiveScene(), this.camera.getActiveCamera());

        this.showBadgesConnectorLines();

        // this.clock.start();

        this.animationID = requestAnimationFrame(this.renderScene);

        // this.renderer.getActiveRenderer().context.getExtension('WEBGL_lose_context').loseContext();
        /* this.scene.createConnectorLine(
          this.scene.lods.Monitoring_Blocks.lod.position,
          this.scene.lods.DrillingMachine_Solo.lod.position
        ); */
        /* this.scene.createGround(0x5a5a5a);

        const cube = this.scene.createCube({ color: 0x000066, z: -10 });

        const position = this.getScreenPosition(
          cube,
          this.renderer.getContainer().clientWidth,
          this.renderer.getContainer().clientHeight,
        );

        this.scene.createCube({ x: 80, color: 0x000066, z: -25 });

        this.scene.createCube({ x: -20, z: -50, color: 0x000066 });

        this.scene.createCube({ z: 5, color: 0x000066, type: 'cubeForMotion' });

        this.scene.initConnectLines();

        this.scene.createInfoPanel();

        this.scene.initCubeMotion();

        this.scene.initPaths();

        this.scene.initHotspots(); */

        // this.mixer = new THREE.AnimationMixer( this.scene.model );
        // const clips = this.scene.model.animations;

        this.setState({
          state: 'ready',
        });
        break;
      }
      default: {
      }
    }
  }

  checkConnectorlines(camera) {
    if (this.runnedMovingCameraToObject || this.runnedAnimations) return;

    const { target, distanceForHide } = this.scene.connectorLinesMetaData;

    const distanceValue = camera.position.distanceTo(target);

    if (distanceValue < distanceForHide) {
      this.hideConnectorLines();
    } else {
      this.showConnectorLines();
    }
  }

  initControls() {
    this.controls = new ControlsService({
      camera: this.camera.getActiveCamera(),
      container: this.renderer.getContainer(),
      listener: this,
      minDistance: 1,
      maxDistance: 3600,
    });
    this.controls.update();
  }

  initRaycaster() {
    this.raycaster = new THREE.Raycaster();
  }

  renderScene = (time) => {
    const deltaTime = this.clock.getDelta();

    this.controls.update();

    if (this.resizeRendererToDisplaySize()) {
      const container = this.renderer.getContainer();
      this.camera.update(container.clientWidth, container.clientHeight);
      this.recalculateBadgesPositions(true);
      this.recalculateBadgesSize();
    } else {
      this.recalculateBadgesPositions();
    }

    if (
      this.animations.DrillingMachine_Solo &&
      this.animations.DrillingMachine_Solo.action?.isRunning()
    ) {
      this.animations.DrillingMachine_Solo.mixer.update(deltaTime);
    }

    if (this.animations.DrillSpots_Solo && this.animations.DrillSpots_Solo.action?.isRunning()) {
      this.animations.DrillSpots_Solo.mixer.update(deltaTime);
    }

    if (
      this.animations.Monitoring_Blocks &&
      this.animations.Monitoring_Blocks.action?.isRunning()
    ) {
      this.animations.Monitoring_Blocks.mixer.update(deltaTime);
    }

    if (
      this.animations.Monitoring_Blocks &&
      this.animations.Monitoring_Blocks.action?.isRunning()
    ) {
      this.animations.Monitoring_Blocks.mixer.update(deltaTime);
    }

    if (
      this.animations.Planning_Combined &&
      this.animations.Planning_Combined.action?.isRunning()
    ) {
      this.animations.Planning_Combined.mixer.update(deltaTime);
    }

    if (this.animations.Drill_Blast && this.animations.Drill_Blast.action?.isRunning()) {
      this.animations.Drill_Blast.mixer.update(deltaTime);
    }

    if (
      this.animations.Material_Movement &&
      this.animations.Material_Movement.action?.isRunning()
    ) {
      this.animations.Material_Movement.mixer.update(deltaTime);
    }

    if (this.animations.Safety && this.animations.Safety.action?.isRunning()) {
      this.animations.Safety.mixer.update(deltaTime);
    }

    /* if (this.animations.Planning_Drone_Scan_Solo) {
      this.animations.Planning_Drone_Scan_Solo.mixer.update(deltaTime);
    }

    if (this.animations.Planning_GridMotion_Solo) {
      this.animations.Planning_GridMotion_Solo.mixer.update(deltaTime);
    }

    if (this.animations.Planning_ScanDevice_Waves_Solo) {
      this.animations.Planning_ScanDevice_Waves_Solo.mixer.update(deltaTime);
    } */

    TWEEN.update(time);

    this.renderer.render(this.scene.getActiveScene(), this.camera.getActiveCamera());

    this.animationID = requestAnimationFrame(this.renderScene);
  };

  resizeRendererToDisplaySize = () => {
    const container = this.renderer.getContainer();

    const pixelRatio = window.devicePixelRatio || 0;
    const width = parseInt(container.clientWidth * pixelRatio, 10);
    const height = parseInt(container.clientHeight * pixelRatio, 10);
    const needResize =
      parseInt(container.width, 10) !== width || parseInt(container.height, 10) !== height;
    if (needResize) {
      this.renderer.setSize(width, height, false);
    }

    return needResize;
  };

  initEvents() {
    window.addEventListener('pointermove', this.onPointerMove);
    window.addEventListener('pointerdown', this.onPointerDown);
  }

  onPointerMove = (event) => {
    // calculate pointer position in normalized device coordinates
    // (-1 to +1) for both components
    this.updatePointer(event);
  };

  onPointerDown = (event) => {
    // calculate pointer position in normalized device coordinates
    // (-1 to +1) for both components
    this.updatePointer(event);

    this.raycaster.setFromCamera(this.pointer, this.camera.getActiveCamera());

    const intersects = this.raycaster.intersectObjects(this.scene.scene.children);

    if (Array.isArray(intersects)) {
      for (let i = 0; i < intersects.length; i += 1) {
        if (intersects[i].object.name === 'Terrain' || !intersects[i].object.name) {
          // this.controls.getActiveControls().target.set(new THREE.Vector3(0, 0, 0));
          const { x, y, z } = this.controls.pivotPoint;
          // this.controls.getActiveControls().target.set(x, y, z);
          break;
        } else if (intersects[i].object.name) {
          let object = intersects[i].object;

          while (object.parent) {
            object = object.parent;

            if (object.type === 'LOD') break;
          }

          if (
            object &&
            (this.selected === null || this.selected.name !== object.name) &&
            object.type !== 'Scene'
          ) {
            this.selected = object;
            const { x, y, z } = object.position;
            // this.controls.getActiveControls().target.set(x, y, z);
            this.cameraToMarker(this.selected, this.camera.getActiveCamera());
          }

          // const { x, y, z } = object.position;
          // this.controls.getActiveControls().target.set(x, y, z);
          break;
        }
      }
    }

    if (this.selected) {
      // this.cameraToMarker(this.selected, this.camera.getActiveCamera());
      /* const tween = new TWEEN.Tween(this.camera.position).to(this.selected.position, 2000);
      tween.easing(TWEEN.Easing.Linear.None);
      tween.onUpdate(() => {
        // this.camera.lookAt(target);
      });
      tween.start(); */
    } else {
      // this.controls.getActiveControls().target.set(osition)
    }
  };

  updatePointer(event) {
    const el = this.renderer.getContainer();
    const rect = el.getBoundingClientRect();

    this.pointer.x = ((event.clientX - rect.left) / el.clientWidth) * 2 - 1;
    this.pointer.y = -((event.clientY - rect.top) / el.clientHeight) * 2 + 1;
  }

  selectObject() {
    // update the picking ray with the camera and pointer position
    this.raycaster.setFromCamera(this.pointer, this.camera.getActiveCamera());
    // calculate objects intersecting the picking ray
    const intersects = this.raycaster.intersectObjects(this.scene.scene.children);

    if (Array.isArray(intersects)) {
      for (let i = 0; i < intersects.length; i += 1) {
        if (intersects[i].object.name === 'cube') {
          if (this.selected) {
            this.selected.material.color.set(0x000066);
          }
          intersects[i].object.material.color.set(0xff0000);
          this.selected = intersects[i].object;
          return this.selected;
          // this.camera.position.setX(this.selected.position.x);
          // this.controls.target = this.selected.position;
        }
      }
    }

    return null;
  }

  updateCameraOrbit() {
    // console.log('updateCameraOrbit');
    // Update OrbitControls target to a point just in front of the camera
    // this.camera.lookAt(this.camera.position);
    const forward = new THREE.Vector3();
    this.camera.getActiveCamera().getWorldDirection(forward);

    this.controls
      .getActiveControls()
      .target.copy(this.camera.getActiveCamera().position)
      .add(forward);

    // this.controls.getActiveControls().target.copy(this.camera.getActiveCamera().position).add(forward);
    // const arrs = ['vehicle_1', 'vehicle_2', 'vehicle_3', 'vehicle_4', 'cybertruck'];

    // if (this.selected && arrs.includes(this.selected.name)) {
    // const { x, y, z } = this.selected.position;

    // this.camera.getActiveCamera().position.copy(this.selected.position);
    // this.controls.getActiveControls().target.set(x, y, z);
    // this.controls.getActiveControls().target.copy(this.selected.position).add(forward);
    // }

    /* this.camera.lookAt(this.camera.position);

      const endRotation = new THREE.Euler().copy(this.camera.rotation);

      this.camera.rotation.set(endRotation); */
    // this.controls.target = this.camera.position;
  }

  getScreenPosition(object, width = 0, height = 0, flipY) {
    const { x, y, z } = object.position;

    const p = new THREE.Vector3(x, y, z);
    const vector = p.project(this.camera.getActiveCamera());

    vector.x = ((vector.x + 1) / 2) * width;
    vector.y = (-(vector.y - 1) / 2) * height;

    return vector;
  }

  getScreenPosition1(object, width = 0, height = 0, flipY) {
    const widthHalf = width / 2;
    const heightHalf = height / 2;

    /* const width = 640, height = 480;
    const widthHalf = width / 2, heightHalf = height / 2;

    const vector = new THREE.Vector3();
    const projector = new THREE.Projector();
    projector.projectVector(vector.setFromMatrixPosition(object.matrixWorld), this.camera);

    vector.x = (vector.x * widthHalf) + widthHalf;
    vector.y = - (vector.y * heightHalf) + heightHalf; */

    let matrix = null;

    if (!matrix) {
      matrix = object.matrixWorld;
    }

    const vector = new THREE.Vector3();
    object.getWorldPosition(vector); //  = vector.setFromMatrixPosition(matrix);

    vector.project(this.camera.getActiveCamera());

    vector.x = vector.x * widthHalf + widthHalf;
    vector.y = -(vector.y * heightHalf) + heightHalf;

    /* const p = object.position.clone();
		this.camera.updateMatrixWorld();
    p.project(this.camera); */

    return vector;
  }

  clickOnMaterialMovement = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'material-movement' });
    this.selected = this.scene.lods.Material_Movement.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.Material_Movement, this.camera.getActiveCamera());
  };

  clickOnPlanning = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'planning' });
    this.selected = this.scene.lods.Planning_Combined.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.Planning_Combined, this.camera.getActiveCamera());
  };

  clickOnDrillingMachine = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'exploration' });
    this.selected = this.scene.lods.DrillingMachine_Solo.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.DrillingMachine_Solo, this.camera.getActiveCamera());
  };

  clickOnSurveyMonitoring = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'survey-monitoring' });
    this.selected = this.scene.lods.Monitoring_Blocks.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.Monitoring_Blocks, this.camera.getActiveCamera());
  };

  clickOnDrillBlast = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'drill-blast' });
    this.selected = this.scene.lods.Drill_Blast.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.Drill_Blast, this.camera.getActiveCamera());
  };

  clickOnUnderground = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'underground' });
    this.selected = this.scene.lods.Tunnel.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.Tunnel, this.camera.getActiveCamera());
  };

  clickOnSafety = () => {
    this.runnedMovingCameraToObject = true;
    this.showBadges(false);
    this.deactivateActiveAnimations();
    this.setState({ currentLocation: 'safety' });
    this.selected = this.scene.lods.Safety.lod;
    this.hideConnectorLines();
    this.movingToObject(this.scene.lods.Safety, this.camera.getActiveCamera());
  };

  clickOnCloseSidebar = () => {
    const { showSidebar } = this.state;
    this.setState({ showSidebar: !showSidebar });
    eventBus.dispatch('toggleSidebarOpened', { isOpen: !showSidebar });
  };

  resetView = () => {
    this.deactivateActiveAnimations();
    this.setState({ showSidebar: false, activeSidebar: false, currentLocation: null });
    eventBus.dispatch('toggleSidebarOpened', { isOpen: false });
    this.movingToObject(this.scene.lods.camera, this.camera.getActiveCamera());
  };

  recalculateBadgesPositions(ignoreVisibility = false) {
    const container = this.renderer.getContainer();

    const {
      showBadgeExploration,
      showBadgePlanning,
      showBadgeSurveyMonitoring,
      showBadgeDrillBlast,
      showBadgeMaterialMovement,
      showBadgeUnderground,
      showBadgeSafety,
    } = this.badges;

    if (ignoreVisibility || showBadgeExploration) {
      if (this.scene.lods.DrillingMachine_Solo.originModel) {
        const drillingMachinePosition = this.getScreenPosition(
          this.scene.lods.DrillingMachine_Solo.lod,
          container.clientWidth,
          container.clientHeight,
        );
        if (
          this.badges.drillingMachinePosition[0] !== drillingMachinePosition.x ||
          this.badges.drillingMachinePosition[1] !== drillingMachinePosition.y
        ) {
          this.badges.drillingMachinePosition = [
            drillingMachinePosition.x - 24,
            drillingMachinePosition.y - 24,
          ];

          if (this.explorationBadge.current) {
            this.explorationBadge.current.style.top = `${this.badges.drillingMachinePosition[1]}px`;
            this.explorationBadge.current.style.left = `${this.badges.drillingMachinePosition[0]}px`;
          }
        }
      }
    }

    if (ignoreVisibility || showBadgePlanning) {
      const planningPosition = this.getScreenPosition(
        this.scene.lods.Planning_Combined.lod,
        container.clientWidth,
        container.clientHeight,
      );
      if (
        this.badges.planningPosition[0] !== planningPosition.x ||
        this.badges.planningPosition[1] !== planningPosition.y
      ) {
        this.badges.planningPosition = [planningPosition.x - 24, planningPosition.y - 24];

        if (this.planningBadge.current) {
          this.planningBadge.current.style.top = `${this.badges.planningPosition[1]}px`;
          this.planningBadge.current.style.left = `${this.badges.planningPosition[0]}px`;
        }
      }
    }

    if (ignoreVisibility || showBadgeSurveyMonitoring) {
      const surveyMonitoringPosition = this.getScreenPosition(
        this.scene.lods.Monitoring_Blocks.lod,
        container.clientWidth,
        container.clientHeight,
      );
      if (
        this.badges.surveyMonitoringPosition[0] !== surveyMonitoringPosition.x ||
        this.badges.surveyMonitoringPosition[1] !== surveyMonitoringPosition.y
      ) {
        this.badges.surveyMonitoringPosition = [
          surveyMonitoringPosition.x - 24,
          surveyMonitoringPosition.y - 24,
        ];

        if (this.surveyMonitoringBadge.current) {
          this.surveyMonitoringBadge.current.style.top = `${this.badges.surveyMonitoringPosition[1]}px`;
          this.surveyMonitoringBadge.current.style.left = `${this.badges.surveyMonitoringPosition[0]}px`;
        }
      }
    }

    if (ignoreVisibility || showBadgeDrillBlast) {
      const drillblastPosition = this.getScreenPosition(
        this.scene.lods.Drill_Blast.lod,
        container.clientWidth,
        container.clientHeight,
      );
      if (
        this.badges.drillblastPosition[0] !== drillblastPosition.x ||
        this.badges.drillblastPosition[1] !== drillblastPosition.y
      ) {
        this.badges.drillblastPosition = [drillblastPosition.x - 24, drillblastPosition.y - 24];

        if (this.drillBlastBadge.current) {
          this.drillBlastBadge.current.style.top = `${this.badges.drillblastPosition[1]}px`;
          this.drillBlastBadge.current.style.left = `${this.badges.drillblastPosition[0]}px`;
        }
      }
    }

    if (ignoreVisibility || showBadgeMaterialMovement) {
      const materialMovementPosition = this.getScreenPosition(
        this.scene.lods.Material_Movement.lod,
        container.clientWidth,
        container.clientHeight,
      );
      if (
        this.badges.materialMovementPosition[0] !== materialMovementPosition.x ||
        this.badges.materialMovementPosition[1] !== materialMovementPosition.y
      ) {
        this.badges.materialMovementPosition = [
          materialMovementPosition.x - 24,
          materialMovementPosition.y - 24,
        ];

        if (this.materialMovementBadge.current) {
          this.materialMovementBadge.current.style.top = `${this.badges.materialMovementPosition[1]}px`;
          this.materialMovementBadge.current.style.left = `${this.badges.materialMovementPosition[0]}px`;
        }
      }
    }

    if (ignoreVisibility || showBadgeUnderground) {
      const undergroundPosition = this.getScreenPosition(
        this.scene.lods.Tunnel.lod,
        container.clientWidth,
        container.clientHeight,
      );
      if (
        this.badges.undergroundPosition[0] !== undergroundPosition.x ||
        this.badges.undergroundPosition[1] !== undergroundPosition.y
      ) {
        this.badges.undergroundPosition = [undergroundPosition.x - 24, undergroundPosition.y - 24];
        if (this.undergroundBadge.current) {
          this.undergroundBadge.current.style.top = `${this.badges.undergroundPosition[1]}px`;
          this.undergroundBadge.current.style.left = `${this.badges.undergroundPosition[0]}px`;
        }
      }
    }

    if (ignoreVisibility || showBadgeSafety) {
      const safetyPosition = this.getScreenPosition(
        this.scene.lods.Safety.lod,
        container.clientWidth,
        container.clientHeight,
      );
      if (
        this.badges.safetyPosition[0] !== safetyPosition.x ||
        this.badges.safetyPosition[1] !== safetyPosition.y
      ) {
        this.badges.safetyPosition = [safetyPosition.x - 24, safetyPosition.y - 24];

        if (this.safetyBadge.current) {
          this.safetyBadge.current.style.top = `${this.badges.safetyPosition[1]}px`;
          this.safetyBadge.current.style.left = `${this.badges.safetyPosition[0]}px`;
        }
      }
    }
  }

  recalculateBadgesSize() {
    const cameraDistance = this.controls.getActiveControls().getDistance();

    const { scale } = this.props.journey?.scene?.camera;

    let maxDistance = Infinity;

    const distances = Object.keys(scale);

    for (let i = 0, n = distances.length; i < n; i++) {
      const d = +distances[i];

      if (cameraDistance <= d && d < maxDistance) {
        maxDistance = d;
      }
    }

    if (maxDistance !== this.maxDistanceForScaleBadges) {
      this.maxDistanceForScaleBadges = maxDistance;
      this.changeBadgesSize(scale[this.maxDistanceForScaleBadges]);
    }
  }

  changeBadgesSize(size) {
    if (size) {
      const badges = [
        'materialMovementBadge',
        'planningBadge',
        'explorationBadge',
        'surveyMonitoringBadge',
        'drillBlastBadge',
        'undergroundBadge',
        'safetyBadge',
      ];

      this.setState({ pointerSize: size.pointer });

      for (let i = 0, n = badges.length; i < n; i++) {
        const badge = this[badges[i]].current;

        if (badge) {
          badge.className = size.ownClass ? `scene-badge ${size.ownClass}` : `scene-badge`;
        }
      }
    }
  }

  showBadges(visible = true) {
    if (this.badgesHasOcclusions || this.runnedMovingCameraToObject || this.runnedAnimations) {
      visible = false;
    }

    if (this.showedBadges === visible) return;

    this.showedBadges = visible;

    this.badges.showBadgeMaterialMovement = visible;
    this.badges.showBadgePlanning = visible;
    this.badges.showBadgeExploration = visible;
    this.badges.showBadgeSurveyMonitoring = visible;
    this.badges.showBadgeDrillBlast = visible;
    this.badges.showBadgeUnderground = visible;
    this.badges.showBadgeSafety = visible;

    const stateForVisibility = visible ? '' : 'hidden';

    this.materialMovementBadge.current &&
      (this.materialMovementBadge.current.style.visibility = stateForVisibility);
    this.planningBadge.current &&
      (this.planningBadge.current.style.visibility = stateForVisibility);
    this.explorationBadge.current &&
      (this.explorationBadge.current.style.visibility = stateForVisibility);
    this.surveyMonitoringBadge.current &&
      (this.surveyMonitoringBadge.current.style.visibility = stateForVisibility);
    this.drillBlastBadge.current &&
      (this.drillBlastBadge.current.style.visibility = stateForVisibility);
    this.undergroundBadge.current &&
      (this.undergroundBadge.current.style.visibility = stateForVisibility);
    this.safetyBadge.current && (this.safetyBadge.current.style.visibility = stateForVisibility);
  }

  activateAnimationForModel(name) {
    if (name === 'DrillingMachine_Solo') {
      if (this.scene.lods.DrillingMachine_Solo.loadedModel) {
        const animationModel = {
          mixer: new THREE.AnimationMixer(this.scene.lods.DrillingMachine_Solo.loadedModel.scene),
          action: null,
        };

        this.animations[name] = animationModel;

        const animationAction = animationModel.mixer.clipAction(
          this.scene.lods.DrillingMachine_Solo.loadedModel.animations[0],
        );
        animationModel.action = animationAction;
        animationModel.action.setLoop(THREE.LoopRepeat);
        animationModel.action.play();

        this.activeAnimationModelNames.set(name, true);
        this.runnedAnimations = true;

        // this.scene.lods.Terrain_Explosion.loadedModel.scene.visible = false;
      }
    } else if (name === 'DrillSpots_Solo') {
      if (this.scene.lods.DrillSpots_Solo.loadedModel) {
        const animationModel = {
          mixer: new THREE.AnimationMixer(this.scene.lods.DrillSpots_Solo.loadedModel.scene),
          action: null,
        };

        this.animations[name] = animationModel;

        const animationAction = animationModel.mixer.clipAction(
          this.scene.lods.DrillSpots_Solo.loadedModel.animations[0],
        );
        animationModel.action = animationAction;
        animationModel.action.setLoop(THREE.LoopRepeat);
        animationModel.action.play();

        this.activeAnimationModelNames.set(name, true);
        this.runnedAnimations = true;
      }
    } else if (name === 'Planning') {
      const names = [
        'Planning_Combined',
        // 'Planning_Drone_Scan_Solo',
        // 'Planning_GridMotion_Solo',
        // 'Planning_ScanDevice_Waves_Solo'
      ];

      // this.animations.Planning_Combined = true;

      let i = -1;
      const n = names.length;

      while (++i < n) {
        const name = names[i];

        const model = this.scene.lods[name];

        if (model?.loadedModel) {
          const group = model.loadedModel.scene;

          const animationModel = {
            mixer: new THREE.AnimationMixer(group),
          };

          this.animations[name] = animationModel;

          const animationAction = animationModel.mixer.clipAction(model.loadedModel.animations[0]);

          animationModel.action = animationAction;
          animationModel.action.setLoop(THREE.LoopRepeat);

          if (Array.isArray(model.hideObjects)) {
            let i = -1;
            const n = model.hideObjects.length;

            while (++i < n) {
              if (model.hideObjects[i] !== 'Terrain_Base_Plate_Triangle') {
                const m = group.getObjectByName(model.hideObjects[i]);

                if (m) {
                  m.visible = true;
                }
              }
            }
          }

          animationModel.action.play();

          this.activeAnimationModelNames.set(name, true);
          this.runnedAnimations = true;
        }
      }
    } else if (name === 'Monitoring_Blocks') {
      // TODO: DUPLICATE AS PREVIOUS FOR Planning_Combined
      const names = ['Monitoring_Blocks'];

      let i = -1;
      const n = names.length;

      while (++i < n) {
        const name = names[i];

        const model = this.scene.lods[name];

        if (model?.loadedModel) {
          const group = model.loadedModel.scene;

          const animationModel = {
            mixer: new THREE.AnimationMixer(group),
          };

          this.animations[name] = animationModel;

          const animationAction = animationModel.mixer.clipAction(model.loadedModel.animations[0]);

          animationModel.action = animationAction;
          animationModel.action.setLoop(THREE.LoopRepeat);

          if (Array.isArray(model.hideObjects)) {
            let i = -1;
            const n = model.hideObjects.length;

            while (++i < n) {
              const m = group.getObjectByName(model.hideObjects[i]);

              if (m) {
                m.visible = true;
              }
            }
          }

          animationModel.action.play();

          this.activeAnimationModelNames.set(name, true);
          this.runnedAnimations = true;
        }
      }
    } else if (name === 'Drill_Blast') {
      // TODO: DUPLICATE AS PREVIOUS FOR Planning_Combined
      const names = ['Drill_Blast'];

      let i = -1;
      const n = names.length;

      while (++i < n) {
        const name = names[i];

        const model = this.scene.lods[name];

        if (model?.loadedModel) {
          const group = model.loadedModel.scene;

          const animationModel = {
            mixer: new THREE.AnimationMixer(group),
          };

          this.animations[name] = animationModel;

          const animationAction = animationModel.mixer.clipAction(model.loadedModel.animations[0]);

          animationModel.action = animationAction;
          animationModel.action.setLoop(THREE.LoopRepeat);

          if (Array.isArray(model.hideObjects)) {
            let i = -1;
            const n = model.hideObjects.length;

            while (++i < n) {
              const m = group.getObjectByName(model.hideObjects[i]);

              if (m) {
                m.visible = true;
              }
            }
          }

          animationModel.action.play();

          this.activeAnimationModelNames.set(name, true);
          this.runnedAnimations = true;
        }
      }
    } else if (name === 'Material_Movement') {
      // TODO: DUPLICATE AS PREVIOUS FOR Planning_Combined
      const names = ['Material_Movement'];

      let i = -1;
      const n = names.length;

      while (++i < n) {
        const name = names[i];

        const model = this.scene.lods[name];

        if (model?.loadedModel) {
          const group = model.loadedModel.scene;

          const animationModel = {
            mixer: new THREE.AnimationMixer(group),
          };

          this.animations[name] = animationModel;

          const animationAction = animationModel.mixer.clipAction(model.loadedModel.animations[0]);

          animationModel.action = animationAction;
          animationModel.action.setLoop(THREE.LoopRepeat);

          if (Array.isArray(model.hideObjects)) {
            let i = -1;
            const n = model.hideObjects.length;

            while (++i < n) {
              const m = group.getObjectByName(model.hideObjects[i]);

              if (m) {
                m.visible = true;
              }
            }
          }

          animationModel.action.play();

          this.activeAnimationModelNames.set(name, true);
          this.runnedAnimations = true;
        }
      }
    } else if (name === 'Safety') {
      // TODO: DUPLICATE AS PREVIOUS FOR Planning_Combined
      const names = ['Safety'];

      let i = -1;
      const n = names.length;

      while (++i < n) {
        const name = names[i];

        const model = this.scene.lods[name];

        if (model?.loadedModel) {
          const group = model.loadedModel.scene;

          const animationModel = {
            mixer: new THREE.AnimationMixer(group),
          };

          this.animations[name] = animationModel;

          const animationAction = animationModel.mixer.clipAction(model.loadedModel.animations[0]);

          animationModel.action = animationAction;
          animationModel.action.setLoop(THREE.LoopRepeat);

          if (Array.isArray(model.hideObjects)) {
            let i = -1;
            const n = model.hideObjects.length;

            while (++i < n) {
              const m = group.getObjectByName(model.hideObjects[i]);

              if (m) {
                m.visible = true;
              }
            }
          }

          animationModel.action.play();

          this.activeAnimationModelNames.set(name, true);
          this.runnedAnimations = true;
        }
      }
    }
  }

  deactivateAnimations() {
    Object.keys(this.animations).forEach((name) => {
      this.deactivateAnimation(name);
    });

    this.runnedAnimations = false;
  }

  deactivateActiveAnimations() {
    if (this.activeAnimationModelNames) {
      for (const [name] of this.activeAnimationModelNames.entries()) {
        this.deactivateAnimation(name);
      }

      this.activeAnimationModelNames.clear();

      this.runnedAnimations = false;
    }
  }

  deactivateAnimation(name) {
    if (this.animations[name]) {
      this.animations[name].action.stop();
      this.animations[name].mixer.stopAllAction();
      this.animations[name].mixer.setTime(0);

      const model = this.scene.lods[name];

      if (model?.loadedModel) {
        const group = model.loadedModel.scene;

        if (Array.isArray(model.hideObjects)) {
          let i = -1;
          const n = model.hideObjects.length;

          while (++i < n) {
            const m = group.getObjectByName(model.hideObjects[i]);

            if (m) {
              m.visible = false;
            }
          }
        }
      }

      /* if (name === 'DrillingMachine_Solo') {
        this.scene.lods.Terrain_Explosion.loadedModel.scene.visible = true;
      } */

      delete this.animations[name];
    }
  }

  movingToObject(target, camera) {
    let point = null;

    if (target?.path) {
      target.path.currentIndex += 1;

      if (target.path.currentIndex > target.path.points.length - 1) {
        this.runnedMovingCameraToObject = false;

        target.path.currentIndex = -1;

        if (target.name === 'camera') {
          this.showBadges();
        } else {
          this.setState({
            showSidebar: true,
            activeSidebar: true,
          });

          eventBus.dispatch('toggleSidebarOpened', { isOpen: true });
        }

        if (target.name === 'DrillingMachine_Solo') {
          if (!this.animations.DrillingMachine_Solo) {
            this.activateAnimationForModel('DrillingMachine_Solo');
          }

          if (!this.animations.DrillSpots_Solo) {
            this.activateAnimationForModel('DrillSpots_Solo');
          }
        } else if (target.name === 'Planning_Combined') {
          if (!this.animations.Planning_Combined) {
            this.activateAnimationForModel('Planning');
          }
        } else if (target.name === 'Monitoring_Blocks') {
          if (!this.animations.Monitoring_Blocks) {
            this.activateAnimationForModel('Monitoring_Blocks');
          }
        } else if (target.name === 'Drill_Blast') {
          if (!this.animations.Drill_Blast) {
            this.activateAnimationForModel('Drill_Blast');
          }
        } else if (target.name === 'Material_Movement') {
          if (!this.animations.Material_Movement) {
            this.activateAnimationForModel('Material_Movement');
          }
        } else if (target.name === 'Safety') {
          if (!this.animations.Safety) {
            this.activateAnimationForModel('Safety');
          }
        }

        return;
      }

      point = target.path.points[target.path.currentIndex];
    }

    // const cameraPosition = camera.position.clone();

    // const targetPosition = point.position.clone();

    // const distance = cameraPosition.sub(targetPosition);

    // const direction = distance.normalize();

    // const offset = distance.clone();
    // const offset = distance.clone().sub(direction.multiplyScalar(25)); // distance.clone().sub(direction.multiplyScalar(25)); // distance.clone();// distance.clone().sub(direction.multiplyScalar(25));

    const newPos = point.position.clone(); // .sub(offset);

    const movingTime = isNumeric(target.path?.movingTime) ? target.path.movingTime : 2000;

    const startQuaternion = camera.quaternion.clone();
    camera.lookAt(point.position);
    const endQuaternion = camera.quaternion.clone();
    camera.quaternion.copy(startQuaternion);

    this.timeForRotation.t = 0;

    new TWEEN.Tween(this.timeForRotation)
      .to({ t: 1 }, movingTime)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        const newQuaternion = new THREE.Quaternion();
        newQuaternion.slerpQuaternions(startQuaternion, endQuaternion, this.timeForRotation.t);

        camera.quaternion.copy(newQuaternion);
        camera.quaternion.normalize();
      })
      .start();

    // console.log('MovingObject:newPos', newPos, camera.position, target, offset, direction, distance);
    const tween = new TWEEN.Tween(camera.position).to(
      {
        x: newPos.x,
        y: newPos.y,
        z: newPos.z,
      },
      movingTime,
    );

    // tween.easing(TWEEN.Easing.Quadratic.Out);
    tween.start();
    tween.onUpdate(() => {
      // console.log('MovingObject:newPos:onUpdate', camera.position);
      // this.updateCameraOrbit();
    });
    tween.onComplete(() => {
      // console.log('MovingObject:newPos:onComplete', camera.position);
      // this.updateCameraOrbit();

      if (target) {
        const arrs = [
          'DrillingMachine_Solo',
          'Planning_Combined',
          'Monitoring_Blocks',
          'Drill_Blast',
          'Material_Movement',
          'Safety',
          'camera',
          'Tunnel',
        ];

        if (arrs.includes(target.name)) {
          const { x, y, z } = target.path?.target ? target.path.target : point.position;

          if (target?.path) {
            if (target.path.currentIndex === target.path.points.length - 1) {
              /* if (target.path.offsetTarget) {
                const offsetTarget = target.path.offsetTarget;

                const forward = new THREE.Vector3();
                this.camera.getActiveCamera().getWorldDirection(forward);
                forward.multiplyScalar(offsetTarget);

                this.controls.getActiveControls().target.set(x, y, z).add(forward);
              } else {
                this.controls.getActiveControls().target.set(x, y, z);
              } */

              const startQuaternion = camera.quaternion.clone();
              camera.lookAt(new THREE.Vector3(x, y, z));
              const endQuaternion = camera.quaternion.clone();
              camera.quaternion.copy(startQuaternion);

              this.timeForRotation.targetTime = 0;

              new TWEEN.Tween(this.timeForRotation)
                .to({ targetTime: 1 }, 2000)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onUpdate(() => {
                  const newQuaternion = new THREE.Quaternion();
                  newQuaternion.slerpQuaternions(
                    startQuaternion,
                    endQuaternion,
                    this.timeForRotation.targetTime,
                  );

                  camera.quaternion.copy(newQuaternion);
                  camera.quaternion.normalize();

                  // this.updateCameraOrbit();
                })
                .onComplete(() => {
                  // this.controls.getActiveControls().center.set(x, y, z);
                  // this.controls.getActiveControls().target.set(x, y, z);
                  // this.controls.update();

                  if (!this.changeTargetForControl() || target.path?.forceChangeTarget) {
                    this.controls.getActiveControls().target.set(x, y, z);
                  }
                  /* const p = new THREE.Vector3(x, y, z);
                  const vector = p.project(this.camera.getActiveCamera());

                  const width = this.renderer.getContainer().clientWidth;
                  const height = this.renderer.getContainer().clientHeight;

                  vector.x = ((vector.x + 1) / 2) * width;
                  vector.y = (-(vector.y - 1) / 2) * height;

                  this.raycaster.setFromCamera(new THREE.Vector2(vector.x, vector.y), this.camera.getActiveCamera());

                  const intersects1 = this.raycaster.intersectObjects(this.scene.scene.children); */

                  // this.updateCameraOrbit();
                  this.movingToObject(target, camera);
                })
                .start();
            } else {
              this.movingToObject(target, camera);
            }
          } else {
            const startQuaternion = camera.quaternion.clone();
            camera.lookAt(new THREE.Vector3(x, y, z));
            const endQuaternion = camera.quaternion.clone();
            camera.quaternion.copy(startQuaternion);

            this.timeForRotation.targetTime = 0;

            new TWEEN.Tween(this.timeForRotation)
              .to({ targetTime: 1 }, 2000)
              .easing(TWEEN.Easing.Quadratic.InOut)
              .onUpdate(() => {
                const newQuaternion = new THREE.Quaternion();
                newQuaternion.slerpQuaternions(
                  startQuaternion,
                  endQuaternion,
                  this.timeForRotation.targetTime,
                );

                camera.quaternion.copy(newQuaternion);
                camera.quaternion.normalize();
              })
              .onComplete(() => {
                if (!this.changeTargetForControl() || target.path?.forceChangeTarget) {
                  this.controls.getActiveControls().target.set(x, y, z);
                }

                this.runnedMovingCameraToObject = false;
              })
              .start();
          }
        }
      }
    });
  }

  changeTargetForControl() {
    const forward = new THREE.Vector3();
    this.camera.getActiveCamera().getWorldDirection(forward);

    const raycaster = new THREE.Raycaster(this.camera.getActiveCamera().position, forward);
    const intersects = raycaster.intersectObjects([this.scene.lods.terrain.loadedModel.scene]);

    if (intersects.length > 0) {
      let maxPoint = null;

      let i = -1;
      const n = intersects.length;

      while (++i < n) {
        if (maxPoint === null) {
          maxPoint = intersects[i].point;
        } else if (maxPoint.y < intersects[i].point.y) {
          maxPoint = intersects[i].point;
        }
      }

      this.controls.getActiveControls().target.set(maxPoint.x, maxPoint.y, maxPoint.z);

      return true;
    }

    return false;
  }

  moveCameraToLocation = (slug) => {
    this.setState({
      showSidebar: false,
    });
    eventBus.dispatch('toggleSidebarOpened', { isOpen: false });

    switch (slug) {
      case 'exploration':
        this.clickOnDrillingMachine();
        break;
      case 'planning':
        this.clickOnPlanning();
        break;
      case 'material-movement':
        this.clickOnMaterialMovement();
        break;
      case 'survey-monitoring':
        this.clickOnSurveyMonitoring();
        break;
      case 'drill-blast':
        this.clickOnDrillBlast();
        break;
      case 'underground':
        this.clickOnUnderground();
        break;
      case 'safety':
        this.clickOnSafety();
        break;
      default: {
      }
    }
  };

  onGoIntoLocation = async () => {
    const { isUserLoggedIn, lomixConfig, onEvent } = this.props;

    const { currentLocation: id } = this.state;

    const journey = lomixConfig.find((j) => j.slug === id) || lomixConfig[0];

    const eventText = `deeg_deeper_into_${journey.title}`;
    sendGTEvent({ event: eventText });
    // this.clickOnCloseSidebar();

    if (isUserLoggedIn) {
      onEvent({
        action: 'journey:change',
        data: {
          id,
        },
      });
    } else {
      this.setState({ showSignUpForm: true });
      saveToLS('deferredJourney', id);
      // loginMsal();
    }
  };

  showBadgesConnectorLines() {
    this.connectorLines.startedProcess = 'showConnectorLines';

    this.showBadges();

    const tw = new TWEEN.Tween(this.badges);
    tw.to({ opacity: 1 }, 3000); // .easing(TWEEN.Easing.Linear.None)
    tw.onUpdate((object) => {
      this.materialMovementBadge.current &&
        (this.materialMovementBadge.current.style.opacity = `${object.opacity}`);
      this.planningBadge.current &&
        (this.planningBadge.current.style.opacity = `${object.opacity}`);
      this.explorationBadge.current &&
        (this.explorationBadge.current.style.opacity = `${object.opacity}`);
      this.surveyMonitoringBadge.current &&
        (this.surveyMonitoringBadge.current.style.opacity = `${object.opacity}`);
      this.drillBlastBadge.current &&
        (this.drillBlastBadge.current.style.opacity = `${object.opacity}`);
      this.undergroundBadge.current &&
        (this.undergroundBadge.current.style.opacity = `${object.opacity}`);
      this.safetyBadge.current && (this.safetyBadge.current.style.opacity = `${object.opacity}`);
      let i = -1;
      const n = this.scene.connectorLinesIds.length;

      while (++i < n) {
        const id = this.scene.connectorLinesIds[i];
        const connectorLine = this.scene.connectorLinesByIds.get(id);

        connectorLine.model.material.opacity = object.opacity / 2;
      }
    });
    tw.onComplete(() => {
      // this.controls.enable();
      if (
        this.connectorLines.startedProcess === null ||
        this.connectorLines.startedProcess === 'showConnectorLines'
      ) {
        this.connectorLines.opacity = 1;
        this.connectorLines.showed = true;
        this.connectorLines.movingArrows = true;
        this.startMovingArrows({ useDelay: true });
      }
    });
    tw.start();

    // this.badges.tween = tw;
  }

  showConnectorLines() {
    if (this.connectorLines.startedProcess === 'showConnectorLines') return;

    if (this.connectorLines.startedProcess === 'hideConnectorLines') {
      if (this.connectorLines.tween) {
        this.connectorLines.tween.stop();
        this.connectorLines.tween = null;
      }
    } else if (this.connectorLines.showed) return;

    this.connectorLines.startedProcess = 'showConnectorLines';
    // this.connectorLines.opacity = 0;

    const tw = new TWEEN.Tween(this.connectorLines);
    this.connectorLines.tween = tw;
    tw.to({ opacity: 1 }, 2000); // .easing(TWEEN.Easing.Linear.None)
    tw.onStart(() => {
      let i = -1;
      const n = this.scene.connectorLinesIds.length;

      while (++i < n) {
        const id = this.scene.connectorLinesIds[i];
        const connectorLine = this.scene.connectorLinesByIds.get(id);
        connectorLine.model.visible = true;
      }
    });
    tw.onUpdate((object) => {
      let i = -1;
      const n = this.scene.connectorLinesIds.length;

      while (++i < n) {
        const id = this.scene.connectorLinesIds[i];
        const connectorLine = this.scene.connectorLinesByIds.get(id);
        connectorLine.model.material.opacity = object.opacity / 2;
      }
    });
    tw.onComplete(() => {
      // this.controls.enable();
      this.connectorLines.showed = true;
      this.connectorLines.movingArrows = true;
      this.startMovingArrows({ useDelay: true });
      this.connectorLines.tween = null;
      this.connectorLines.startedProcess = null;
    });
    tw.start();
  }

  hideConnectorLines() {
    if (this.connectorLines.startedProcess === 'hideConnectorLines') return;

    if (this.connectorLines.startedProcess === 'showConnectorLines') {
      if (this.connectorLines.tween) {
        this.connectorLines.tween.stop();
        this.connectorLines.tween = null;
      }
    } else if (!this.connectorLines.showed) return;
    this.connectorLines.startedProcess = 'hideConnectorLines';

    // this.connectorLines.opacity = 1;

    const tw = new TWEEN.Tween(this.connectorLines);
    this.connectorLines.tween = tw;

    tw.to({ opacity: 0 }, 2000); // .easing(TWEEN.Easing.Linear.None)
    tw.onStart(() => {
      this.stopMovingArrows();
    });
    tw.onUpdate((object) => {
      let i = -1;
      const n = this.scene.connectorLinesIds.length;

      while (++i < n) {
        const id = this.scene.connectorLinesIds[i];
        const connectorLine = this.scene.connectorLinesByIds.get(id);
        connectorLine.model.material.opacity = object.opacity / 2;
      }
    });
    tw.onComplete(() => {
      // this.controls.enable();
      let i = -1;
      const n = this.scene.connectorLinesIds.length;

      while (++i < n) {
        const id = this.scene.connectorLinesIds[i];
        const connectorLine = this.scene.connectorLinesByIds.get(id);
        connectorLine.model.visible = false;
      }

      this.connectorLines.showed = false;
      this.connectorLines.movingArrows = false;
      this.connectorLines.tween = null;
      this.connectorLines.startedProcess = null;
    });
    tw.start();
  }

  startMovingArrows({ useDelay = false, delayTime = 1000 } = {}) {
    if (this.scene.splashes.currentIndex >= this.scene.splashes.chains.length - 1) {
      if (!this.scene.splashes.repeat) {
        this.scene.splashes.currentIndex = -1;
        return;
      }

      this.scene.splashes.currentIndex = 0;
    } else {
      this.scene.splashes.currentIndex++;
    }

    const splash = this.scene.splashes.chains[this.scene.splashes.currentIndex];

    splash.fraction = 0;

    const tw = new TWEEN.Tween(splash);
    this.connectorLines.tweenArrows = tw;

    tw.to({ fraction: 1 }, splash.time);
    tw.onStart(() => {
      const connector = this.scene.connectorLinesByIds.get(splash.connectorLineId);

      connector.model.material.color.setHex(splash.connectorLineColorAfterStart);
      connector.splash.model.visible = true;
    });
    tw.onUpdate((object) => {
      this.scene.movingArrowInConnectorLine(splash.connectorLineId, object.fraction);
    });
    tw.onComplete(() => {
      const connector = this.scene.connectorLinesByIds.get(splash.connectorLineId);
      connector.splash.model.visible = false;
      connector.model.material.color.setHex(splash.connectorLineColorAfterFinished);

      if (this.connectorLines.movingArrows) {
        this.startMovingArrows();
      }
    });

    if (useDelay) {
      tw.delay(delayTime);
    }

    tw.start();
  }

  stopMovingArrows() {
    this.connectorLines.movingArrows = false;

    if (this.connectorLines.tweenArrows) {
      this.connectorLines.tweenArrows.stop();
      this.connectorLines.tweenArrows = null;
    }

    let i = -1;
    const n = this.scene.connectorLinesIds.length;

    while (++i < n) {
      const id = this.scene.connectorLinesIds[i];
      const connectorLine = this.scene.connectorLinesByIds.get(id);
      connectorLine.splash.model.visible = false;
      connectorLine.model.material.color.setHex(this.scene.splashes.defaultLineColor);
    }
  }

  reset() {
    this.gTime = 0;

    this.selected = null;

    this.timeForRotation = { t: 0 };

    this.position = 0;

    this.fraction = 0;

    this.initedScene = false;

    this.animations = {};
    this.activeAnimationModelNames = new Map();
    this.runnedAnimations = false;

    this.gTime = 0;

    this.badgesHasOcclusions = false;
    this.previousShowedBadges = null;
    this.showedBadges = false;

    this.badges = {
      planningPosition: [Infinity, Infinity],
      drillblastPosition: [Infinity, Infinity],
      drillingMachinePosition: [Infinity, Infinity],
      surveyMonitoringPosition: [Infinity, Infinity],
      materialMovementPosition: [Infinity, Infinity],
      undergroundPosition: [Infinity, Infinity],
      safetyPosition: [Infinity, Infinity],
      showBadgeMaterialMovement: false,
      showBadgePlanning: false,
      showBadgeExploration: false,
      showBadgeSurveyMonitoring: false,
      showBadgeDrillBlast: false,
      showBadgeUnderground: false,
      showBadgeSafety: false,
      opacity: 0,
    };

    this.connectorLines = {
      opacity: 0,
      showed: false,
      movingArrows: false,
      scheduledProcess: null,
      startedProcess: null,
    };

    this.animationID = null;

    this.setState({
      state: null,
      progress: 0,
      showSidebar: false,
      currentLocation: null,
      activeSidebar: false,
      showSignUpForm: false,
    });
  }

  clear() {
    eventBus.remove('resetView');

    window.removeEventListener('pointermove', this.onPointerMove);
    window.removeEventListener('pointerdown', this.onPointerDown);

    if (this.animationID) {
      cancelAnimationFrame(this.animationID);
      this.animationID = null;
    }

    if (this.connectorLines.tween) {
      this.connectorLines.tween.stop();
      this.connectorLines.tween = null;
    }

    if (this.connectorLines.tweenArrows) {
      this.connectorLines.tweenArrows.stop();
      this.connectorLines.tweenArrows = null;
    }

    const tweens = TWEEN.getAll();

    for (let i = 0, n = tweens.length; i < n; i++) {
      tweens[i].stop();
    }

    TWEEN.removeAll();

    this.deactivateActiveAnimations();

    this.scene.clear();
    this.scene = null;

    this.renderer
      .getActiveRenderer()
      .getContext()
      .canvas.removeEventListener('webglcontextlost', this.webglcontextlost, false);
    this.renderer
      .getActiveRenderer()
      .getContext()
      .canvas.removeEventListener('webglcontextrestored', this.webglcontextlost, false);
    this.renderer.root.renderLists.dispose();
    this.renderer.root.dispose();
    this.renderer.root = null;

    this.renderer = null;
    this.camera = null;
    this.raycaster = null;
    this.controls.clear();
    this.controls.controls.dispose();
    this.controls = null;

    this.backgrounds = null;
    this.backgroundImage = null;

    this.materialMovementBadge = null;
    this.planningBadge = null;
    this.explorationBadge = null;
    this.surveyMonitoringBadge = null;
    this.drillBlastBadge = null;
    this.undergroundBadge = null;
    this.safetyBadge = null;

    this.routeName = '';

    this.clock = null;
    this.pointer = null;
    this.runnedMovingCameraToObject = null;
    this.selected = null;

    this.timeForRotation = null;

    this.position = null;

    this.fraction = null;

    this.initedScene = null;

    this.animations = null;
    this.activeAnimationModelNames = null;
    this.runnedAnimations = null;

    this.gTime = null;

    this.badgesHasOcclusions = null;
    this.previousShowedBadges = null;
    this.showedBadges = false;

    this.badges = null;

    this.connectorLines = null;
  }

  onClickCloseAuth = () => {
    this.setState({
      showSignUpForm: false,
    });
  };

  render() {
    const { config } = this.props;

    const {
      state,
      progress,
      showSidebar,
      activeSidebar,
      currentLocation,
      pointerSize,
      showGestureHint,
      showSignUpForm,
      showPowerCard,
    } = this.state;

    const { Auth } = auth.containers;

    return (
      <div className={ALLOW_EVENTS_CLASS}>
        <section className={`${config.className}__background scene`}>
          <canvas id="container" className={`${config.className}__background-image`} />
        </section>
        <PowerOfOneCard isFirstVisit={Cookies.get('mine_map') !== 'visited'} show={showPowerCard} />

        <PointerContainer
          key="pointerMaterialMovement"
          title="Material Movement"
          id="pointerMaterialMovement"
          forwardedRef={this.materialMovementBadge}
          clickHandler={this.clickOnMaterialMovement}
          position={{
            top: this.badges.materialMovementPosition[1],
            left: this.badges.materialMovementPosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgeMaterialMovement}
        />

        <PointerContainer
          key="pointerPlanning"
          title="Planning"
          id="pointerPlanning"
          forwardedRef={this.planningBadge}
          clickHandler={this.clickOnPlanning}
          position={{
            top: this.badges.planningPosition[1],
            left: this.badges.planningPosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgePlanning}
        />
        <PointerContainer
          key="pointerExploration"
          title="Exploration"
          id="pointerExploration"
          forwardedRef={this.explorationBadge}
          clickHandler={this.clickOnDrillingMachine}
          position={{
            top: this.badges.drillingMachinePosition[1],
            left: this.badges.drillingMachinePosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgeExploration}
        />

        <PointerContainer
          key="pointerSurveyMonitoring"
          title="Survey Monitoring"
          id="pointerSurveyMonitoring"
          forwardedRef={this.surveyMonitoringBadge}
          clickHandler={this.clickOnSurveyMonitoring}
          position={{
            top: this.badges.surveyMonitoringPosition[1],
            left: this.badges.surveyMonitoringPosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgeSurveyMonitoring}
        />

        <PointerContainer
          key="pointerDrillBlast"
          title="Drill Blast"
          id="pointerDrillBlast"
          forwardedRef={this.drillBlastBadge}
          clickHandler={this.clickOnDrillBlast}
          position={{
            top: this.badges.drillblastPosition[1],
            left: this.badges.drillblastPosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgeDrillBlast}
        />

        <PointerContainer
          key="pointerUnderground"
          title="Underground"
          id="pointerUnderground"
          forwardedRef={this.undergroundBadge}
          clickHandler={this.clickOnUnderground}
          position={{
            top: this.badges.undergroundPosition[1],
            left: this.badges.undergroundPosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgeUnderground}
        />

        <PointerContainer
          key="pointerSafety"
          title="Safety"
          id="pointerSafety"
          forwardedRef={this.safetyBadge}
          clickHandler={this.clickOnSafety}
          position={{
            top: this.badges.safetyPosition[1],
            left: this.badges.safetyPosition[0],
          }}
          size={{
            pointer: pointerSize,
          }}
          opacity={this.badges.opacity}
          visibility={this.badges.showBadgeSafety}
        />

        {state !== 'ready' ? (
          <div className="container-progressbar">
            <LinearProgressWittTips value={progress} />
          </div>
        ) : (
          ''
        )}
        <div
          className={`scene-badge-wrapper ${activeSidebar ? 'active' : ''} ${
            showSidebar ? 'visible' : ''
          }`}
        >
          <div className="scene-badge-button-container" onClick={this.clickOnCloseSidebar}>
            {showSidebar ? (
              <div className="scene-badge-button close-scene">
                <i className="arrow right" />
              </div>
            ) : (
              <div className="scene-badge-button open-scene">
                <i className="arrow left" />
              </div>
            )}
          </div>
          <div className={`scene-badge-container ${showSidebar ? 'visible' : ''}`}>
            {showSignUpForm ? (
              <Auth onClickCloseAuth={this.onClickCloseAuth} />
            ) : (
              <Sidebar
                currentLocation={currentLocation}
                onMoveToLocation={this.moveCameraToLocation}
                onGoIntoLocation={this.onGoIntoLocation}
              />
            )}
          </div>
        </div>

        {state === 'ready' && (
          <GestureHint showGestureHint={showGestureHint} />
        )}
      </div>
    );
  }
}

MineMap.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  size: PropTypes.shape({
    pointer: PropTypes.number,
  }),
  setReplaceJourney: PropTypes.func,
  setToJourney: PropTypes.func,
  setCanNavigate: PropTypes.func,
  onEvent: PropTypes.func,
  isUserLoggedIn: PropTypes.bool,
  visited: PropTypes.number,
};

MineMap.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  size: {
    pointer: 0,
  },
  setReplaceJourney: () => {},
  setToJourney: () => {},
  setCanNavigate: () => {},
  onEvent: () => {},
  isUserLoggedIn: false,
  visited: 0,
};

const config = {
  className: 'journey',
  delayPrimaries: 0.5,
  delaySecondaries: 0.25,
  staggerBreakdowns: 0.125,
  delayVideoBreakdown: 1.5,
};

const mapStateToProps = (state) => {
  const lomixConfig = selectors.getLomixConfig(state);
  const location = selectors.getLocation(state);

  if (!lomixConfig) {
    return {};
  }

  const generatedProps = {
    config,
    lomixConfig,
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
    journey: selectors.getJourney(state),
    location,
  };

  return generatedProps;
};

const mapDispatchToProps = (dispatch) => ({
  setToJourney: (payload) => dispatch(actions.setToJourney({ payload })),
  setReplaceJourney: (payload) => dispatch(actions.setReplaceJourney({ payload })),
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
  setLocationName: (payload) => dispatch(actions.setLocationName({ payload })),
  setJourney: (payload) => dispatch(actions.setJourney({ payload })),
  showInfo3D: (payload) => dispatch(actions.showInfo3D({ payload })),
});

export default withNavigate(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(MineMap),
);
