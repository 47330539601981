import Box from 'modules/common/components/Box';

export default class MaterialMovementDynamicData3 {
  constructor(position) {
    this.id = 'materialMovementDynamicData_3';
    this.component = Box;
    this.title =
      'material-movement.Material Movement Dynamic Data.elements.One Smart Digital Reality';
    this.content = '';
    this.video = 'power_of_one.mp4';
    this.props = {
      target: 'tb1',
      position,
      cornerFrom: ['top', 'left'],
      hashtags: ['cost', 'esg', 'productivity', 'dataAnalytics'],
    };
  }
}
