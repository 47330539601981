import Introduction from 'modules/underground-introduction/configs/configDefault';
import UndergroundSafety from 'modules/underground-safety/configs/configDefault';
import UndergroundTaskManagement from 'modules/underground-task-management/configs/configDefault';
import UndergroundDevelopmentDrilling from 'modules/underground-development-drilling/configs/configDefault';

const config = {
  id: 7,
  title: 'underground.Underground',
  slug: 'underground',
  pdfPath: '07Underground',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_6_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [UndergroundSafety, UndergroundTaskManagement, UndergroundDevelopmentDrilling],
};

export default config;
