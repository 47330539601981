import description from './description';
import MaterialMovementSchedulingOptimising1 from './elements/materialMovementSchedulingOptimising_1';
import MaterialMovementSchedulingOptimising2 from './elements/materialMovementSchedulingOptimising_2';
import MaterialMovementSchedulingOptimising3 from './elements/materialMovementSchedulingOptimising_3';
import MaterialMovementSchedulingOptimising4 from './elements/materialMovementSchedulingOptimising_4';
import MaterialMovementSchedulingOptimising5 from './elements/materialMovementSchedulingOptimising_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [18, 43.25], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 25.29], { direction: 'reverse' }],
    ['hs1', [0, 76.07]],
  ],
  tinyBois: [
    { id: 'tb1', position: [18, 43.25], offset: [5.7, 1.475] },
    { id: 'tb2', position: [18, 43.25], offset: [0.2, 4.45] },
    { id: 'tb3', position: [18, 43.25], offset: [5.7, 4.45] },
    { id: 'tb4', position: [18, 43.25], offset: [3, 6] },
  ],
  elements: [
    new MaterialMovementSchedulingOptimising1([11.7, 16.5]),
    new MaterialMovementSchedulingOptimising2([43.3, 42.8]),
    new MaterialMovementSchedulingOptimising3([11.7, 59.6]),
    new MaterialMovementSchedulingOptimising4([59.2, 56.9]),
    new MaterialMovementSchedulingOptimising5([35.41, 59.6]),
  ],
};

export default config;
