const description = {
  id: '5 of 7',
  currentNumber: 5,
  totalNumber: 7,
  title: 'drill-blast.Blast Movement.title',
  slug: 'blast-movement',
  pdfPath: '05BlastMovement',
  apiSlug: 'drill-blast-blast-movement',
  productDetailsLink: 'https://hexagon.com/products/product-groups/hxgn-minemeasure/monitor',
  description: 'drill-blast.Blast Movement.description',
  video: {
    name: 'path 3_location_5.mp4',
    stop: 10.5,
    backgroundImage: 'Path_3_location_5_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.01,
    dancingAnts: 11,
    secondaries: 11.5,
  },
};

export default description;
