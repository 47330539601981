import description from './description';
import ExplorationRockCharacterization1 from './elements/explorationRockCharacterization_1';
import ExplorationRockCharacterization2 from './elements/explorationRockCharacterization_2';
import ExplorationRockCharacterization3 from './elements/explorationRockCharacterization_3';
import ExplorationRockCharacterization4 from './elements/explorationRockCharacterization_4';
import ExplorationRockCharacterization5 from './elements/explorationRockCharacterization_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [14, 42.75], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 32.03], { direction: 'reverse' }],
    ['hs1', [0, 19.33], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [14, 42.75], offset: [0.1, 4.6] },
    { id: 'tb2', position: [14, 42.75], offset: [5.5, 4.6] },
    { id: 'tb3', position: [14, 42.75], offset: [2.9, 6.1] },
    { id: 'tb4', position: [14, 42.75], offset: [5.65, 1.2] },
  ],
  elements: [
    new ExplorationRockCharacterization1([11.6, 16.5], { size: 'wide' }),
    new ExplorationRockCharacterization2([11.6, 59.6]),
    new ExplorationRockCharacterization3([35.4, 46.8], { cornerFrom: ['bottom', 'left'] }),
    new ExplorationRockCharacterization4([35.4, 59.6]),
    new ExplorationRockCharacterization5([51.25, 32.12]),
  ],
};

export default config;
