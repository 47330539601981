const description = {
  id: 1,
  currentNumber: 1,
  title: 'home.Home',
  slug: 'introduction',
  description: 'home.description ',
  video: {
    name: 'path 0_entry.mp4',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  drawPoints: { hotspots: 0, dancingAnts: 0, secondaries: 0 },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
};

export default description;
