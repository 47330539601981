import description from './description';
import MaterialMovementDynamicData1 from './elements/materialMovementDynamicData_1';
import MaterialMovementDynamicData2 from './elements/materialMovementDynamicData_2';
import MaterialMovementDynamicData3 from './elements/materialMovementDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [55.1, 54.5], width: 6.375 }],
  dancingAnts: [],
  tinyBois: [
    { id: 'tb2', position: [55.1, 54.5], offset: [0.4, 4.8] },
    { id: 'tb1', position: [55.1, 54.5], offset: [5.6, 4.8] },
  ],
  elements: [
    new MaterialMovementDynamicData1([51, 24]),
    new MaterialMovementDynamicData2([43.2, 67.9]),
    new MaterialMovementDynamicData3([66.7, 56.3]),
  ],
};

export default config;
