const description = {
  id: '4 of 5',
  currentNumber: 4,
  totalNumber: 5,
  title: 'exploration.Rock Characterisation.title',
  slug: 'rock-characterisation',
  pdfPath: '04RockCharacterisation',
  apiSlug: 'exploration-rock-characterisation',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-split-fragmentation-analysis',
  description: 'exploration.Rock Characterisation.description',
  video: {
    name: 'path 1_location_4.mp4',
    stop: 3,
    backgroundImage: 'Path_1_location_4_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 4.5,
    secondaries: 5,
  },
};

export default description;
