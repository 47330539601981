import description from './description';
import SurveyMonitoringDynamicData1 from './elements/surveyMonitoringDynamicData_1';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [69.63, 68.53], width: 9.125, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [41.99, 0]],
    ['hs1', [51.75, 0], { direction: 'reverse' }],
    ['hs1', [61.62, 0]],
    ['hs1', [0, 69.33], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [new SurveyMonitoringDynamicData1([11.9, 22.6], { size: 'wide' })],
};

export default config;
