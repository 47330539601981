import StageCard from 'modules/common/components/StageCard';

const config = {
  id: 4,
  title: 'material-movement.Material Movement',
  slug: 'introduction',
  apiSlug: 'material-movement-introduction',
  description: 'material-movement.description',
  video: {
    backgroundImage: 'Path_4_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1,
    secondaries: 2,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'materialMovementIntroduction_1', component: StageCard }],
};

export default config;
