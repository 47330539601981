import Introduction from 'modules/safety-introduction/configs/configDefault';
import VehicleCollisionAvoidance from 'modules/safety-vehicle-collision-avoidance/configs/configDefault';
import OperatorAlertnessSystem from 'modules/safety-operator-alertness-system/configs/configDefault';
import PersonalAlert from 'modules/safety-personal-alert/configs/configDefault';
import SlopeMonitoring from 'modules/safety-slope-monitoring/configs/configDefault';
import DynamicData from 'modules/safety-dynamic-data/configs/configDefault';

const config = {
  id: 5,
  title: 'safety.Safety',
  slug: 'safety',
  pdfPath: '05Safety',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_4_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [
    VehicleCollisionAvoidance,
    OperatorAlertnessSystem,
    PersonalAlert,
    SlopeMonitoring,
    DynamicData,
  ],
};

export default config;
