import StageCard from 'modules/common/components/StageCard';

const description = {
  id: 1,
  currentNumber: 1,
  title: 'workflow.Mine Map',
  slug: 'introduction',
  hasMainNav: false,
  isInPaths: false,
  hidePath: true,
  video: {
    name: 'path 020_2D_Mine_Map_3-Step-Trans.mp4',
    stop: 3,
    backgroundImage: 'path 020_2D_Mine_Map.jpg',
    defaultBackgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  description: 'workflow.Mine Map description',
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 0.5,
    secondaries: 1,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'explorationIntroduction_1', component: StageCard }],
};

export default description;
