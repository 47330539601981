import description from './description';
import MaterialMovementFleetManagement1 from './elements/materialMovementFleetManagement_1';
import MaterialMovementFleetManagement2 from './elements/materialMovementFleetManagement_2';
import MaterialMovementFleetManagement3 from './elements/materialMovementFleetManagement_3';
import MaterialMovementFleetManagement4 from './elements/materialMovementFleetManagement_4';
import MaterialMovementFleetManagement5 from './elements/materialMovementFleetManagement_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16, 43.5], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 22.36]],
    ['hs1', [39.89, 100], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16, 43.5], offset: [5.7, 1.475] },
    { id: 'tb2', position: [16, 43.5], offset: [5.7, 4.45] },
    { id: 'tb3', position: [16, 43.5], offset: [0.2, 4.45] },
    { id: 'tb4', position: [16, 43.5], offset: [3, 6] },
  ],
  elements: [
    new MaterialMovementFleetManagement1([11.9, 15.6]),
    new MaterialMovementFleetManagement2([31.6, 35.8], { cornerFrom: ['top', 'left'] }),
    new MaterialMovementFleetManagement3([31.6, 46], { cornerFrom: ['top', 'left'] }),
    new MaterialMovementFleetManagement4([11.9, 60.3]),
    new MaterialMovementFleetManagement5([31.6, 56.2], { cornerFrom: ['top', 'left'] }),
  ],
};

export default config;
