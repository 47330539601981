import description from './description';
import MaterialMovementDynamicData1 from './elements/materialMovementDynamicData_1';
import MaterialMovementDynamicData2 from './elements/materialMovementDynamicData_2';
import MaterialMovementDynamicData3 from './elements/materialMovementDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [45.1, 46.9], width: 6.375 }],
  dancingAnts: [],
  tinyBois: [
    { id: 'tb2', position: [45.1, 46.9], offset: [0.4, 4.8] },
    { id: 'tb1', position: [45.1, 46.9], offset: [5.6, 4.8] },
  ],
  elements: [
    new MaterialMovementDynamicData1([59.2, 19], { size: 'wide' }),
    new MaterialMovementDynamicData2([28, 62.5]),
    new MaterialMovementDynamicData3([66.9, 61.1]),
  ],
};

export default config;
