import description from './description';
import ExplorationDynamicData1 from './elements/explorationDynamicData_1';
import ExplorationDynamicData2 from './elements/explorationDynamicData_2';
import ExplorationDynamicData3 from './elements/explorationDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [46.2, 75], width: 18.25, rotation: [67, 0] }],
  dancingAnts: [
    ['hs1', [50.57, 0]],
    ['hs1', [55, 0], { direction: 'reverse' }],
    ['hs1', [100, 4.78]],
    ['hs1', [100, 14.35], { direction: 'reverse' }],
  ],
  tinyBois: [],
  elements: [
    new ExplorationDynamicData1([19.8, 21]),
    new ExplorationDynamicData2([51, 23.5]),
    new ExplorationDynamicData3([58.9, 50.4]),
  ],
};

export default config;
