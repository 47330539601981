/* eslint-disable no-unused-vars */
import { fork, takeEvery, put, select, call } from 'redux-saga/effects';

import { SUCCESS, ERROR } from 'core/constants/statuses';

import actions from 'app/actions';
import {
  APP_PATHS_TOGGLE,
  APP_IS_STAGE_ANIMATING_TOGGLE,
  APP_MODAL_CONTENT_SET,
  APP_MODAL_OPEN_SET,
  APP_EMAIL_SEND,
  APP_TABLET_ORIENTATION_SHOW,
  APP_INIT,
  APP_PRIVACY_POLICY_ACCEPT,
  APP_WORKFLOWS_OVERVIEW_2D_VISIT,
  APP_INFO_3D_SHOW,
  APP_SITEMAP_OPEN_SET,
  APP_PWA_CONFIRM_DELETE_SHOW,
  APP_PWA_CONFIRM_DELETE_PROGRESS_SET,
  APP_PWA_MODAL_SHOW,
  APP_JOURNEY_TO_ASSOCIATE_SET,
  APP_JOURNEY_SHARE,
  APP_WORKFLOWS_OVERVIEW_VISIT,
} from 'app/constants';

import { getFromLS, saveToLS, removeFromLS } from 'core/services/localStorage';

import Cookies from 'js-cookie';

import {
  getVisitedWorkflowsOverview2D,
  getJourneyToAssociate,
  getVisitedWorkflowsOverview,
} from 'app/selectors';

import rootJourneysManager from 'modules/journeys-manager/sagas';
import rootJourneysManagerList from 'modules/journeys-manager-introduction/sagas';
import rootJourneysManagerJourney from 'modules/journeys-manager-journey/sagas';

import rootLead from 'modules/lead/sagas';

import configAPP from 'app/configs';

import api from 'app/api';

export default function* root() {
  yield fork(watchInit);
  yield fork(watchTogglePaths);
  yield fork(watchToggleIsStageAnimating);
  yield fork(watchSetModalContent);
  yield fork(watchSetIsModalOpen);
  yield fork(watchSendEmail);
  yield fork(watchShowTabletOrientation);
  yield fork(watchAcceptPrivacyPolicy);
  yield fork(watchVisitWorkflowsOverview);
  yield fork(watchVisitWorkflowsOverview2D);
  yield fork(watchShowInfo3D);
  yield fork(watchSetSiteMapOpen);
  yield fork(rootJourneysManager);
  yield fork(rootJourneysManagerList);
  yield fork(rootJourneysManagerJourney);
  yield fork(watchShowPwaModal);
  yield fork(watchShowPwaConfirmDeleteModal);
  yield fork(watchSetPwaConfirmDeleteProgress);
  yield fork(rootLead);
  yield fork(watchSetJourneyToAssociate);
  yield fork(watchShareJourney);
}

function* init() {
  const payload = {
    tablet: {
      wasShowedPortraitOrientation: getFromLS('tablet:orientation:portrait:showed') || false,
    },
    acceptedPrivacyPolicy: Cookies.get('privacy_policy') === 'accepted',
    visitedWorkflowsOverview: getFromLS('workflows-overview:visited') || 0,
    visitedWorkflowsOverview2D: getFromLS('workflows-overview-2d:visited') || 0,
    pwaLoaded: getFromLS('pwa') === 'pwa' || configAPP.enableExhibition,
  };

  const initedDateTime = Date.now();

  const meta = {
    receivedAt: initedDateTime,
    updatedAt: initedDateTime,
  };

  yield put(actions.init({ status: SUCCESS, payload, meta }));
}

function* togglePaths(params = { payload: {} }) {
  const { isOpen = null } = params.payload;

  const payload = {
    isOpen,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.togglePaths({ status: SUCCESS, payload, meta }));
}

function* toggleIsStageAnimating(params = { payload: {} }) {
  const { isStageAnimating = null } = params.payload;

  const payload = {
    isStageAnimating,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.toggleIsStageAnimating({ status: SUCCESS, payload, meta }));
}

function* setModalContent(params = { payload: {} }) {
  const { content } = params.payload;

  const payload = {
    content,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.setModalContent({ status: SUCCESS, payload, meta }));
}

function* setModalOpen(params = { payload: {} }) {
  const { isOpen } = params.payload;

  const payload = {
    isOpen,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.setIsModalOpen({ status: SUCCESS, payload, meta }));
}

function* setSiteMapOpen(params = { payload: {} }) {
  const { isOpen } = params.payload;

  const payload = {
    isOpen,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.setSiteMapOpen({ status: SUCCESS, payload, meta }));
}

function* sendEmail(params = { payload: {} }) {
  const {
    name,
    email,
    phoneNumber,
    companeName,
    message,
    generalOverview,
    wantSolution,
    haveChallange,
    time,
  } = params.payload;

  const payload = {};

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.sendEmail({ status: SUCCESS, payload, meta }));
}

function* showTabletOrientation(params = { payload: {} }) {
  const { orientation } = params.payload;

  const wasShowedPortraitOrientation = saveToLS(
    'tablet:orientation:portrait:showed',
    orientation === 'portrait',
  );

  if (wasShowedPortraitOrientation !== null) {
    const payload = {
      wasShowedPortraitOrientation,
    };

    const meta = {
      receivedAt: new Date(),
    };

    yield put(actions.showTabletOrientation({ status: SUCCESS, payload, meta }));
  }
}

function* acceptPrivacyPolicy() {
  Cookies.set('privacy_policy', 'accepted', { expires: 2 * 365 });

  const payload = {
    acceptedPrivacyPolicy: true,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.acceptPrivacyPolicy({ status: SUCCESS, payload, meta }));
}

function* visitWorkflowsOverview() {
  let visitedWorkflowsOverview = yield select(getVisitedWorkflowsOverview);

  visitedWorkflowsOverview += 1;

  saveToLS('workflows-overview:visited', visitedWorkflowsOverview);

  const payload = {
    visitedWorkflowsOverview,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.visitWorkflowsOverview({ status: SUCCESS, payload, meta }));
}

function* visitWorkflowsOverview2D() {
  let visitedWorkflowsOverview2D = yield select(getVisitedWorkflowsOverview2D);

  visitedWorkflowsOverview2D += 1;

  saveToLS('workflows-overview-2d:visited', visitedWorkflowsOverview2D);

  const payload = {
    visitedWorkflowsOverview2D,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.visitWorkflowsOverview2D({ status: SUCCESS, payload, meta }));
}

function* showInfo3D(params = { payload: {} }) {
  const { show, error3D } = params.payload;

  const payload = {
    show,
    error3D,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.showInfo3D({ status: SUCCESS, payload, meta }));
}

function* showPwaModal(params = { payload: {} }) {
  const { show } = params.payload;

  const payload = {
    show,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.showPwaModal({ status: SUCCESS, payload, meta }));
}

function* showPwaConfirmDeleteModal(params = { payload: {} }) {
  const { show } = params.payload;

  const payload = {
    show,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.showPwaConfirmDeleteModal({ status: SUCCESS, payload, meta }));
}

function* setPwaConfirmDeleteProgress(params = { payload: {} }) {
  const { value, status } = params.payload;

  const payload = {
    value,
  };

  const meta = {
    receivedAt: new Date(),
  };

  if (status === ERROR) {
    yield put(actions.setPwaConfirmDeleteProgress({ status: ERROR, payload, meta }));
    return;
  }

  yield put(actions.setPwaConfirmDeleteProgress({ status: SUCCESS, payload, meta }));
}

function* setJourneyToAssociate(params = { payload: {} }) {
  const { id } = params.payload;

  saveToLS('journeyToAssociate', id);

  const payload = {
    id,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.setJourneyToAssociate({ status: SUCCESS, payload, meta }));
}

function* shareJourney(params = { payload: {} }) {
  const id = yield select(getJourneyToAssociate);
  const { emails } = params.payload;

  const result = yield call(api.shareJourney, {
    id,
    emails,
  });

  if (!result) {
    return;
  }

  removeFromLS('journeyToAssociate');

  const payload = {
    id: null,
  };

  const meta = {
    receivedAt: new Date(),
  };

  yield put(actions.setJourneyToAssociate({ status: SUCCESS, payload, meta }));
}

function* watchInit() {
  yield takeEvery(APP_INIT, init);
}

function* watchTogglePaths() {
  yield takeEvery(APP_PATHS_TOGGLE, togglePaths);
}

function* watchToggleIsStageAnimating() {
  yield takeEvery(APP_IS_STAGE_ANIMATING_TOGGLE, toggleIsStageAnimating);
}

function* watchSetModalContent() {
  yield takeEvery(APP_MODAL_CONTENT_SET, setModalContent);
}

function* watchSetIsModalOpen() {
  yield takeEvery(APP_MODAL_OPEN_SET, setModalOpen);
}

function* watchSetSiteMapOpen() {
  yield takeEvery(APP_SITEMAP_OPEN_SET, setSiteMapOpen);
}

function* watchSendEmail() {
  yield takeEvery(APP_EMAIL_SEND, sendEmail);
}

function* watchShowTabletOrientation() {
  yield takeEvery(APP_TABLET_ORIENTATION_SHOW, showTabletOrientation);
}

function* watchAcceptPrivacyPolicy() {
  yield takeEvery(APP_PRIVACY_POLICY_ACCEPT, acceptPrivacyPolicy);
}

function* watchVisitWorkflowsOverview() {
  yield takeEvery(APP_WORKFLOWS_OVERVIEW_VISIT, visitWorkflowsOverview);
}

function* watchVisitWorkflowsOverview2D() {
  yield takeEvery(APP_WORKFLOWS_OVERVIEW_2D_VISIT, visitWorkflowsOverview2D);
}

function* watchShowInfo3D() {
  yield takeEvery(APP_INFO_3D_SHOW, showInfo3D);
}

function* watchShowPwaModal() {
  yield takeEvery(APP_PWA_MODAL_SHOW, showPwaModal);
}

function* watchShowPwaConfirmDeleteModal() {
  yield takeEvery(APP_PWA_CONFIRM_DELETE_SHOW, showPwaConfirmDeleteModal);
}

function* watchSetPwaConfirmDeleteProgress() {
  yield takeEvery(APP_PWA_CONFIRM_DELETE_PROGRESS_SET, setPwaConfirmDeleteProgress);
}

function* watchSetJourneyToAssociate() {
  yield takeEvery(APP_JOURNEY_TO_ASSOCIATE_SET, setJourneyToAssociate);
}

function* watchShareJourney() {
  yield takeEvery(APP_JOURNEY_SHARE, shareJourney);
}
