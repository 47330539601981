// @ts-nocheck
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useCallback } from 'react';

import { useNavigate, useParams, useLocation } from 'react-router-dom';

import getConfig from 'getLOMIXConfig';

import analytics from 'modules/analytics';
import { updateDocTitle } from 'core/utils';

import i18n from 'i18nConfig';

export default function withNavigate(Component) {
  function WithNavigate(props) {
    const { googleAnalytics } = analytics.services;

    const navigate = useNavigate();
    const params = useParams();
    const routeLocation = useLocation();

    useEffect(() => {
      /* const path =
        routeLocation.pathname === '/' ? '/start-your-experience' : routeLocation.pathname; */

      const config = getConfig();

      if (config) {
        let { journeyId } = params;

        if (!journeyId && routeLocation.pathname === '/workflows-overview') {
          journeyId = 'workflows-overview';
        }

        const journey = config.find((j) => j.slug === journeyId) || config[0];

        const location =
          journey.locations.find((l) => l.slug === params.locationId) || journey.locations[0];

        updateDocTitle(journey, location);
      }

      // googleAnalytics.trackPage(path);
    }, [routeLocation, params, getConfig, googleAnalytics, updateDocTitle]);

    const navigateWithLanguage = useCallback((path, options) => {
      if (!path) return;

      const language = i18n.language === 'en' ? '' : i18n.language;

      let formattedPath = path.trim();

      formattedPath = formattedPath.startsWith('/') ? `${language}${path}` : `${language}/${path}`;

      navigate(formattedPath, options);
    }, []);

    return (
      <Component
        {...props}
        navigate={navigateWithLanguage}
        params={params}
        routeLocation={routeLocation}
      />
    );
  }

  const displayName = Component.displayName || Component.name || 'Component';

  WithNavigate.displayName = `${WithNavigate}(${displayName})`;

  return WithNavigate;
}
