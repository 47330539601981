const description = {
  id: '1 of 5',
  currentNumber: 1,
  totalNumber: 5,
  title: 'exploration.Drillhole Design.title',
  slug: 'drillhole-design',
  pdfPath: '01DrillholeDesign',
  apiSlug: 'exploration-planning',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-exploration-geo',
  description: 'exploration.Drillhole Design.description',
  video: {
    name: 'path 1_location_1.mp4',
    stop: 3.225,
    backgroundImage: 'Path_1_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
};

export default description;
