const description = {
  id: '1 of 3',
  currentNumber: 1,
  totalNumber: 3,
  title: 'underground.Safety.title',
  slug: 'underground-safety',
  pdfPath: '01Safety',
  apiSlug: 'underground-safety',
  productDetailsLink: 'https://hxgnspotlight.com/shedding-new-light-underground/',
  description: 'underground.Safety.description',
  video: {
    name: 'path 6_location_1.mp4',
    stop: 29.25,
    backgroundImage: 'Path_6_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 29.25,
    dancingAnts: 29.25,
    secondaries: 29.25,
  },
};

export default description;
