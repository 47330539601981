/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from 'app/selectors';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';
import { useTranslation } from 'react-i18next';
import HashtagModal from './HashtagModal';
import { updateInterests, userForm } from '../../authService';
import HashtagWithDescriptionList from './HashtagWithDescriptionList';
import HashtagList from './HashtagList';

const EditHashtags = ({ loggedUser, isOpen, onClose }) => {
  const [withDescription, setWithDescription] = React.useState(false);
  const [interests, setInterests] = useState(userForm.interest);
  const [updatingInterests, setUpdatingInterests] = useState(userForm.interest);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const userInterestData = { ...userForm.interest, ...loggedUser?.interest };
    setInterests(userInterestData);
    setUpdatingInterests(userInterestData);
  }, [loggedUser]);

  useEffect(() => {
    if (
      updatingInterests &&
      Object.keys(updatingInterests).some((k) => updatingInterests[k] === true)
    ) {
      setIsSaveButtonDisabled(false);
    } else {
      setIsSaveButtonDisabled(true);
    }
  }, [updatingInterests, setIsSaveButtonDisabled]);

  const saveChanges = async () => {
    await updateInterests(updatingInterests);
    setWithDescription(false);

    const userInterest = await Object.keys(updatingInterests).filter(
      (key) => updatingInterests[key] === true,
    );
    const userInterestString = userInterest.toString();

    sendGTEvent({ event: 'hashtag_selected', hashtags: userInterestString });

    onClose();
  };

  const handleCloseModal = () => {
    setUpdatingInterests(interests);
    setWithDescription(false);
    onClose();
  };

  const handleCheckboxChange = (inputName, value) => {
    const updatedInterest = { [inputName]: value };

    setUpdatingInterests((newInterests) => ({ ...newInterests, ...updatedInterest }));
  };

  const toggleWithDescriptions = (val) => {
    setWithDescription(val);
  };

  return (
    <HashtagModal
      isOpen={isOpen}
      handleClose={handleCloseModal}
      saveChanges={saveChanges}
      isSaveButtonDisabled={isSaveButtonDisabled}
    >
      <div className="auth-title">{t('auth.Information priority')}</div>
      {withDescription ? (
        <HashtagWithDescriptionList
          values={updatingInterests}
          handleChange={handleCheckboxChange}
          toggleWithDescriptions={toggleWithDescriptions}
        />
      ) : (
        <HashtagList
          key="modal-list"
          values={updatingInterests}
          handleChange={handleCheckboxChange}
          toggleWithDescriptions={toggleWithDescriptions}
        />
      )}
    </HashtagModal>
  );
};

EditHashtags.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  loggedUser: PropTypes.object,
};

EditHashtags.defaultProps = {
  isOpen: false,
  onClose: () => {},
  loggedUser: null,
};

const mapStateToProps = (state) => {
  return { loggedUser: selectors.getLoggedUser(state) };
};

export default connect(mapStateToProps)(EditHashtags);
