import Box from 'modules/common/components/Box';

export default class MaterialMovementOperatorAlertness4 {
  constructor(position, props = {}) {
    this.id = 'materialMovementOperatorAlertness_4';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Operator Alertness System.elements.Remote monitoring';
    this.content = 'safety.Operator Alertness System.elements.Remote monitoring Content';
    this.props = {
      target: props.target || null,
      position,
      hashtags: ['healthSafety'],
    };
  }
}
