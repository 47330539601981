// @ts-nocheck
import React from 'react';

import SiteMapIcon from './assets/icons/site-map.svg';

// eslint-disable-next-line react/prop-types
const ButtonSiteMapApp = ({ onClick }) => {
  const handleClick = () => {
    onClick({ action: 'openSiteMap' });
  };

  return (
    <div className="c-download-app">
      {/* eslint-disable-next-line react/button-has-type */}
      <button className="c-button c-button--size-default" onClick={handleClick}>
        <div className="c-button__icon">
          <SiteMapIcon style={{ width: '1.25rem', height: '1.375rem' }} />
        </div>
      </button>
    </div>
  );
};

export default ButtonSiteMapApp;
