import StageCard from 'modules/common/components/StageCard';

const description = {
  id: 1,
  currentNumber: 1,
  title: 'exploration.Exploration',
  slug: 'introduction',
  apiSlug: 'exploration-introduction',
  description: 'exploration.description',
  video: {
    backgroundImage: 'Path_1_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 0.5,
    secondaries: 1,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'explorationIntroduction_1', component: StageCard }],
};

export default description;
