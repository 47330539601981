/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment,react/prop-types,react/no-unused-state,react/forbid-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import eventBus from 'EventBus';
import * as selectors from 'app/selectors';
import * as auth from 'modules/auth';

import AnimationManager from 'modules/common/services/AnimationManager';

import Stage from 'modules/common/components/Stage';

import VideoBackground from 'modules/common/components/VideoBackground';

import './assets/scss/index.scss';
import EditHashtags from 'modules/auth/components/Hashtags/EditHashtags';
// import { withTranslation } from 'react-i18next';
import ProductDetailPanel from '../components/ProductDetailPanel';

class Location extends PureComponent {
  constructor(props) {
    super(props);
    this.stage = React.createRef();

    this.backgroundImage = React.createRef();

    this.startAnimOnlyOne = true;
    this.isAnimationEnded = false;

    this.playingVideo = false;
    this.endedVideo = false;
    this.loadedVideo = !this.existVideo();

    this.locationReady = false;

    this.state = {
      video: {
        play: false,
        canPause: true,
        loaded: this.loadedVideo,
      },
      decreaseVolume: false,
      canShowHashtagsModal: false,
    };

    this.rollbackedAnimation = false;

    this.animationManager = new AnimationManager({
      listener: this,
    });

    this.action = null;
  }

  componentDidMount() {
    if (this.loadedVideo) {
      setTimeout(() => {
        this.videoLoaded(true);
      }, 0);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.requestDestroy !== this.props.requestDestroy && this.props.requestDestroy) {
      this.resume();
    }
  }

  componentWillUnmount() {
    this.killAnimation();
  }

  onUpdate({ action, data = {} } = {}) {
    switch (action) {
      case 'toggleAnimationEnded': {
        this.toggleAnimationEnded(data.isEnded);
        break;
      }
      case 'toggleIsStageAnimating': {
        this.toggleIsStageAnimating(data.isAnimating);
        break;
      }
      case 'startVideo': {
        if (this.canPlayVideo()) {
          this.toggleVideoPlaying(true);
        }
        break;
      }
      case 'breakdownVideo': {
        this.animPaused = true;
        this.setState({ canShowHashtagsModal: true });
        this.checkLocationReady();
        break;
      }
      case 'startVideoEnd': {
        this.props.onEvent({
          action: 'startVideoEnd',
          data: {
            ...data,
            action: this.action,
          },
        });

        if (this.props.locationName) {
          this.animPaused = false;
          return this.toggleVideoPlaying(true);
        }
        break;
      }
      case 'rollbackAnimation': {
        this.rollbackAnimation();
        break;
      }
      case 'cancelResumeFromCard': {
        this.props.onEvent({ action: 'cancelResumeFromCard' });

        eventBus.dispatch('cancelContinueFromCard');

        this.startAnimOnlyOne = false;

        this.videoLoaded(false);
        break;
      }
      default: {
        //
      }
    }
  }

  checkReady = () => {
    const { enabledTransition, config } = this.props;

    if (this.startAnimOnlyOne && this.loadedVideo && !enabledTransition) {
      this.startAnimOnlyOne = false;

      if (this.animationManager) {
        this.animationManager.init(config, this.props.drawPoints, this.stage?.current);
        this.animate();
      }
    }
  };

  checkLocationReady() {
    if (this.playingVideo || !this.animPaused || this.locationReady) return;

    this.locationReady = true;

    this.toggleIsStageAnimating(false);
    this.props.onEvent({ action: 'locationReady' });
  }

  videoStopped = (isPlaying, canPause) => {
    this.toggleVideoPlaying(isPlaying, canPause);
    this.checkLocationReady();
  };

  toggleVideoPlaying = (isPlaying, canPause) => {
    let { video } = this.state;

    video = {
      ...video,
      play: isPlaying,
    };

    if (canPause !== undefined) {
      video = {
        ...video,
        canPause,
      };
    }

    this.playingVideo = isPlaying;

    this.setState({ video });
  };

  toggleVideoEnded = (isEnded) => {
    this.endedVideo = isEnded;

    if (isEnded) {
      this.props.onEvent({ action: 'video:end' });
      this.checkAnimationEnded();
    }
  };

  videoLoaded = (value = true) => {
    this.loadedVideo = value;
    this.checkReady();

    let { video } = this.state;

    video = {
      ...video,
      loaded: true,
    };

    this.setState({ video });
  };

  toggleAnimationEnded = (isEnded) => {
    this.isAnimationEnded = isEnded;

    if (isEnded) {
      this.checkAnimationEnded();
    }
  };

  checkAnimationEnded = () => {
    if (this.endedVideo && this.isAnimationEnded) {
      this.isAnimationEnded = false;
      this.endedVideo = false;

      this.props.onEvent({ action: 'animation:end' });
    }
  };

  toggleIsStageAnimating = (isAnimating) => {
    this.props.onEvent({
      action: 'toggleIsStageAnimating',
      data: { isStageAnimating: isAnimating },
    });
  };

  rollbackAnimation = () => {
    this.rollbackedAnimation = true;

    this.props.onEvent({ action: 'rollbackedAnimationEnd' });
  };

  animate() {
    this.setState({
      decreaseVolume: false,
    });

    this.animationManager.play();

    return this;
  }

  killAnimation() {
    this.animationManager.kill();
    this.animationManager = null;
  }

  resume = () => {
    this.setState({
      decreaseVolume: true,
    });

    this.rollbackedAnimation = false;
    this.animationManager.resume();

    return this;
  };

  resumeFromCard = () => {
    // const { /* location, */ onEvent } = this.props;

    // onEvent('resumeFromCard');

    this.animationManager.resume();

    /* this.animationManager.addEventCallback('onComplete', 'setLocationName', {
      slug: location.slug,
    }); */

    return this;
  };

  cancelResumeFromCard = () => {
    this.animationManager.resume();

    // this.animationManager.addEventCallback('onComplete', 'cancelResumeFromCard');

    return this;
  };

  handleEvent = ({ action }) => {
    this.action = action;

    switch (action) {
      case 'resume':
        this.resume();
        break;
      case 'resumeFromCard':
        this.resumeFromCard();
        break;
      case 'cancelResumeFromCard':
        this.cancelResumeFromCard();
        break;
      default: {
        // console.log('handleEvent:action', action);
      }
    }
  };

  canPlayVideo() {
    return this.existVideo();
  }

  existVideo() {
    return !!this.props.location?.video.name;
  }

  showEditHashtags = () => {
    const { isUserLoggedIn, isInterests, routeName } = this.props;
    const { canShowHashtagsModal } = this.state;
    return canShowHashtagsModal && isUserLoggedIn && !isInterests && routeName !== 'home';
  };

  render() {
    const {
      location, // TEMP. ONLY FOR TEST
      locationName, // TEMP. ONLY FOR TEST
      journey, // TEMP. ONLY FOR TEST
      backgroundImage,
      video,
      playDefaultAudio,
      muted,
      enabledTransition,
      disableDefaultAudio,
      isMsalUser,
      isUserLoggedIn,
      routeName,
      deferredClickElement,
    } = this.props;

    const { AuthModal } = auth.containers;

    const { decreaseVolume, video: videoSettings } = this.state;

    return (
      <>
        {!isMsalUser && this.showEditHashtags() && <EditHashtags isOpen />}
        {deferredClickElement && !isUserLoggedIn && routeName !== 'home' && <AuthModal />}

        <VideoBackground
          backgroundImage={backgroundImage}
          video={video}
          videoSettings={videoSettings}
          videoStopped={this.videoStopped}
          videoEnded={this.toggleVideoEnded}
          videoLoaded={this.videoLoaded}
          playDefaultAudio={playDefaultAudio}
          muted={muted}
          decreaseVolume={decreaseVolume}
          enabledTransition={enabledTransition}
          disableDefaultAudio={disableDefaultAudio}
        />

        <Stage
          ref={this.stage}
          onEvent={this.handleEvent}
          journey={journey}
          location={location}
          locationName={locationName}
        />

        <ProductDetailPanel location={location} journey={journey} />
      </>
    );
  }
}

Location.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  enabledTransition: PropTypes.bool,
  playDefaultAudio: PropTypes.bool,
  muted: PropTypes.bool,
  location: PropTypes.object,
  requestDestroy: PropTypes.bool,
  disableDefaultAudio: PropTypes.bool,
};

Location.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  enabledTransition: false,
  playDefaultAudio: false,
  muted: true,
  location: {},
  requestDestroy: false,
  disableDefaultAudio: false,
};

const mapStateToProps = (state, props) => {
  const config = {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  };

  const { location } = props;
  const loggedUser = selectors.getLoggedUser(state);
  const interests = loggedUser?.interest || {};

  const playDefaultAudio = !!location?.video?.playDefaultSound;

  return {
    config,
    playDefaultAudio,
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
    loggedUser,
    isInterests: Object.keys(interests).some((k) => interests[k] === true) ?? false,
    isMsalUser: !!loggedUser?.idTokenClaims || false,
    deferredClickElement: selectors.getDeferredClickElement(state),
  };
};

// export default withTranslation()(
//   connect(mapStateToProps, null, null, { forwardRef: true })(Location),
// );

// export default connect(mapStateToProps, null, null, { forwardRef: true })(
//   withTranslation()(Location),
// );

export default connect(mapStateToProps, null, null, { forwardRef: true })(Location);
