import Introduction from 'modules/exploration-introduction/configs/configDefault';
import DrillholeDesign from 'modules/exploration-drillhole-design/configs/configDefault';
import Drilling from 'modules/exploration-drilling/configs/configDefault';
import ResourceModel from 'modules/exploration-resource-model/configs/configDefault';
import RockCharacterization from 'modules/exploration-rock-characterisation/configs/configDefault';
import DynamicData from 'modules/exploration-dynamic-data/configs/configDefault';

const config = {
  id: 1,
  title: 'Exploration',
  slug: 'exploration',
  pdfPath: '01Exploration',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_1_location_1_frame.jpg',
  },
  info: Introduction,
  locations: [DrillholeDesign, Drilling, ResourceModel, RockCharacterization, DynamicData],
};

export default config;
