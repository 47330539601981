import Box from 'modules/common/components/Box';

export default class MaterialMovementSchedulingOptimising3 {
  constructor(position) {
    this.id = 'materialMovementSchedulingOptimising_3';
    this.component = Box;
    this.title = 'material-movement.Scheduling Optimising.elements.Activity Scheduler';
    this.img = '';
    this.video = '4a-1.mp4';
    this.props = {
      target: 'tb2',
      position,
      hashtags: ['cost', 'productivity'],
    };
  }
}
