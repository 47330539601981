const config = {
  id: '1 of 5',
  currentNumber: 1,
  totalNumber: 5,
  title: 'workflow.Mine Map',
  slug: 'mine-map',
  hasMainNav: true,
  isInPaths: true,
  hidePath: false,
  description: 'workflow.Mine Map description1',
  video: {
    backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 4.5,
    secondaries: 5,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [],
  pointers: [
    {
      id: 'exploration',
      label: {
        number: '01',
        title: 'exploration.Exploration',
      },
      position: [728, 155],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 92,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 92,
        },
        rect: {
          x: 0.433594,
          y: 0.12146,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 19.9329,
            y: 22.5428,
            r: 7.25392,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 19.9308,
            y: 22.5431,
            r: 2.63779,
          },
        },
        line: {
          y1: 29.1372,
          d: 'M19.2734 29.1372H20.5923V91.7847H19.2734V29.1372Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 40,
        height: 92,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 92,
        },
        rect: {
          x: 0.433594,
          y: 0.12146,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 20.2172,
            y: 19.9052,
            r: 9.89171,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 20.2159,
            y: 19.9049,
            r: 2.63779,
          },
        },
        line: {
          y1: 29.1372,
          d: 'M19.2734 29.1372H20.5923V91.7847H19.2734V29.1372Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Exploration_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Exploration-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Exploration_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'planning',
      label: {
        number: '02',
        title: 'planning.Planning',
      },
      position: [586, 176],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 132,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 132,
        },
        rect: {
          x: 0.773438,
          y: 0.339844,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 19.9329,
            y: 22.761,
            r: 7.25392,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 19.9308,
            y: 22.7608,
            r: 2.63779,
          },
        },
        line: {
          x1: 19.9327,
          y1: 29.3555,
          x2: 19.9327,
          y2: 131.57,
          d: 'M19.2734 29.3555H20.5923V131.57H19.2734V29.3555Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 41,
        height: 132,
        scale: 0.00833333,
        viewBox: {
          x: 41,
          y: 132,
        },
        rect: {
          x: 0.773438,
          y: 0.339844,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 20.557,
            y: 20.1229,
            r: 9.89171,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 20.5577,
            y: 20.1231,
            r: 2.63779,
          },
        },
        line: {
          x1: 20.5577,
          y1: 29.3555,
          x2: 20.5577,
          y2: 131.57,
          d: 'M19.8984 29.3555H21.2173V131.57H19.8984V29.3555Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Planning_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Planning-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Planning_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'drill-blast',
      label: {
        number: '03',
        title: 'drill-blast.Drill Blast',
      },
      position: [554, 219],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 106,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 106,
        },
        rect: {
          x: 0.773438,
          y: 0.275879,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 19.9329,
            y: 22.6975,
            r: 7.25392,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 19.9308,
            y: 22.6974,
            r: 2.63779,
          },
        },
        line: {
          x1: 19.9327,
          y1: 42.255,
          x2: 19.9327,
          y2: 105.128,
          d: 'M19.2734 29.2915H20.5923V105.128H19.2734V29.2915Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 41,
        height: 106,
        scale: 0.00833333,
        viewBox: {
          x: 41,
          y: 106,
        },
        rect: {
          x: 0.773438,
          y: 0.275879,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 20.557,
            y: 20.0595,
            r: 9.89171,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 20.5577,
            y: 20.0592,
            r: 2.63779,
          },
        },
        line: {
          x1: 20.5577,
          y1: 42.255,
          x2: 20.5577,
          y2: 105.128,
          d: 'M19.8984 29.2915H21.2173V105.128H19.8984V29.2915Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Drill_and_Blast_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Drill_and_Blast-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Drill_and_Blast_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'material-movement',
      label: {
        number: '04',
        title: 'material-movement.Material Movement',
      },
      position: [625, 252],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 73,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 73,
        },
        rect: {
          x: 0.773438,
          y: 0.494141,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 19.9329,
            y: 22.9153,
            r: 7.25392,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 19.9308,
            y: 22.9151,
            r: 2.63779,
          },
        },
        line: {
          x1: 19.9327,
          y1: 29.5098,
          x2: 19.9327,
          y2: 72.3738,
          d: 'M19.2734 29.5098H20.5923V72.3738H19.2734V29.5098Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 41,
        height: 73,
        scale: 0.00833333,
        viewBox: {
          x: 41,
          y: 73,
        },
        rect: {
          x: 0.773438,
          y: 0.494141,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 20.557,
            y: 20.2772,
            r: 9.89171,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 20.5577,
            y: 20.2774,
            r: 2.63779,
          },
        },
        line: {
          x1: 20.5577,
          y1: 29.5098,
          x2: 20.5577,
          y2: 72.3738,
          d: 'M19.8984 29.5098H21.2173V72.3738H19.8984V29.5098Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Material_Movement_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Material_Movement-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Material_Movement_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'safety',
      label: {
        number: '05',
        title: 'safety.Safety',
      },
      position: [573, 275],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 59,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 59,
        },
        rect: {
          x: 0.833984,
          y: 0.412354,
          width: 38.3333,
          height: 38.3333,
        },
        circle: {
          outer: {
            x: 20.0007,
            y: 22.1347,
            r: 7.02778,
            strokeWidth: 1.27778,
          },
          inner: {
            x: 19.9999,
            y: 22.1347,
            r: 2.55556,
          },
        },
        line: {
          x1: 20.0001,
          y1: 31.8261,
          x2: 20.0001,
          y2: 57.9126,
          d: 'M19.3613 29.1626H20.6391V57.9126H19.3613V29.1626Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 40,
        height: 59,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 59,
        },
        rect: {
          x: 0.833984,
          y: 0.412354,
          width: 38.3333,
          height: 38.3333,
        },
        circle: {
          outer: {
            x: 20.0005,
            y: 19.5792,
            r: 9.58333,
            strokeWidth: 1.27778,
          },
          inner: {
            x: 19.9999,
            y: 19.5795,
            r: 2.55556,
          },
        },
        line: {
          x1: 20.0001,
          y1: 31.8261,
          x2: 20.0001,
          y2: 57.9126,
          d: 'M19.3613 29.1626H20.6391V57.9126H19.3613V29.1626Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Safety_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Safety-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Safety_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'survey-monitoring',
      label: {
        number: '06',
        title: 'survey-monitoring.Survey Monitoring',
      },
      position: [501, 139],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 152,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 152,
        },
        rect: {
          x: 0.773438,
          y: 0.711914,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 19.9329,
            y: 23.1331,
            r: 7.25392,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 19.9308,
            y: 23.1329,
            r: 2.63779,
          },
        },
        line: {
          x1: 19.9327,
          y1: 29.7275,
          x2: 19.9327,
          y2: 151.725,
          d: 'M19.2734 29.7275H20.5923V151.725H19.2734V29.7275Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 41,
        height: 152,
        scale: 0.00833333,
        viewBox: {
          x: 41,
          y: 152,
        },
        rect: {
          x: 0.773438,
          y: 0.711914,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 20.557,
            y: 20.4955,
            r: 9.89171,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 20.5577,
            y: 20.4957,
            r: 2.63779,
          },
        },
        line: {
          x1: 20.5577,
          y1: 29.7275,
          x2: 20.5577,
          y2: 151.725,
          d: 'M19.8984 29.7275H21.2173V151.725H19.8984V29.7275Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Monitoring_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Monitoring-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Monitoring_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
    {
      id: 'underground',
      label: {
        number: '07',
        title: 'underground.Underground',
      },
      position: [520, 275],
      rotation: [0, 0],
      default: {
        label: {
          number: {
            translateX: '-22',
          },
        },
        // width: 2.275,
        width: 40,
        height: 60,
        scale: 0.00833333,
        viewBox: {
          x: 40,
          y: 60,
        },
        rect: {
          x: 0.773438,
          y: 0.431641,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 19.9329,
            y: 22.8533,
            r: 7.25392,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 19.9308,
            y: 22.8531,
            r: 2.63779,
          },
        },
        line: {
          x1: 19.9327,
          y1: 29.4473,
          x2: 19.9327,
          y2: 59.1224,
          d: 'M19.2734 29.4473H20.5923V59.1224H19.2734V29.4473Z',
        },
        splash: {
          opacity: 0,
          width: 120,
          height: 120,
        },
      },
      active: {
        // width: 2.275,
        width: 41,
        height: 60,
        scale: 0.00833333,
        viewBox: {
          x: 41,
          y: 60,
        },
        rect: {
          x: 0.773438,
          y: 0.431641,
          width: 39.5668,
          height: 39.5668,
        },
        circle: {
          outer: {
            x: 20.557,
            y: 20.2152,
            r: 9.89171,
            strokeWidth: 1.31889,
          },
          inner: {
            x: 20.5577,
            y: 20.2149,
            r: 2.63779,
          },
        },
        line: {
          x1: 20.5577,
          y1: 29.4473,
          x2: 20.5577,
          y2: 59.1224,
          d: 'M19.8984 29.4473H21.2173V59.1224H19.8984V29.4473Z',
        },
        splash: {
          opacity: 1,
          width: 120,
          height: 120,
        },
      },
      zoomIn: {
        video: {
          name: 'path 020_2D_Mine_Map_Underground_Zoom_In.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_Underground-Zoom-Out.jpg',
        },
      },
      zoomOut: {
        video: {
          name: 'path 020_2D_Mine_Map_Underground_Zoom_Out.mp4',
          backgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
        },
      },
    },
  ],
  connectivityLines: {
    offsetX: 24,
    offsetY: 71.2,
    width: 273,
    height: 130,
  },
};

export default config;
