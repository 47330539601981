import description from './description';
import MaterialMovementDynamicData1 from './elements/materialMovementDynamicData_1';
import MaterialMovementDynamicData3 from './elements/materialMovementDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [55.15, 54.59], width: 6.375 }],
  dancingAnts: [],
  tinyBois: [{ id: 'tb1', position: [55.15, 54.59], offset: [5.6, 4.8] }],
  elements: [
    new MaterialMovementDynamicData1([66.7, 31.25], { size: 'wide', cornerFrom: ['top', 'left'] }),
    new MaterialMovementDynamicData3([66.7, 58.39], { cornerFrom: ['top', 'left'] }),
  ],
};

export default config;
