const description = {
  id: '3 of 4',
  currentNumber: 3,
  totalNumber: 4,
  title: 'planning.Grade Control.title',
  slug: 'grade-control',
  pdfPath: '03GradeControl',
  apiSlug: 'planning-grade-control',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineplan-grade-control-geo',
  description: 'planning.Grade Control.description',
  video: {
    name: 'path 2_location_3.mp4',
    stop: 9,
    backgroundImage: 'Path_2_location_3_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.25,
    dancingAnts: 7.5,
    secondaries: 8,
  },
};

export default description;
