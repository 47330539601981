import description from './description';
import MaterialMovementSchedulingOptimising1 from './elements/materialMovementSchedulingOptimising_1';
import MaterialMovementSchedulingOptimising2 from './elements/materialMovementSchedulingOptimising_2';
import MaterialMovementSchedulingOptimising3 from './elements/materialMovementSchedulingOptimising_3';
import MaterialMovementSchedulingOptimising4 from './elements/materialMovementSchedulingOptimising_4';
import MaterialMovementSchedulingOptimising5 from './elements/materialMovementSchedulingOptimising_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [14, 42.5], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 25.29], { direction: 'reverse' }],
    ['hs1', [0, 76.07]],
  ],
  tinyBois: [
    { id: 'tb1', position: [14, 42.5], offset: [5.7, 1.475] },
    { id: 'tb2', position: [14, 42.5], offset: [0.2, 4.45] },
    { id: 'tb3', position: [14, 42.5], offset: [5.7, 4.45] },
    { id: 'tb4', position: [14, 42.5], offset: [3, 6] },
  ],
  elements: [
    new MaterialMovementSchedulingOptimising1([12.4, 17.1], { size: 'wide' }),
    new MaterialMovementSchedulingOptimising2([51.46, 48.7]),
    new MaterialMovementSchedulingOptimising3([12.4, 66.4]),
    new MaterialMovementSchedulingOptimising4([59.27, 63.17]),
    new MaterialMovementSchedulingOptimising5([35.8, 66.4]),
  ],
};

export default config;
