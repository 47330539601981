/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
// @ts-nocheck
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import configAPP from 'app/configs';

import analytics from 'modules/analytics';

import Spinner from 'common/Spinner';

import AppLayout from 'app/layouts/app';
import actions from 'app/actions';

import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';

import { getFromLS, removeFromLS, saveToLS } from 'core/services/localStorage';

import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

import cacheService from 'app/services/CacheService';
import tokenInterceptor from 'modules/auth/TokenInterceptor';

import eventBus from 'EventBus';
import { ToastContainer } from 'react-toastify';
// eslint-disable-next-line no-unused-vars
// import i18n from './i18n';
import { withTranslation } from 'react-i18next';

import * as selectors from './selectors';

import NotFound from './containers/NotFound';

import './assets/scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    const { googleAnalytics } = analytics.services;
    googleAnalytics.init();

    const { init } = this.props;

    if (configAPP.enableDownload) {
      /* this.state = {
        info: '',
      }; */

      this.cache = {
        needCheckFiles: false,
        thresholdTime: 30 * 60 * 1000, // 36000000, // 300000 = 5 min, // 7200000, // = 2h
        callbackTimeout: null,
      };

      // this.arr = [];

      // this.messageChannel = new MessageChannel();

      if (navigator.serviceWorker) {
        navigator.serviceWorker.onmessage = (event) => {
          cacheService.resetTimeout();
          // this.arr.push(event.data);
          /* this.setState({
            info: `${JSON.stringify(event.data)}`,
          }); */
          // Print the result
          /* this.setState({
            info: `${JSON.stringify(event.data)}`,
          }); */

          const state = String(event?.data?.state).trim();
          if (state === 'SW:INITED:READY') {
            this.handlerRegisteredPWA();
          } else if (state === 'SW:FILE:CHECKED') {
            if (event?.data?.error) {
              // this.pwaLoadedError();
              // TODO: ADD IF NEED!
            }

            const result = cacheService.postMessage(navigator.serviceWorker.controller);

            if (result === 'FILES:EMPTY') {
              cacheService.clearFiles();

              if (this.action === 'CHECK:FILES') {
                this.clearDeprecatedFiles();
                this.pwaLoaded();

                this.cache.needCheckFiles = true;

                this.cache.callbackTimeout = setTimeout(() => {
                  this.runCheckFiles();
                }, this.cache.thresholdTime);
              } else if (this.action === 'CHECK:FILES:AFTER:REGISTERED') {
                this.clearDeprecatedFiles();
                this.pwaLoaded();

                this.cache.needCheckFiles = true;

                this.cache.callbackTimeout = setTimeout(() => {
                  this.runCheckFiles();
                }, this.cache.thresholdTime);
              }

              this.action = null;
            } else {
              const value = cacheService.getProgress();
              this.setPwaProgress(value);
            }
          } else if (state === 'SW:PWA:DELETE') {
            if (event?.data?.error) {
              this.pwaUnloadedError();
            } else {
              this.pwaUnloaded();
            }
          }
        };
      }
    }

    init();

    this.addCustomAttributesInHotjar();
  }

  componentDidMount() {
    tokenInterceptor.init();

    if (configAPP.enableDownload) {
      window.addEventListener('storage', this.useServiceWorkerIfPWA, false);

      window.addEventListener('online', this.activeInternetStatusOnline);

      window.addEventListener('offline', this.activeInternetStatusOffline);

      if (getFromLS('pwa')) {
        this.useServiceWorkerIfPWA();
      }

      if (
        this.isPwaLoaded() &&
        navigator?.serviceWorker?.controller?.state === 'activated' &&
        window.navigator.onLine
      ) {
        this.cache.needCheckFiles = true;
        this.runCheckFiles();
      }
    }
  }

  componentDidUpdate(prevPorps) {
    const { loggedUser, pwaConfirmDeleteStatusProgress } = this.props;

    if (prevPorps.loggedUser !== loggedUser) {
      if (loggedUser) {
        this.addCustomAttributesInHotjar();
      } else {
        this.removeCustomAttributesInHotjar();
      }
    }

    if (
      prevPorps.pwaConfirmDeleteStatusProgress !== pwaConfirmDeleteStatusProgress &&
      this.isPwaLoaded()
    ) {
      // TODO: Add states: start->progress->end and check process
      if (pwaConfirmDeleteStatusProgress > 0 && pwaConfirmDeleteStatusProgress < 100) {
        this.unloadPWA();
      }
    }
  }

  componentWillUnmount() {
    if (configAPP.enableDownload) {
      this.cache.needCheckFiles = false;

      if (this.cache.callbackTimeout) {
        clearTimeout(this.cache.callbackTimeout);
        this.cache.callbackTimeout = null;
      }

      window.removeEventListener('storage', this.useServiceWorkerIfPWA);

      window.removeEventListener('online', this.activeInternetStatusOnline);

      window.removeEventListener('offline', this.activeInternetStatusOffline);
    }
  }

  useServiceWorkerIfPWA = () => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    if (getFromLS('pwa')) {
      try {
        serviceWorkerRegistration.register({
          onSuccess: () => {},
          onUpdate: (registration) => {
            if (registration && registration.waiting) {
              this.registration = registration;
              registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
          },
          onActivated: () => {
            this.handlerRegisteredPWA();
          },
          onReady: () => {
            this.sendEventForUpdateSWRouter();
          },
        });
      } catch (error) {
        console.error(error);
        this.pwaLoadedError();
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }
    }
  };

  handlerRegisteredPWA = () => {
    this.sendEventForCheckFiles('CHECK:FILES:AFTER:REGISTERED');
  };

  setPwaProgress = (value) => {
    const { setPwaProgress } = this.props;
    setPwaProgress({ value });
  };

  unloadPWA = async () => {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      await cacheService.deletePWA(navigator.serviceWorker.controller);
    }
  };

  pwaUnloaded = () => {
    const { setPwaLoaded, setPwaError } = this.props;

    setPwaLoaded(false);
    setPwaError(false);
    removeFromLS('pwa');

    serviceWorkerRegistration.unregister();
  };

  pwaUnloadedError = () => {
    const { setPwaError } = this.props;
    setPwaError(true);
  };

  pwaLoaded = () => {
    const { setPwaLoaded } = this.props;
    setPwaLoaded(true);
    if (getFromLS('pwa') !== 'pwa') {
      saveToLS('pwa', 'pwa');
      eventBus.dispatch('downloadedOffline', { message: true });
    }

    window.dispatchEvent(new Event('storage'));
  };

  pwaLoadedError = async () => {
    const { setPwaLoaded, setPwaError } = this.props;
    sendGTEvent({ event: 'download_error', status: 'fail' });
    setPwaLoaded(false);
    setPwaError(true);
    removeFromLS('pwa');

    serviceWorkerRegistration.unregister();
  };

  activeInternetStatusOnline = () => {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      this.cache.needCheckFiles = true;
      this.runCheckFiles();
    }
  };

  activeInternetStatusOffline = () => {
    this.cache.needCheckFiles = false;

    if (this.cache.callbackTimeout) {
      clearTimeout(this.cache.callbackTimeout);
      this.cache.callbackTimeout = null;
    }
  };

  addCustomAttributesInHotjar() {
    const { loggedUser, isMsalUser } = this.props;

    let userInterestsArray = [];
    let company = '';
    let userId = '';

    if (loggedUser && window.hj) {
      const { name } = loggedUser;

      if (isMsalUser) {
        if (!loggedUser.idTokenClaims) return;

        userInterestsArray = loggedUser.idTokenClaims?.extension_Interests.split(',') || [];
        company = loggedUser.idTokenClaims?.extension_Company;
        userId = loggedUser.username;
      } else {
        userInterestsArray = Object.keys(loggedUser?.interest).filter(
          (k) => loggedUser?.interest[k],
        );
        company = loggedUser.company;
        userId = loggedUser.email;
      }

      const filteredInformationPriority = [];
      const mapInformationPriority = {
        cost: 'Cost',
        productivity: 'Productivity',
        digitalisation: 'Digitalisation',
        dataAnalytics: 'Data_Analytics',
        integration: 'Integration',
        autonomy: 'Autonomy',
        healthSafety: 'Health_Safety',
        esg: 'ESG',
      };

      userInterestsArray &&
        userInterestsArray.forEach((key) => {
          filteredInformationPriority.push(
            mapInformationPriority[key] ? mapInformationPriority[key] : key,
          );
        });

      window.hj('identify', userId, {
        Name: name,
        Company: company,
        InformationPriority: filteredInformationPriority,
        DownloadedOfflineVersion: getFromLS('pwa') === 'pwa' ? 'Yes' : 'No',
      });
    }
  }

  removeCustomAttributesInHotjar() {
    window.hj && window.hj('identify', null, {});
  }

  async runCheckFiles() {
    if (!this.cache.needCheckFiles) return;

    this.sendEventForCheckFiles();

    this.cache.callbackTimeout = setTimeout(() => {
      this.runCheckFiles();
    }, this.cache.thresholdTime);
  }

  async sendEventForCheckFiles(type = 'CHECK:FILES') {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      this.action = type;

      await cacheService.prepareFiles();
      const result = cacheService.postMessage(navigator.serviceWorker.controller);

      if (result === 'FILES:EMPTY') {
        cacheService.clearFiles();
        this.clearDeprecatedFiles();

        this.action = null;

        this.pwaLoaded();
      } else {
        const value = cacheService.getProgress();
        this.setPwaProgress(value);
      }
    }
  }

  async clearDeprecatedFiles() {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      cacheService.clearDeprecatedFiles(navigator.serviceWorker.controller);
    }
  }

  async sendEventForUpdateSWRouter() {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      cacheService.updateRouter(navigator.serviceWorker.controller);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  generateRouters() {
    return (
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index />

          <Route path="sign-up" />
          <Route path="/zh/sign-up" />

          <Route path=":journeyId" />
          <Route path="/zh/:journeyId" />

          <Route path=":journeyId/:locationId" />
          <Route path="/zh/:journeyId/:locationId" />

          <Route path=":journeyId/:locationId/:id" />
          <Route path="/zh/:journeyId/:locationId/:id" />

          <Route path=":journeyId/:locationId/:id/:subjourneyId/:sublocationId" />
          <Route path="/zh/:journeyId/:locationId/:id/:subjourneyId/:sublocationId" />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  isPwaLoaded() {
    return getFromLS('pwa') === 'pwa';
  }

  render() {
    const { ready } = this.props;

    // console.log('i18n', i18n);

    if (!ready) return <Spinner />;

    return (
      <>
        <Suspense fallback={<Spinner />}>
          <BrowserRouter>{this.generateRouters()}</BrowserRouter>
        </Suspense>
        <ToastContainer />
      </>
    );
  }
}

App.propTypes = {
  ready: PropTypes.bool,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
  init: PropTypes.func,
  setPwaProgress: PropTypes.func,
  setPwaLoaded: PropTypes.func,
  setPwaError: PropTypes.func,
};

App.defaultProps = {
  ready: false,
  i18n: {
    // language: 'en',
  },
  init: () => {},
  setPwaProgress: () => {},
  setPwaLoaded: () => {},
  setPwaError: () => {},
};

const mapStateToProps = (state) => {
  const loggedUser = selectors.getLoggedUser(state);
  return {
    ready: selectors.isReady(state),
    loggedUser,
    pwaConfirmDeleteStatus: selectors.getPwaConfirmDeleteStatus(state),
    pwaConfirmDeleteStatusProgress: selectors.getPwaConfirmDeleteProgress(state),
    isMsalUser: !!loggedUser?.idTokenClaims || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  init: () => dispatch(actions.init()),
  setPwaProgress: (payload) => dispatch(actions.setPwaProgress({ payload })),
  setPwaLoaded: (payload) => dispatch(actions.setPwaLoaded({ payload })),
  setPwaError: (payload) => dispatch(actions.setPwaError({ payload })),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));
