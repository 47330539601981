import Box from 'modules/common/components/Box';

export default class ExplorationResourceModel2 {
  constructor(position) {
    this.id = 'explorationResourceModel_2';
    this.component = Box;
    this.type = 'content';
    this.title = 'exploration.Resource Model.elements.Block Model Manager';
    this.content = 'exploration.Resource Model.elements.Block Model Manager Content';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['digitalisation'],
    };
  }
}
