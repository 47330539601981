import Box from 'modules/common/components/Box';

export default class ExplorationRockCharacterization4 {
  constructor(position) {
    this.id = 'explorationRockCharacterization_4';
    this.component = Box;
    this.title = 'exploration.Rock Characterisation.elements.Implicit Modelling';
    this.video = '1d-2.mp4';
    this.props = {
      target: 'tb3',
      position,
      hashtags: ['digitalisation'],
    };
  }
}
