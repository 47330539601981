import description from './description';
import MaterialMovementPersonalAlert1 from './elements/materialMovementPersonalAlert_1';
import MaterialMovementPersonalAlert2 from './elements/materialMovementPersonalAlert_2';
import MaterialMovementPersonalAlert3 from './elements/materialMovementPersonalAlert_3';
import MaterialMovementPersonalAlert4 from './elements/materialMovementPersonalAlert_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.09, 58.9], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 29.88], { direction: 'reverse' }],
    ['hs1', [0, 90.42], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.09, 58.9], offset: [0.4, 8.8] },
    { id: 'tb2', position: [16.09, 58.9], offset: [11.2, 8.8] },
    { id: 'tb3', position: [16.09, 58.9], offset: [11.2, 1.7] },
  ],
  elements: [
    new MaterialMovementPersonalAlert1([11.8, 22]),
    new MaterialMovementPersonalAlert2([11.8, 75.23]),
    new MaterialMovementPersonalAlert3([27, 51.6]),
    new MaterialMovementPersonalAlert4([27, 65.8]),
  ],
};

export default config;
