import Box from 'modules/common/components/Box';

export default class MaterialMovementOperatorAlertness5 {
  constructor(position, props = {}) {
    this.id = 'materialMovementOperatorAlertness_5';
    this.component = Box;
    this.type = 'content';
    this.title = 'safety.Operator Alertness System.elements.Easy to install and quick to configure';
    this.content =
      'safety.Operator Alertness System.elements.Easy to install and quick to configure Content';
    this.props = {
      target: props.target || null,
      position,
      hashtags: ['healthSafety'],
    };
  }
}
