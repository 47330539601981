const description = {
  id: '7 of 7',
  currentNumber: 7,
  totalNumber: 7,
  title: 'drill-blast.Dynamic Data.title',
  slug: 'dynamic-data',
  pdfPath: '07Drill&BlastDynamicData',
  apiSlug: 'drill-blast-dynamic-data',
  productDetailsLink: 'https://hexagon.com/products/hxgn-minemeasure-blast-control',
  description: 'drill-blast.Dynamic Data.description',
  video: {
    backgroundImage: 'Path_3_location_7_frame.jpg',
    playDefaultSound: true,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 3,
  },
};

export default description;
