import Box from 'modules/common/components/Box';

export default class ExplorationRockCharacterization3 {
  constructor(position, props = {}) {
    this.id = 'explorationRockCharacterization_3';
    this.component = Box;
    this.type = 'content';
    this.title = 'exploration.Rock Characterisation.elements.Split FX';
    this.content = 'exploration.Rock Characterisation.elements.Split FX Content';
    this.props = {
      target: 'tb2',
      position,
      cornerFrom: props.cornerFrom || null,
      hashtags: ['dataAnalytics'],
    };
  }
}
