/* eslint-disable react/button-has-type */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment,no-useless-escape,jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import JourneyForm from 'modules/journeys-manager-journey/components/JourneyForm';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import ArrowLeftIcon from 'app/assets/icons/arrow-left.svg';
import CloseIcon from './assets/icons/close.svg';

import './assets/scss/index.scss';

class EditJourney extends PureComponent {
  constructor(props) {
    super(props);
    this.journeyForm = null;
    this.state = {
      isOpenDeleteJourneyPopup: false,
    };
  }

  componentDidMount() {
    const { onEvent } = this.props;

    onEvent({ action: 'editDidMount' });
  }

  componentWillUnmount() {
    const { onEvent } = this.props;

    onEvent({ action: 'editWillUnmount' });
  }

  setChildForm(form) {
    this.journeyForm = form;
  }

  onEvent = ({ action, data }) => {
    const { onEvent } = this.props;

    onEvent({ action, data });
  };

  cancel() {
    this.navigationBack();
  }

  /**
   * @description for triggering form submit functionality
   */
  // eslint-disable-next-line class-methods-use-this
  updateJourney() {
    this.journeyForm.submitForm();
  }

  // eslint-disable-next-line class-methods-use-this
  sendRequest(data) {
    const { onEvent, journey } = this.props;

    onEvent({ action: 'updateJourney', data: { data, id: journey.id } });
  }

  navigationBack() {
    const { onEvent } = this.props;

    onEvent({ action: 'goToViewMode' });
  }

  deleteJourney() {
    const { onEvent, journey } = this.props;

    this.closeDeleteJourneyPopup();

    onEvent({ action: 'deleteJourney', data: { id: journey.id } });
  }

  openDeleteJourneyPopup() {
    this.setState({
      isOpenDeleteJourneyPopup: true,
    });
  }

  closeDeleteJourneyPopup() {
    this.setState({
      isOpenDeleteJourneyPopup: false,
    });
  }

  render() {
    const { allLomixConfigs, journey, permission, t } = this.props;
    const { isOpenDeleteJourneyPopup } = this.state;

    return (
      <>
        <div className="journey-manager-edit-page">
          <div className="journey-manager-edit-header">
            <div>
              <button
                className="journey-manager-edit-header__navigation"
                onClick={() => this.navigationBack()}
              >
                <ArrowLeftIcon />
                <span>{t('common.back')}</span>
              </button>
              <div className="journey-manager-edit-header__title">
                {t('journeys-manager.EDIT JOURNEY')}
              </div>
            </div>
            <div className="journey-manager-edit-header__actions">
              <button
                type="button"
                className="c-button c-button--size-sm c-button--link journey-manager-edit-header__action-cancel"
                onClick={() => this.cancel()}
              >
                <div className="c-button__text">{t('common.Cancel')}</div>
              </button>
              <button
                className="c-button c-button--size-default  journey-manager-edit-header__action-create"
                onClick={() => this.updateJourney()}
              >
                <div className="c-button__text">{t('common.Save changes')}</div>
              </button>
            </div>
          </div>

          <div className="journey-manager-edit-content journey-manager-edit-content--row">
            <JourneyForm
              onSubmit={(data) => this.sendRequest(data)}
              onFormCreated={(FormControl) => this.setChildForm(FormControl)}
              allLomixConfigs={allLomixConfigs}
              journey={journey}
            >
              <div>
                {permission.delete && (
                  <button
                    type="button"
                    className="c-button c-button--size-default journey-manager-edit-content__action-delete"
                    onClick={() => this.openDeleteJourneyPopup()}
                  >
                    <div className="c-button__text">
                      <CloseIcon />
                      <span>{t('journeys-manager.Delete journey')}</span>
                    </div>
                  </button>
                )}
              </div>
            </JourneyForm>
          </div>
        </div>

        <Dialog
          className="c-dialog c-dialog--with-main-header delete-journey-modal"
          open={isOpenDeleteJourneyPopup}
          onClose={() => this.closeDeleteJourneyPopup()}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle className="c-dialog-title c-dialog-title--normal" id="scroll-dialog-title">
            <span>{t('journeys-manager.DELETE OWN JOURNEY')}</span>
            <button
              type="button"
              className="c-button c-button--size-sm c-button--link"
              onClick={() => this.closeDeleteJourneyPopup()}
            >
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent className="c-dialog-content">
            <div className="delete-journey-modal_content-text">
              <p>{t('journeys-manager.afterDeleting')}</p>
              <p>{t('journeys-manager.deleteJourney')}</p>
            </div>
          </DialogContent>
          <DialogActions className="c-dialog-actions c-dialog-actions--standard">
            <button
              type="button"
              className="c-button c-button--size-sm c-button--link delete-journey-modal_action-cancel"
              onClick={() => this.closeDeleteJourneyPopup()}
            >
              <div className="c-button__text">{t('common.Cancel')}</div>
            </button>
            <button
              type="button"
              className="c-button c-button--size-sm c-button--with-border delete-journey-modal_action-delete"
              onClick={() => this.deleteJourney()}
            >
              <div className="c-button__text">{t('journeys-manager.Delete journey')}</div>
            </button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

EditJourney.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allLomixConfigs: PropTypes.arrayOf(PropTypes.any),
  journey: PropTypes.shape({
    id: PropTypes.number,
    privateName: PropTypes.string,
    shareableName: PropTypes.string,
    description: PropTypes.string,
    hidden: PropTypes.bool,
    own: PropTypes.bool,
    shared: PropTypes.bool,
    createdAt: PropTypes.number,
    updatedAt: PropTypes.number,
    locations: PropTypes.arrayOf(PropTypes.string),
  }),
  onEvent: PropTypes.func,
  t: PropTypes.func,

  permission: PropTypes.shape({
    createJourney: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
    duplicate: PropTypes.bool,
  }),
};

EditJourney.defaultProps = {
  allLomixConfigs: [],
  journey: null,
  onEvent: () => {},
  t: () => {},

  permission: {
    createJourney: false,
    edit: false,
    delete: false,
    duplicate: false,
  },
};

export default withTranslation()(connect(null, null)(EditJourney));
