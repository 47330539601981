import Box from 'modules/common/components/Box';

export default class ExplorationRockCharacterization2 {
  constructor(position) {
    this.id = 'explorationRockCharacterization_2';
    this.component = Box;
    this.title = 'exploration.Rock Characterisation.elements.Fracture Visualisation';
    this.video = '1d-1.mp4';
    this.props = {
      target: 'tb1',
      position,
      hashtags: ['dataAnalytics'],
    };
  }
}
