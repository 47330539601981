/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment,react/prop-types,react/no-unused-state,react/forbid-prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable no-restricted-syntax */
// @ts-nocheck
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable array-callback-return */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as selectors from 'app/selectors';
import actions from 'app/actions';

import VideoBackground from 'modules/common/components/VideoBackground';

import SidebarRight from 'modules/mine-map/components/SidebarRight';
import Pointer from 'modules/mine-map/components/Pointers/Pointer';
import PathsWO2D from 'modules/mine-map/components/Paths';
import ConnectivityLines from 'modules/mine-map/components/ConnectivityLines';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import * as auth from 'modules/auth';

import './assets/scss/index.scss';
import { sendGTEvent } from 'modules/analytics/services/googleAnalytics';
import { saveToLS } from 'core/services/localStorage';
import PowerOfOneDesc from '../components/PowerOfOneDesc';
import PowerOfOneCard from '../components/PowerOfOneDesc/PowerOfOneCard';

class Location extends PureComponent {
  constructor(props) {
    super(props);

    this.backgroundImage = React.createRef();

    this.pointers = React.createRef();
    this.pointers.current = [];

    this.isVideoEnded = false;
    this.loadedVideo = false;
    this.startAnimOnlyOne = true;

    this.action = null;

    this.startZoomIn = false;
    this.stopZoomIn = false;

    this.targetPointer = null;

    this.startZoomOut = false;
    this.stopZoomOut = false;

    this.hidedPointers = {};
    this.activePointers = {};

    this.state = {
      backImg: this.props.backgroundImage,
      video: {
        play: false,
        canPause: true,
      },
      decreaseVolume: false,
      visiblePointers: true,
      activePointerId: null,
      activePathJourneyId: null,
      showPathsWO2D: true,
      showSignUpForm: false,
      showSidebarRight: false,
      // showPoWerDesc: false,
    };
  }

  componentDidMount() {
    this.visit();

    if (!this.existVideo()) {
      setTimeout(() => {
        this.videoLoaded(true);
      }, 0);
    }

    if (this.props.visited < 1) {
      const { location } = this.props;
      const id = location?.pointers[0]?.id || null;

      this.activePointers[id] = true;

      this.setState({
        activePointerId: id,
        activePathJourneyId: id,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { authed } = this.props;

    if (prevProps.authed !== authed && authed) {
      if (this.state.showSidebarRight && this.targetPointer?.id) {
        this.goToLocationSidebarRight(this.targetPointer.id);
      }
    }
  }

  visit() {
    this.props.onEvent({ action: 'mine-map:visited' });
  }

  checkReady = () => {
    const { enabledTransition, config } = this.props;

    if (this.startAnimOnlyOne && this.loadedVideo && !enabledTransition) {
      this.startAnimOnlyOne = false;
    }
  };

  videoStopped = (isPlaying, canPause) => {
    this.toggleVideoPlaying(isPlaying, canPause);

    if (this.animPaused) {
      this.toggleIsStageAnimating(false);
    }

    if (this.startZoomIn) {
      this.startZoomIn = false;
      this.stopZoomIn = true;
    }
  };

  toggleVideoPlaying = (isPlaying, canPause) => {
    let { video } = this.state;

    video = {
      ...video,
      play: isPlaying,
    };

    if (canPause !== undefined) {
      video = {
        ...video,
        canPause,
      };
    }

    this.setState({ video });
  };

  toggleVideoEnded = (isEnded) => {
    this.isVideoEnded = isEnded;

    if (isEnded) {
      if (this.startZoomIn) {
        this.startZoomIn = false;
        this.stopZoomIn = true;

        this.toggleVideoPlaying(false);

        if (this.targetPointer) {
          // this.setBackImage(this.targetPointer?.zoomIn);

          this.setState({
            showSidebarRight: true,
          });
        }
      } else if (this.startZoomOut) {
        this.startZoomOut = false;
        this.stopZoomOut = true;

        // this.setBackImage(this.targetPointer?.zoomOut);

        this.stopZoomOut = false;

        this.targetPointer = null;

        this.deactivePointers();

        this.setState({
          visiblePointers: true,
          showPathsWO2D: true,
          activePointerId: null,
          activePathJourneyId: null,
        });

        this.toggleVideoPlaying(false);
        // this.setBackImage(this.targetPointer?.zoomOut);
      }
    }
  };

  videoLoaded = (value = true) => {
    this.loadedVideo = value;

    if (this.startZoomIn || this.startZoomOut) {
      let video = null;

      if (this.startZoomIn) {
        video = this.targetPointer?.zoomIn;
      } else {
        video = this.targetPointer?.zoomOut;
      }

      setTimeout(() => {
        if (this.startZoomIn) {
          this.setBackImage(video);
        } else {
          this.setBackImage(video);
        }
      }, 1000);

      this.toggleVideoPlaying(true);
    } else {
      this.checkReady();
    }
  };

  setBackImage = (location) => {
    this.setState({ backImg: location?.video?.backgroundImage });
    return location?.video?.backgroundImage;
  };

  toggleIsStageAnimating = (isAnimating) => {
    this.props.onEvent({
      action: 'toggleIsStageAnimating',
      data: { isStageAnimating: isAnimating },
    });
  };

  /* openPathsIfLastLocation = () => {
    const { location, journey, togglePaths, locationName } = this.props;
    const lastLocation = journey?.locations[journey.locations.length - 1];

    if (location?.id && lastLocation?.id && location.id === lastLocation.id && locationName) {
      this.timeOut = setTimeout(() => {
        togglePaths({ isOpen: true });
      }, 5000);
    }
  };
 */

  existVideo() {
    return !!this.props.location?.video.name;
  }

  getPointers = () => {
    const { location, journey } = this.props;
    const { visiblePointers } = this.state;

    if (location?.pointers?.length) {
      return location.pointers.map((pointer, index) => {
        return (
          <Pointer
            id={pointer.id}
            key={`pointer${pointer.id}`}
            ref={(el) => {
              this.pointers.current[index] = el;
            }}
            visible={visiblePointers}
            active={!!this.activePointers[pointer.id]}
            data={pointer}
            onClick={this.handlePointerClick}
            onHide={this.handlePointerHide}
            onActive={this.handlePointerActive}
            onDeactive={this.handlePointerDeactive}
            dimensions={journey?.dimensions}
          />
        );
      });
    }

    return null;
  };

  handlePointerClick = (id) => {
    this.targetPointer = this.getPointer(id);

    this.setState({
      visiblePointers: false,
    });

    // this.toggleVideoPlaying(true);
    // this.toggleVideoPlaying(false);
    // this.setBackImage(this.targetPointer?.zoomIn);
  };

  deactivePointers = () => {
    Object.keys(this.activePointers).forEach((id) => {
      this.activePointers[id] = false;
    });
  };

  handlePointerActive = (id) => {
    this.deactivePointers();

    this.activePointers[id] = true;

    this.setState({
      activePointerId: id,
      activePathJourneyId: id,
    });
  };

  handlePointerDeactive = (id, manual = false) => {
    if (this.activePointers[id]) {
      this.activePointers[id] = false;

      if (!manual) {
        this.setState({
          activePointerId: null,
          activePathJourneyId: null,
        });
      }
    }
  };

  handlePointerHide = (id) => {
    this.hidedPointers[id] = true;

    const { location } = this.props;

    if (Object.keys(this.hidedPointers).length === location?.pointers?.length) {
      this.startZoomIn = true;
      this.stopZoomIn = false;

      this.startZoomOut = false;
      this.stopZoomOut = false;

      this.toggleVideoPlaying(true);

      this.setState({
        showPathsWO2D: false,
        // showPoWerDesc: false,
      });
      // this.toggleVideoPlaying(this.loadedVideo);
    }
  };

  getPointer = (id) => {
    const { location } = this.props;

    if (location?.pointers?.length) {
      return location.pointers.find((pointer) => id === pointer.id);
    }

    return null;
  };

  goToLocationSidebarRight = (id) => {
    const { isUserLoggedIn } = this.props;
    const eventText = `deeg_deeper_into_${id}`;
    sendGTEvent({ event: eventText });

    // console.log('Location goToLocationSidebarRight', id);
    // this.clickOnCloseSidebarRight();

    if (isUserLoggedIn) {
      this.props.onEvent({
        action: 'journey:change',
        data: {
          id,
        },
      });
    } else {
      this.setState({ showSignUpForm: true });
      saveToLS('deferredJourney', id);
    }

    // if (this.props.authed) {
    //   this.props.onEvent({
    //     action: 'journey:change',
    //     data: {
    //       id,
    //     },
    //   });
    // } else {
    //   console.log('LOcation goToLocationSidebarRight');
    //   // this.clickOnCloseSidebarRight();

    // }

    // const { setReplaceJourney, setToJourney, setCanNavigate, isUserLoggedIn } = this.props;

    /* if (isUserLoggedIn) {
      const journey = LOMIXConfig?.find((j) => j.slug === id);

      if (journey) {
        this.setBackImage(journey.locations[0]);

        setToJourney(`${id}/${journey.locations[0].slug}`);
        setReplaceJourney(true);
        setCanNavigate(true);

        this.resetWorkflowsOverview2DMineMap();
      }
    } else {
      this.setState({ showSignUpForm: true });
    } */
  };

  resetWorkflowsOverview2DMineMap = () => {
    this.startZoomIn = false;
    this.stopZoomIn = false;

    this.startZoomOut = false;
    this.stopZoomOut = false;

    this.targetPointer = null;

    this.setState({
      showSidebarRight: false,
      visiblePointers: true,
      showPathsWO2D: true,
      activePointerId: null,
      activePathJourneyId: null,
      showSignUpForm: false,
      // showPoWerDesc: false,
    });
  };

  clickOnCloseSidebarRight = () => {
    this.startZoomIn = false;
    this.stopZoomIn = false;

    this.startZoomOut = true;
    this.stopZoomOut = false;

    this.loadedVideo = false;
    this.isVideoEnded = false;

    // this.setBackImage(this.targetPointer?.zoomOut);

    this.toggleVideoPlaying(false);

    this.setState({
      showSidebarRight: false,
    });

    // this.setBackImage(this.targetPointer?.zoomOut);
  };

  onClickCloseAuth = () => {
    this.setState({
      showSignUpForm: false,
    });
  };

  getVideo() {
    const { location } = this.props;

    if (this.startZoomIn || this.stopZoomIn) {
      if (this.targetPointer?.zoomIn?.video) {
        return this.targetPointer?.zoomIn?.video;
      }
    } else if (this.startZoomOut || this.stopZoomOut) {
      if (this.targetPointer?.zoomOut?.video) {
        return this.targetPointer?.zoomOut?.video;
      }
    }

    return location?.video;
  }

  isFirstVisit() {
    return this.props.visited <= 1;
  }

  handlePathsEventPathsWO2D = ({ action, id }) => {
    switch (action) {
      case 'deactive': {
        if (this.activePointers[id]) {
          this.activePointers[id] = false;

          this.setState({
            activePointerId: null,
          });
        }
        break;
      }
      case 'active': {
        this.deactivePointers();

        this.activePointers[id] = true;

        this.setState({
          activePointerId: id,
        });
        break;
      }
      case 'goToJourney': {
        this.deactivePointers();

        this.activePointers[id] = true;

        this.setState({
          activePointerId: id,
          activePathJourneyId: id,
        });

        this.handlePointerClick(id);
        break;
      }
      case 'openSiteMap': {
        this.props.setSiteMapOpen({ isOpen: true });
        break;
      }
      default: {
        //
      }
    }
  };

  // handlePowerEvent = ({ action, id }) => {
  //   const { showPoWerDesc, showPathsWO2D } = this.state;
  //   switch (action) {
  //     case 'toggleShow': {
  //       this.setState({
  //         showPoWerDesc: !showPoWerDesc,
  //         showPathsWO2D: !showPathsWO2D,
  //       });

  //       break;
  //     }
  //     default: {
  //       //
  //     }
  //   }
  // };

  render() {
    const {
      journey,
      location,
      playDefaultAudio,
      muted,
      backgroundImage,
      enabledTransition,
      disableDefaultAudio,
    } = this.props;

    const {
      video: videoSettings,
      decreaseVolume,
      showSidebarRight,
      activePathJourneyId,
      activePointerId,
      showPathsWO2D,
      visiblePointers,
      backImg,
      showSignUpForm,
      // showPoWerDesc,
    } = this.state;

    const videoForPlay = this.getVideo();

    const { Auth } = auth.containers;

    return (
      <>
        <div className={`${ALLOW_EVENTS_CLASS}`}>
          <VideoBackground
            backgroundImage={backImg}
            video={videoForPlay}
            videoSettings={videoSettings}
            videoStopped={this.videoStopped}
            videoEnded={this.toggleVideoEnded}
            videoLoaded={this.videoLoaded}
            playDefaultAudio={playDefaultAudio}
            disableDefaultAudio
            muted={muted}
            decreaseVolume={decreaseVolume}
            enabledTransition={enabledTransition}
          />
        </div>

        {this.getPointers()}

        <ConnectivityLines
          location={location}
          dimensions={journey?.dimensions}
          activePointerId={activePointerId}
          visible={visiblePointers}
        />

        <>
          <PowerOfOneCard isFirstVisit={this.isFirstVisit()} show={showPathsWO2D} />

          {!this.isFirstVisit() && (
            <PathsWO2D
              journeyId={activePathJourneyId}
              onEvent={this.handlePathsEventPathsWO2D}
              show={showPathsWO2D}
            />
          )}
          <div className={`sidebar-right-wrapper ${showSidebarRight ? 'active' : ''}`}>
            <div
              className={`scene-badge-button-container ${ALLOW_EVENTS_CLASS}`}
              onClick={this.clickOnCloseSidebarRight}
            >
              <div className="scene-badge-button close-scene">
                <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.928917 2.34315C0.538393 1.95262 0.538393 1.31946 0.928917 0.928932C1.31944 0.538408 1.95261 0.538408 2.34313 0.928932L15.0711 13.6569C15.4616 14.0474 15.4616 14.6805 15.0711 15.0711C14.6805 15.4616 14.0474 15.4616 13.6568 15.0711L0.928917 2.34315Z"
                    fill="#014969"
                  />
                  <path
                    d="M2.34313 15.0711C1.95261 15.4616 1.31944 15.4616 0.928917 15.0711C0.538393 14.6805 0.538393 14.0474 0.928917 13.6569L13.6568 0.928932C14.0474 0.538408 14.6805 0.538407 15.0711 0.928932C15.4616 1.31946 15.4616 1.95262 15.0711 2.34315L2.34313 15.0711Z"
                    fill="#014969"
                  />
                </svg>
              </div>
            </div>

            <div
              className={`sidebar-container ${
                showSidebarRight ? 'visible' : ''
              } ${ALLOW_EVENTS_CLASS}`}
            >
              {showSignUpForm ? (
                <Auth onClickCloseAuth={this.onClickCloseAuth} />
              ) : (
                <SidebarRight
                  locationId={this.targetPointer?.id}
                  onGoIntoLocation={this.goToLocationSidebarRight}
                />
              )}
            </div>
          </div>
        </>
      </>
    );
  }
}

Location.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    delayPrimaries: PropTypes.number,
    delaySecondaries: PropTypes.number,
    staggerBreakdowns: PropTypes.number,
    delayVideoBreakdown: PropTypes.number,
  }),
  enabledTransition: PropTypes.bool,
  playDefaultAudio: PropTypes.bool,
  muted: PropTypes.bool,
  location: PropTypes.object,
  visited: PropTypes.number,
  authed: PropTypes.bool,
  onEvent: PropTypes.func,
  isUserLoggedIn: PropTypes.bool,
  disableDefaultAudio: PropTypes.bool,
};

Location.defaultProps = {
  config: {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  },
  enabledTransition: false,
  playDefaultAudio: false,
  muted: true,
  location: {},
  visited: 0,
  authed: false,
  onEvent: () => {},
  isUserLoggedIn: false,
  disableDefaultAudio: false,
};

const mapStateToProps = (state, props) => {
  const config = {
    className: 'journey',
    delayPrimaries: 0.5,
    delaySecondaries: 0.25,
    staggerBreakdowns: 0.125,
    delayVideoBreakdown: 1.5,
  };

  const { location } = props;

  const playDefaultAudio = !!location?.video?.playDefaultSound;

  return {
    config,
    playDefaultAudio,
    isUserLoggedIn: selectors.getIsUserLoggedIn(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setToJourney: (payload) => dispatch(actions.setToJourney({ payload })),
  setCanNavigate: (payload) => dispatch(actions.setCanNavigate({ payload })),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Location);
