import description from './description';
import MaterialMovementOperatorAlertness1 from './elements/materialMovementOperatorAlertness_1';
import MaterialMovementOperatorAlertness2 from './elements/materialMovementOperatorAlertness_2';
import MaterialMovementOperatorAlertness3 from './elements/materialMovementOperatorAlertness_3';
import MaterialMovementOperatorAlertness4 from './elements/materialMovementOperatorAlertness_4';
import MaterialMovementOperatorAlertness5 from './elements/materialMovementOperatorAlertness_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [16.1, 50], width: 12.75 }],
  dancingAnts: [
    ['hs1', [0, 30.27]],
    ['hs1', [0, 89.8], { direction: 'reverse' }],
  ],
  tinyBois: [
    { id: 'tb1', position: [16.1, 50], offset: [11.6, 9] },
    { id: 'tb2', position: [16.1, 50], offset: [11.5, 2.6] },
    { id: 'tb3', position: [16.1, 50], offset: [6, 12.3] },
    { id: 'tb4', position: [16.1, 50], offset: [0.3, 9] },
  ],
  elements: [
    new MaterialMovementOperatorAlertness1([12, 19.9]),
    new MaterialMovementOperatorAlertness2([12, 83]),
    new MaterialMovementOperatorAlertness3([35.6, 49.76], {
      cornerFrom: ['top', 'left'],
      target: 'tb2',
    }),
    new MaterialMovementOperatorAlertness4([35.6, 67.7], { target: 'tb1' }),
    new MaterialMovementOperatorAlertness5([35.6, 83], { target: 'tb3' }),
  ],
};

export default config;
