/* eslint-disable react/prop-types,jsx-a11y/label-has-associated-control  */
import React, { useEffect, useState } from 'react';
import Button from 'modules/common/components/Button';
import { useTranslation } from 'react-i18next';
import HashtagList from '../Hashtags/HashtagList';
import HashtagModal from '../Hashtags/HashtagModal';
import HashtagWithDescriptionList from '../Hashtags/HashtagWithDescriptionList';

const InterestDetails = ({ values, prevStep, signUp, handleChange, saveFromModal }) => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isModalSaveDisabled, setModalIsSaveDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [checkedInterests, setCheckedInterests] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    setCheckedInterests({ ...values });

    if (Object.keys(values).some((k) => values[k] === true)) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [values]);

  useEffect(() => {
    if (Object.keys(checkedInterests).some((k) => checkedInterests[k] === true)) {
      setModalIsSaveDisabled(false);
    } else {
      setModalIsSaveDisabled(true);
    }
  }, [checkedInterests]);

  const continuePrev = (e) => {
    e.preventDefault();
    prevStep();
  };

  const signUpReady = (e) => {
    e.preventDefault();
    signUp();
  };
  const toggleWithDescriptions = (val) => {
    saveFromModal(checkedInterests);
    setIsModalOpen(val);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (fieldName, isChecked) => {
    handleChange(fieldName, isChecked);
  };

  const handleModalCheckboxChange = (fieldName, isChecked) => {
    const updatedInterest = { [fieldName]: isChecked };
    setCheckedInterests({ ...checkedInterests, ...updatedInterest });
  };

  const saveChanges = () => {
    saveFromModal(checkedInterests);
    closeModal();
  };

  return (
    <>
      <HashtagList
        key="sign-list"
        values={values}
        handleChange={handleCheckboxChange}
        toggleWithDescriptions={toggleWithDescriptions}
      />
      <div className="buttons-group-vertical c-margin-top">
        <div className="bottom-button">
          <Button onClick={signUpReady} disabled={isSaveDisabled}>
            {t('auth.Sign up')}
          </Button>
        </div>

        <div
          role="button"
          className="form-link"
          onClick={continuePrev}
          onKeyDown={continuePrev}
          tabIndex={0}
        >
          {t('forms.Back')}
        </div>
      </div>
      <HashtagModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        saveChanges={saveChanges}
        isSaveButtonDisabled={isModalSaveDisabled}
      >
        <div className="auth-title">{t('auth.Information priority')}</div>
        <HashtagWithDescriptionList
          values={checkedInterests}
          handleChange={handleModalCheckboxChange}
          toggleWithDescriptions={toggleWithDescriptions}
        />
      </HashtagModal>
    </>
  );
};

export default InterestDetails;
