import StageCard from 'modules/common/components/StageCard';

const config = {
  id: 3,
  title: 'drill-blast.Drill Blast',
  slug: 'introduction',
  apiSlug: 'drill-blast-introduction',
  description: 'drill-blast.description',
  video: {
    backgroundImage: 'Path_3_location_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1,
    secondaries: 2,
  },
  hotspots: [],
  dancingAnts: [],
  tinyBois: [],
  elements: [{ id: 'drillBlastIntroduction_1', component: StageCard }],
};

export default config;
