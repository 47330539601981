const description = {
  id: '3 of 5',
  currentNumber: 3,
  totalNumber: 5,
  code: 3,
  title: 'material-movement.Operator Assist.title',
  slug: 'operator-assist',
  pdfPath: '03OperatorAssist',
  apiSlug: 'material-movement-operator-assist',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-reverse-assist',
  description: 'material-movement.Operator Assist.description',
  video: {
    name: 'path 4_location_3.mp4',
    stop: 10.5,
    backgroundImage: 'Path_4_location_3_frame.jpg',
  },
  drawPoints: {
    hotspots: 0.5,
    dancingAnts: 10,
    secondaries: 10.5,
  },
};

export default description;
