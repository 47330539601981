const description = {
  id: '2 of 5',
  currentNumber: 2,
  totalNumber: 5,
  code: 2,
  title: 'safety.Operator Alertness System.title',
  slug: 'operator-alertness-system',
  pdfPath: '02OperatorAlertnessSystem',
  apiSlug: 'safety-operator-alertness-system',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineprotect-operator-alertness-system',
  description: 'safety.Operator Alertness System.description',
  video: {
    name: 'path 4_location_6_1.mp4',
    stop: 15,
    backgroundImage: 'Path_4_location_6_1_frame.jpg',
  },
  drawPoints: {
    hotspots: 15,
    dancingAnts: 15.5,
    secondaries: 16,
  },
};

export default description;
