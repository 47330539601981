import Box from 'modules/common/components/Box';

export default class Content {
  constructor(position) {
    this.title = 'exploration.Exploration Drilling.elements.Drillhole Data and Storage';
    this.content = 'exploration.Exploration Drilling.elements.Drillhole Data Content';
    this.id = 'explorationDrilling_2';
    this.component = Box;
    this.type = 'content';
    this.props = { target: 'tb1', position, hashtags: ['digitalisation'] };
  }
}
