const description = {
  id: '4 of 4',
  currentNumber: 4,
  totalNumber: 4,
  title: 'planning.Planning Dynamic Data.title',
  slug: 'planning-dynamic-data',
  pdfPath: '04PlanningDynamicData',
  apiSlug: 'planning-planning-dynamic-data',
  productDetailsLink: 'https://hexagon.com/solutions/mine-reality-capture',
  description: 'planning.Planning Dynamic Data.description',
  video: {
    backgroundImage: 'Path_2_location_4_frame.jpg',
    playDefaultSound: true,
  },
  drawPoints: {
    hotspots: 1,
    dancingAnts: 1.5,
    secondaries: 2,
  },
};

export default description;
