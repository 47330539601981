import description from './description';
import ExplorationResourceModel1 from './elements/explorationResourceModel_1';
import ExplorationResourceModel2 from './elements/explorationResourceModel_2';
import ExplorationResourceModel3 from './elements/explorationResourceModel_3';
import ExplorationResourceModel4 from './elements/explorationResourceModel_4';
import ExplorationResourceModel5 from './elements/explorationResourceModel_5';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [18, 42.25], width: 6.375 }],
  dancingAnts: [
    ['hs1', [0, 33.98], { direction: 'reverse' }],
    ['hs1', [50.57, 0]],
  ],
  tinyBois: [
    { id: 'tb1', position: [18, 42.25], offset: [2.9, 6.1] },
    { id: 'tb2', position: [18, 42.25], offset: [0.4, 4.8] },
    { id: 'tb3', position: [18, 42.25], offset: [5.65, 4.8] },
    { id: 'tb4', position: [18, 42.25], offset: [5.65, 1.2] },
  ],
  elements: [
    new ExplorationResourceModel1([11.6, 16.5]),
    new ExplorationResourceModel2([35.4, 56.1]),
    new ExplorationResourceModel3([11.6, 57.37]),
    new ExplorationResourceModel4([43.3, 42.37]),
    new ExplorationResourceModel5([43.3, 28.37]),
  ],
};

export default config;
