import Introduction from 'modules/exploration-introduction/configs/config1200';
import DrillholeDesign from 'modules/exploration-drillhole-design/configs/config1200';
import Drilling from 'modules/exploration-drilling/configs/config1200';
import ResourceModel from 'modules/exploration-resource-model/configs/config1200';
import RockCharacterization from 'modules/exploration-rock-characterisation/configs/config1200';
import DynamicData from 'modules/exploration-dynamic-data/configs/config1200';

const config = {
  id: 1,
  title: 'Exploration',
  slug: 'exploration',
  pdfPath: '01Exploration',
  hasMainNav: true,
  isInPaths: true,
  video: {
    backgroundImage: 'Path_1_location_1_frame.jpg',
  },
  description:
    'Improve your data and resource model reliability with integrated software solutions.',
  info: Introduction,
  locations: [DrillholeDesign, Drilling, ResourceModel, RockCharacterization, DynamicData],
};

export default config;
