const description = {
  id: 1,
  title: 'journeys-manager.Lead',
  slug: 'journeys',
  hasMainNav: false,
  hideHeader: true,
  isInPaths: false,
  hidePath: true,
  video: {
    name: 'path 020_2D_Mine_Map_3-Step-Trans.mp4',
    stop: 3,
    backgroundImage: 'path 020_2D_Mine_Map.jpg',
    defaultBackgroundImage: 'path_020_2D_Mine_Map_3-Step-Trans.jpg',
  },
  audio: {
    default: {
      disable: true,
    },
  },
  description: 'journeys-manager.Lead Description',
};

export default description;
