const description = {
  id: '2 of 7',
  currentNumber: 2,
  totalNumber: 7,
  title: 'drill-blast.Drilling.title',
  slug: 'drilling',
  pdfPath: '02ProductionDrilling',
  apiSlug: 'drill-blast-drilling',
  productDetailsLink: 'https://hexagon.com/products/hxgn-mineoperate-op-pro-hp',
  description: 'drill-blast.Drilling.description',
  video: {
    name: 'path 3_location_2.mp4',
    stop: 19,
    backgroundImage: 'Path_3_location_2_frame.jpg',
  },
  drawPoints: {
    hotspots: 16,
    dancingAnts: 19,
    secondaries: 19.5,
  },
};

export default description;
