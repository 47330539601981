/* eslint-disable global-require */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import eventBus from 'EventBus';
import { useTranslation } from 'react-i18next';
import Union from './assets/icons/union.svg';
import Close from './assets/icons/close.svg';
import GestureItem from './GestureItem';
import { NextArrow, PrevArrow } from './Arrows';

import ZoomDesktop from './assets/icons/desktop-zoom.svg';
import MoveDesktop from './assets/icons/desktop-move.svg';
import RotateDesktop from './assets/icons/desktop-rotate.svg';

import ZoomTablet from './assets/icons/tablet-zoom.svg';
import MoveTablet from './assets/icons/tablet-move.svg';
import RotateTablet from './assets/icons/tablet-rotate.svg';

import './assets/scss/index.scss';

const gestures = [
  {
    name: 'workflow.Move',
    iconDesktop: <MoveDesktop />,
    iconTablet: <MoveTablet />,
  },
  {
    name: 'workflow.Zoom',
    iconDesktop: <ZoomDesktop />,
    iconTablet: <ZoomTablet />,
  },

  {
    name: 'workflow.Rotate',
    iconDesktop: <RotateDesktop />,
    iconTablet: <RotateTablet />,
  },
];

const GestureHint = ({ showGestureHint }) => {
  const [isOpen, setIsOpen] = useState(showGestureHint);
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const { t } = useTranslation();

  const nodeRef = React.useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    variableWidth: true,
    lazyLoad: true,
    fade: true,
  };

  const moveWithSidebar = (isOpenSidebar) => {
    setIsSidebarOpened(isOpenSidebar);
  };

  useEffect(() => {
    eventBus.on('toggleSidebarOpened', (data) => moveWithSidebar(data.isOpen));
  });

  useEffect(() => {
    setIsOpen(showGestureHint);
  }, [showGestureHint]);

  const toggleGestureHint = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`gesture-container ${isSidebarOpened ? 'right-offset' : ''} ${
        isOpen ? 'open' : ''
      }`}
    >
      <div className={`slider-container ${isOpen ? 'visible' : ''} `}>
        {isOpen && gestures && (
          <Slider {...settings}>
            {gestures.map((gest) => (
              <GestureItem gest={gest} key={gest.name} />
            ))}
          </Slider>
        )}

        <button onClick={toggleGestureHint} className="toggle-button">
          <Close />
        </button>
      </div>

      <button
        onClick={toggleGestureHint}
        className={`toggle-button  ${isOpen ? 'with-text' : ''} `}
      >
        <Union />
        <CSSTransition
          timeout={0}
          in={isOpen}
          classNames="buttonSlide"
          nodeRef={nodeRef}
          mountOnEnter
          unmountOnExit
        >
          <div>
            <span ref={nodeRef}>{t('common.Navigation')}</span>
          </div>
        </CSSTransition>
      </button>
    </div>
  );
};

GestureHint.propTypes = {
  showGestureHint: PropTypes.bool,
};

GestureHint.defaultProps = {
  showGestureHint: false,
};

export default GestureHint;
