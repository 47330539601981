import description from './description';
import MaterialMovementAssetHealth1 from './elements/materialMovementAssetHealth_1';
import MaterialMovementAssetHealth2 from './elements/materialMovementAssetHealth_2';
import MaterialMovementAssetHealth3 from './elements/materialMovementAssetHealth_3';
import MaterialMovementAssetHealth4 from './elements/materialMovementAssetHealth_4';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [14.9, 41.25], width: 5 }],
  dancingAnts: [['hs1', [0, 24], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [14.9, 41.25], offset: [4.5, 1.475] },
    { id: 'tb2', position: [14.9, 41.25], offset: [2.2, 4.7] },
  ],
  elements: [
    new MaterialMovementAssetHealth1([12.4, 17.2], { size: 'wide' }),
    new MaterialMovementAssetHealth2([28, 41.25]),
    new MaterialMovementAssetHealth3([12.4, 55.6]),
    new MaterialMovementAssetHealth4([34.8, 55.5]),
  ],
};

export default config;
