import description from './description';
import MaterialMovementDynamicData1 from './elements/materialMovementDynamicData_1';
import MaterialMovementDynamicData3 from './elements/materialMovementDynamicData_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [55.1, 64.7], width: 12.67 }],
  dancingAnts: [],
  tinyBois: [{ id: 'tb1', position: [55.1, 64.7], offset: [11.2, 9.6] }],
  elements: [
    new MaterialMovementDynamicData1([66.7, 37], { size: 'wide', cornerFrom: ['top', 'left'] }),
    new MaterialMovementDynamicData3([66.7, 69.2], { cornerFrom: ['top', 'left'] }),
  ],
};

export default config;
