import description from './description';
import MaterialMovementOperatorAssist1 from './elements/materialMovementOperatorAssist_1';
import MaterialMovementOperatorAssist2 from './elements/materialMovementOperatorAssist_2';
import MaterialMovementOperatorAssist3 from './elements/materialMovementOperatorAssist_3';

const config = {
  ...description,
  hotspots: [{ id: 'hs1', position: [60.3, 67], width: 6.875, rotation: [55, 0] }],
  dancingAnts: [['hs1', [0, 83], { direction: 'reverse' }]],
  tinyBois: [
    { id: 'tb1', position: [60.3, 67], offset: [5.7, 2] },
    { id: 'tb2', position: [60.3, 67], offset: [5.7, 4.75] },
  ],
  elements: [
    new MaterialMovementOperatorAssist1([59.2, 19.4], { size: 'wide' }),
    new MaterialMovementOperatorAssist2([74.9, 42.31]),
    new MaterialMovementOperatorAssist3([74.9, 60.2]),
  ],
};

export default config;
