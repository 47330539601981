import Box from 'modules/common/components/Box';

export default class MaterialMovementPersonalAlert2 {
  constructor(position) {
    this.id = 'materialMovementPersonalAlert_2';
    this.component = Box;
    this.title = 'safety.Personal Alert.elements.Protect Personnel';
    this.video = '4g-1-1.mp4';
    this.props = {
      target: 'tb1',
      cornerFrom: ['top', 'left'],
      position,
      hashtags: ['healthSafety'],
    };
  }
}
