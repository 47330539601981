/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import * as selectors from 'app/selectors';

import actions from 'app/actions';

import { ALLOW_EVENTS_CLASS } from 'app/constants/app';

import ButtonClose from 'app/components/modals/ButtonClose';

import './assets/styles/index.scss';

class ContactUs extends PureComponent {
  constructor(props) {
    super(props);

    this.data = {
      name: null,
      email: null,
      phoneNumber: null,
      companeName: null,
      message: null,
      generalOverview: null,
      wantSolution: null,
      haveChallange: null,
      time: null,
    };

    this.contentRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { opened } = this.props;

    if (prevProps.opened !== opened) {
      if (opened) {
        this.show();
      } else {
        setTimeout(() => {
          this.hide();
        }, 600);
      }
    }
  }

  handleClose = () => {
    const { onEvent } = this.props;
    onEvent({ action: 'close' });
  };

  handleEvent = ({ action, path }) => {
    const { onEvent } = this.props;
    onEvent({ action, path });
  };

  handleClickSendForm = () => {
    const { sendEmail } = this.props;

    const payload = {
      ...this.data,
    };

    sendEmail(payload);
  };

  getIframe = () => {
    return '<iframe  src="https://connect.hexagonmining.com/l/273802/2021-08-27/3zp2gsp" width="100%" height="500" class="contact-us_iframe" type="text/html" frameborder="0" allowTransparency="true" style="border: 0"></iframe>';
  };

  hide = () => {
    if (this.contentRef?.current) {
      this.contentRef.current.style.left = '-100000%';
    }
  };

  show = () => {
    if (this.contentRef?.current) {
      this.contentRef.current.style.left = '0';
    }
  };

  render() {
    const { config, opened } = this.props;

    return (
      <div
        ref={this.contentRef}
        className={`${config.className} ${opened ? config.openClass : ''} ${ALLOW_EVENTS_CLASS}`}
        style={{
          left: '-100000%',
        }}
      >
        <div className={`${config.className}__container`}>
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0.813rem',
              zIndex: 10,
            }}
          >
            <ButtonClose onClick={this.handleClose} />
          </div>
          <div
            className={`${config.className}__content`}
            dangerouslySetInnerHTML={{ __html: this.getIframe() }}
          />
        </div>
      </div>
    );
  }
}

ContactUs.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    openClass: PropTypes.string,
  }),
  opened: PropTypes.bool,
  onEvent: PropTypes.func,
  sendEmail: PropTypes.func,
};

ContactUs.defaultProps = {
  config: {
    className: 'c-contact-us-modal',
    openClass: 'is-open',
  },
  opened: false,
  onEvent: () => {},
  sendEmail: () => {},
};

const mapStateToProps = (state) => {
  const email = selectors.getEmail(state);

  return {
    email,
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (payload) => dispatch(actions.sendEmail({ payload })),
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ContactUs);
