/* eslint-disable react/forbid-prop-types,react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
// @ts-nocheck
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable spaced-comment */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import gsap from 'gsap';

import { forgeComponent, getHTMLStringAsComponents } from 'app/helpers';

import * as selectors from 'app/selectors';

import Hotspot from 'modules/common/components/Hotspot';

import DancingAnts from 'modules/common/components/DancingAnts';

import TinyBoi from 'modules/common/components/TinyBoi';

import Box from 'modules/common/components/Box';

import TitleCard from 'modules/common/components/TitleCard';

import LocationMarker from 'modules/common/components/LocationMarker';
import StageCard from 'modules/common/components/StageCard';
import VideoLauncher from 'modules/common/components/VideoLauncher';
import Announce from 'modules/common/components/Announce';

import eventBus from 'EventBus';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
// import { withTranslation } from 'react-i18next';

class Stage extends PureComponent {
  constructor(props) {
    super(props);

    this.hotspots = React.createRef();
    this.hotspots.current = [];

    this.dancingAnts = React.createRef();

    this.tinyBois = React.createRef();
    this.tinyBois.current = [];

    this.cards = React.createRef();
    this.cards.current = [];

    this.boxes = React.createRef();
    this.boxes.current = [];

    this.state = {
      cards: [],
      boxes: [],
    };
  }

  componentDidMount() {
    this.getAllElements();

    window.addEventListener('resize', this.getAllElements, false);
    eventBus.on('cancelContinueFromCard', this.cancelContinueFromCard);
  }

  componentDidUpdate(prevProps) {
    const { location, locationName, loggedUser, lang } = this.props;

    if (lang !== prevProps.lang) {
      if (locationName) {
        const boxes = this.getBoxes();
        this.setState({
          boxes,
          cards: [],
        });
      } else {
        const cards = this.getCards();

        this.setState({
          cards,
          boxes: [],
        });
      }
    }

    // todo: replace to activity module
    if (!locationName && prevProps.location !== location) {
      const cards = this.getCards();

      this.setState({
        cards,
        boxes: [],
      });
    }

    if (
      (locationName && prevProps.locationName !== locationName) ||
      (prevProps.loggedUser !== loggedUser && loggedUser)
    ) {
      const boxes = this.getBoxes();
      this.setState({
        boxes,
        cards: [],
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getAllElements, false);
    eventBus.remove('cancelContinueFromCard', this.cancelContinueFromCard);
  }

  // Filters elements by component instance type
  // eslint-disable-next-line react/destructuring-assignment
  getElements(location, ...componentTypes) {
    const elements = [];

    for (const element of location?.elements) {
      for (const componentType of componentTypes) {
        if (element.component.id === componentType.id) {
          elements.push(element);
          break;
        }
      }
    }

    return elements;
  }

  getJoinedElements() {
    const { cards, boxes } = this.state;
    return cards.concat(boxes);
  }

  // Gets refs for primary or secondary elements
  getPrimarySecondaryRefs = (isPrimary = true) => {
    const elements = this.getJoinedElements();
    const refs = [];
    const cardRefs = this.cards.current || [];
    const boxRefs = this.boxes.current || [];

    const allPrimarySecondaryRefs = cardRefs.concat(boxRefs);

    for (const [index, element] of elements.entries()) {
      if (!!element.isPrimary === isPrimary) {
        refs.push(allPrimarySecondaryRefs[index]);
      }
    }

    return refs;
  };

  getTimelines() {
    const { config, hotspots, tinyBois } = this.props;

    // Start with hotspots
    const hotspotTimeline = gsap.timeline({ paused: true }).addLabel('start');
    if (hotspots.length) {
      for (const [index, hotspot] of this.hotspots.current.entries()) {
        hotspotTimeline.add(
          hotspot.init().getTimeline().restart(),
          index ? `<${config.staggerHotspots}` : 0,
        );
      }
    }

    // Then, the ants
    const dancingAntsTimeline = this.dancingAnts?.current?.init().getTimeline();

    // Then, the tiny bois
    const tinyBoisTimeline = gsap.timeline({ paused: true }).addLabel('start');
    if (tinyBois.length) {
      for (const [index, tinyBoi] of this.tinyBois.current.entries()) {
        tinyBoisTimeline.add(
          tinyBoi.init().getTimeline().restart(),
          index ? `<${config.staggerTinyBois}` : 0,
        );
      }
    }

    // Then, the primaries
    const primariesTimeline = gsap.timeline({ paused: true }).addLabel('start');
    const primaryRefs = this.getPrimarySecondaryRefs();

    if (primaryRefs.length) {
      for (const [index, primary] of primaryRefs.entries()) {
        primariesTimeline.add(
          primary.init().getTimeline().restart(),
          index ? `<${config.staggerPrimaries}` : 0,
        );
      }
    }

    // Then, the secondaries
    const secondariesTimeline = gsap.timeline({ paused: true }).addLabel('start');
    const secondaryRefs = this.getPrimarySecondaryRefs(false);
    if (secondaryRefs.length) {
      for (const [index, secondary] of secondaryRefs.entries()) {
        secondariesTimeline.add(
          secondary.init().getTimeline().restart(),
          index ? `<${config.staggerSecondaries}` : 0,
        );
      }
    }

    // const dancingAntsTimeline1=dancingAntsTimeline

    return {
      hotspots: hotspotTimeline.addLabel('end'),
      dancingAnts: dancingAntsTimeline?.addLabel('end'),
      tinyBois: tinyBoisTimeline.addLabel('end'),
      primaries: primariesTimeline.addLabel('end'),
      secondaries: secondariesTimeline.addLabel('end'),
    };
  }

  getCards() {
    const { journey, t } = this.props;

    const location = journey.info;

    const cards = [];
    const cardElements = this.getElements(location, TitleCard, StageCard);

    for (const cardElement of cardElements) {
      const card = {
        component: cardElement.component,
        isPrimary: !!cardElement.isPrimary,
        content: getHTMLStringAsComponents(t(location.description)),
        props:
          cardElement.component.name === StageCard.name
            ? {
                title: this.isLead() ? t(this.props.location?.title) : t(location.title),
                count: this.isLead() ? null : journey.locations.length,
                showCancel: !this.isLead(),
              }
            : {},
        ...cardElement.props,
      };

      cards.push(card);
    }

    return cards;
  }

  getBoxes() {
    const { journey, location, loggedUser, isMsalUser, t } = this.props;

    const boxes = [];
    const boxElements = this.getElements(location, Box);

    for (const boxElement of boxElements) {
      let userInterestsArray = [];
      let hashtagsForShow = [];
      if (loggedUser) {
        if (isMsalUser) {
          userInterestsArray = loggedUser?.idTokenClaims?.extension_Interests.split(',') || [];
        } else {
          userInterestsArray = Object.keys(loggedUser?.interest).filter(
            (k) => loggedUser?.interest[k],
          );
        }

        hashtagsForShow = boxElement.props?.hashtags?.filter((element) =>
          userInterestsArray.includes(element),
        );
      }

      const box = {
        id: boxElement.id,
        isPrimary: !!boxElement.isPrimary,
      };
      // First, let's set some props
      box.props = {
        id: boxElement.id,
        isExpandable: boxElement.type === 'content',
        size:
          boxElement.type === 'marker'
            ? boxElement.size === 'wide'
              ? 'lg xl'
              : boxElement.size === 'wide-l'
              ? 'lg xxl'
              : 'lg'
            : boxElement.type === 'content'
            ? 'sm'
            : null,
        color:
          boxElement.type === 'marker' ? 'white' : boxElement.type === 'content' ? null : 'black',
        hashtags: hashtagsForShow,
      };

      // Passed props are king — make sure we respect those over the inferred
      box.props = { ...boxElement.props, ...box.props };

      // Then, we gotta make some slot content
      box.headerSlot = { components: [], content: [] };
      box.bodySlot = { components: [], content: [] };
      box.defaultSlot = { components: [], content: [] };
      box.announceSlot = { components: [], content: [] };

      if (boxElement.type === 'marker') {
        const options = {
          jourNum: journey.hideMarkerPathId ? null : journey.id,
          jourName: journey.hideMarkerJourneyName ? null : t(journey.title),
          locName: journey.hideMarkerPathName ? null : t(location.title),
        };

        if (this.isLead()) {
          options.locId = journey.activeLocation?.id ? journey.activeLocation.id : location.id;
          options.locСurrentNumber = journey.activeLocation?.currentNumber
            ? journey.activeLocation.currentNumber
            : location.currentNumber;
          options.locTotalNumber = journey.activeLocation?.totalNumber
            ? journey.activeLocation.totalNumber
            : location.totalNumber;
        } else {
          options.locId = location.id;
          options.locСurrentNumber = location.currentNumber;
          options.locTotalNumber = location.totalNumber;
        }

        box.defaultSlot.components.push(forgeComponent(LocationMarker, options));

        box.defaultSlot.content = box.defaultSlot.content.concat(
          getHTMLStringAsComponents(t(location.description)),
        );
        box.announceSlot.components.push(
          forgeComponent(Announce, {
            announce: boxElement.props.announce,
          }),
        );
      } else if (boxElement.type === 'content') {
        box.headerSlot.content.push(forgeComponent('h3', {}, {}, t(boxElement.title)));
        box.bodySlot.content = box.bodySlot.content.concat(
          getHTMLStringAsComponents(t(boxElement.content)),
        );
      } else {
        box.defaultSlot.components.push(
          forgeComponent(VideoLauncher, {
            id: boxElement.id,
            // title: boxElement.title,

            title: t(boxElement.title),
            video: boxElement.video,
            img: boxElement.img,
            modalType: boxElement.modalType,
            hashtags: hashtagsForShow,
          }),
        );
      }

      boxes.push(box);
    }

    return boxes;
  }

  getAllElements = () => {
    const { locationName } = this.props;
    if (locationName) {
      const boxes = this.getBoxes();
      this.setState({
        boxes,
        cards: [],
      });
    } else {
      const cards = this.getCards();
      this.setState({
        cards,
        boxes: [],
      });
    }
  };

  cancelContinueFromCard = () => {
    this.setState({ boxes: [], cards: [] });
  };

  handleEvent = (payload) => {
    const { onEvent } = this.props;
    onEvent(payload);
  };

  killTimelines() {
    // if (this.dancingAnts.current) {
    this.dancingAnts?.current?.killTimeline();
    // }
  }

  isLead() {
    return this.props.journey.slug === 'lead';
  }

  render() {
    const { config, location, hotspots, tinyBois, locationName } = this.props;
    const { cards, boxes } = this.state;

    this.hotspots.current.length = 0;
    this.tinyBois.current.length = 0;
    this.cards.current.length = 0;
    this.boxes.current.length = 0;

    return (
      <>
        {hotspots.length
          ? hotspots.map((hotspot, index) => {
              return (
                <Hotspot
                  key={`hotspot${index}`}
                  ref={(el) => {
                    this.hotspots.current[index] = el;
                  }}
                  {...hotspot}
                />
              );
            })
          : ''}

        {locationName && location?.dancingAnts?.length ? (
          <DancingAnts
            ref={this.dancingAnts}
            ants={location?.dancingAnts}
            hotspots={hotspots}
            tinyBois={tinyBois}
          />
        ) : (
          ''
        )}

        {tinyBois.length
          ? tinyBois.map((tinyBoi, index) => {
              return (
                <TinyBoi
                  key={`tinyBoi${index}`}
                  ref={(el) => {
                    this.tinyBois.current[index] = el;
                  }}
                  {...tinyBoi}
                />
              );
            })
          : ''}

        {boxes.length
          ? boxes.map((box, index) => {
              return (
                <Box
                  key={`box${box.id}`}
                  ref={(el) => {
                    this.boxes.current[index] = el;
                  }}
                  box={box}
                  index={index}
                  onEvent={this.handleEvent}
                  {...box.props}
                />
              );
            })
          : ''}
        {cards.length ? (
          <div className={`${config.className}__cards`}>
            {cards.map((card, index) => {
              const CARD = card;
              const CARD_COMPONENT = card.component;

              return (
                <CARD_COMPONENT
                  key={`card${index}`}
                  {...CARD.props}
                  ref={(el) => {
                    if (el !== null) {
                      this.cards.current[index] = el;
                    }
                  }}
                  onEvent={this.handleEvent}
                >
                  {CARD.content.map((cmp, cindex) => {
                    const CMP = cmp;
                    const CMP_COMPONENT = cmp.name;
                    return (
                      <CMP_COMPONENT key={`card${index}content${cindex}`}>{CMP.html}</CMP_COMPONENT>
                    );
                  })}
                </CARD_COMPONENT>
              );
            })}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

Stage.propTypes = {
  config: PropTypes.shape({
    className: PropTypes.string,
    staggerHotspots: PropTypes.number,
    staggerConnections: PropTypes.number,
    staggerTinyBois: PropTypes.number,
    staggerPrimaries: PropTypes.number,
    staggerSecondaries: PropTypes.number,
  }),
  journey: PropTypes.object,
  location: PropTypes.object,
  loggedUser: PropTypes.object,
  onEvent: PropTypes.func,
  t: PropTypes.func,
};

Stage.defaultProps = {
  config: {
    className: 'stage',
    staggerHotspots: 0.1,
    staggerConnections: 0.15,
    staggerTinyBois: 0.1,
    staggerPrimaries: 0.1,
    staggerSecondaries: 0.1,
  },
  journey: null,
  location: null,
  onEvent: () => {},
  loggedUser: {},
  t: () => {},
};

Stage.id = 'Stage';

const mapStateToProps = (state, props) => {
  const loggedUser = selectors.getLoggedUser(state);
  return {
    hotspots: props.location?.hotspots || [],
    tinyBois: props.location?.tinyBois || [],
    lang: i18next.language || window.localStorage.i18nextLng,
    loggedUser,
    locationName: props.locationName === 'introduction' ? null : props.locationName,
    isMsalUser: !!loggedUser?.idTokenClaims || false,
  };
};

export default withTranslation(null, { withRef: true })(
  connect(mapStateToProps, null, null, { forwardRef: true })(Stage),
);
